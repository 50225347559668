<template>
  <div class="projects-table">
    <div class="filters">
      <input v-model="searchQuery" placeholder="Search projects...">
      <select v-model="sortBy">
        <option value="name">Name</option>
        <option value="price">Price</option>
        <option value="weight">Weight</option>
        <option value="status">Status</option>
        <option value="lastModified">Last Modified</option>
      </select>
      <button @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'">
        {{ sortOrder === 'asc' ? '↑' : '↓' }}
      </button>

      <button @click="createNewProject" class="new-project-btn">Create New Project</button>
    </div>
   
    <table>
      <thead>
        <tr>
          <th @click="updateSort('name')">Name</th>
          <th> </th>
          <th @click="updateSort('price')">Price</th>
          <th @click="updateSort('weight')">Weight</th>
          <th @click="updateSort('state')">Status</th>
          <th @click="updateSort('modified')">Last Modified</th>
          
          <!-- <th>Actions</th> -->
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="10" class="spinner"><ProgressSpinner /></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="project in filteredAndSortedProjects" :key="project.id">
          
          <td @click="goToProjectDetail(project.reference)">{{ project.name }}</td>
          <td class="actions">
            <div class="dropdown">
              <button class="dropbtn"><b>...</b></button>
              <div class="dropdown-content">
                <a @click="editDesign(project.reference)">Edit Design</a>
                <a @click="shareProject(project.id)">Share</a>
                <a @click="requestHardQuote(project.id)">Request Hard Quote</a>
                <a @click="adjustQuote(project.reference)">Adjust Quote</a>
                <a @click="openProjectFolder(project.id)">Open Project Folder</a>
                <a @click="copyProject(project.id)">Copy</a>
                <a @click="archiveProject(project.id)">Archive</a>
                <a @click="deleteQuote(project)">Delete</a>
              </div>
            </div>
          </td>
          <td @click="goToProjectDetail(project.reference)">{{ project.price }}</td>
          <td @click="goToProjectDetail(project.reference)">{{ project.weight }}</td>
          <td @click="goToProjectDetail(project.reference)">{{ project.state }}</td>
          <td @click="goToProjectDetail(project.reference)">{{ formatDate(project.modified) }}</td>
          
        </tr>
      </tbody>
    </table>
    <QuoteAdjustmentModal :qaData.sync="qaData"></QuoteAdjustmentModal>
    
  </div>
</template>

<script>
import { CORE } from '@/constants';
import ContextLogic from '@/lib/contextLogic';

import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue'
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default {
  components: {
    QuoteAdjustmentModal,
    ProgressSpinner
  },
  data() {
    return {
      loading:true,
      searchQuery: '',
      sortBy: 'name',
      sortOrder: 'asc',
      projects: [],
      qaData: {
        ref: null,
        show: false
      }
      
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    filteredAndSortedProjects() {
      // console.log('testprojs', this.testProj)
      let filtered = this.projects.filter(project => {
        return Object.values(project).some(value => 
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    }
  },
  methods: {
    ...mapActions('projectModule', ['fetchProjects', 'deleteProject']),
    ...mapActions('contextModule', ['']),
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    goToProjectDetail(projectRef) {
      this.$router.push(`/project/${projectRef}`)
    },
    createNewProject() {
      this.$router.push('/project/new')
    },
    adjustQuote(ref){
      this.qaData.ref = ref;
      this.qaData.show = true;
    },
    async deleteQuote(project){
      await this.$modal.show('dialog', {
          title: 'Confirmation',
          text: `Are you sure you want to permanently delete this design?`,
          buttons:[
            {
              title: 'Delete',
              handler: async () => {
                let response = await this.deleteProject(project);
                this.projects = await this.fetchProjects();
                this.$modal.hide('dialog')
              },
            },
            {
              title: 'Cancel',
              handler: async () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
     
    },
    editDesign(ref){
      //project/dkj523/design
      this.$router.push(`/project/${ref}/design`)        
    },
  },
  actions:{
    
  },
  async created(){
    await ContextLogic.initContext(this);
    let sub = this.current.mode === CORE.modes.system ? null : this.$auth.user.sub;

    this.projects = await this.fetchProjects();
    this.loading=false;
  }
}
</script>

<style lang="scss" scoped>
// Variables
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333;
$hover-color: #f1f8ff;
$green-color: #2ecc71;
$danger-color: #e74c3c;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

// Mixins
@mixin transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease;
}

.projects-table {
  margin: 20px;
  font-family: Arial, sans-serif;
  color: $text-color;
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 20px;

  .spinner{
    text-align: center;    
  }
  
  .filters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    input, select {
      padding: 8px 12px;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 14px;
      @include transition;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    button {
      padding: 8px 12px;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      @include transition;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .new-project-btn {
      background-color: $green-color;
      margin-left: auto;

      &:hover {
        background-color: darken($green-color, 10%);
      }
    }
  }

  table {
    margin:auto;
    width: fit-content;
    border-collapse: separate;
    border-spacing: 0;

    th, td {
      //border: 1px solid $border-color;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: $secondary-color;
      color: white;
      font-weight: bold;
      cursor: pointer;
      @include transition(background-color);

      &:hover {
        background-color: lighten($secondary-color, 10%);
      }
    }

    tr {
      @include transition(background-color);

      &:hover {
        background-color: $hover-color;
      }
    }

    td {
      @include transition(background-color);

      &:hover {
        background-color: darken($hover-color, 5%);
      }
    }
  }

  .actions {
    text-align: center;
    width: 5px;
    .dropdown {
      position: relative;
      display: inline-block;

      .dropbtn {
        background-color: transparent;
        color: $text-color;
        padding: 5px 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        @include transition;

        &:hover {
          color: $primary-color;
        }
      }

      .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: white;
        min-width: 160px;
        box-shadow: $box-shadow;
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;

        a {
          color: $text-color;
          padding: 10px 10px;
          text-decoration: none;
          display: block;
          cursor: pointer;
          @include transition(background-color);

          &:hover {
            background-color: $hover-color;
          }

          &.delete-option {
            color: $danger-color;

            &:hover {
              background-color: lighten($danger-color, 35%);
            }
          }
        }
      }

      &:hover .dropdown-content {
        display: block;
      }
    }
  }
}


</style>

