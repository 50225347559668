<template>
  <div class="container">

    <div >
      
    </div>

    <nav aria-label="breadcrumb">

      <div class="breadcrumb" v-if="mode !== null">
        <div v-for="(crumb, index) in breadcrumbs2" :key="index" class="breadcrumb-item"
          
          >
          <span v-if="crumb.path" @click="navTo(crumb)">{{ crumb.label }}</span>
          <span v-else>{{ crumb.label }}</span>
        </div>

        <div id="menuContainer">
          
          <div id="menuToggle" @click="toggleMenu">
             {{ userEmail }}</div>
          <div id="menu" v-if="open">
            <div class="menuItem">
              <router-link :to="{ name: 'profile' }" style="text-decoration: none;;">Profile</router-link>
            </div>            
            <div class="menuItem"  @click="logout">
              <b>Log out</b>
            </div>
          </div>
        </div>
        
        
      </div>

    </nav>
    
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'NavCard',
  data(){
    return {
      open:false
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    mode() {
      return this.current.mode
    },
    userEmail() {
      return this.$auth.user.email;
    },
    breadcrumbs2(){
      return ContextLogic.getBreadcrumbs(this);
    }

  },
  methods: {
    navTo(route){
      if(this.$route.fullPath != route.path)
      this.$router.push({path: route.path });      
    },
    toggleMenu(){
      this.open=!this.open;
    },
    logout() {
      console.log('app: logout')
      console.log('logging out, redirecting to: ', `${process.env.VUE_APP_SITE_BASE_URL}/login`)
      localStorage.removeItem('token');
      sessionStorage.removeItem('currentUser')
      this.$auth.logout({
        logoutParams: {
          returnTo: `${process.env.VUE_APP_SITE_BASE_URL}/login`
        }
      });
    },
    beforeRouteUpdate(to, from, next) {
      console.log('beforeRouteUpdate')    ;
      next();
  }
  }
}
</script>

<style scoped>
.body {
  color: black;
  font-weight: bold;
  font-size: 25px;
  border-radius: 4px;
  border: 1px solid black;
  background: lightgray;
  padding: 40px;

  display: inline-block;
  width: 200px;
}

.detail {
  font-weight: normal;
  margin: 10px;
  font-size: .8em;
}

.breadcrumb{
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  background-color: #e9ecef;
  border-radius: 0.2rem;
  font-size: 1.2em;
  border-bottom: 1px solid lightgray;
}

.breadcrumb-item {
  cursor:pointer;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item.active {
  color: #6c757d;
  cursor: default;
}

#menuToggle{
  
  border-radius: 4px;
  padding: 10px;
  font-size: .9rem ;
  font-weight: bold;
}

#menu{
  padding:4px;
  text-decoration: none;
  font-size: 1rem;  
}

#menuContainer{
  cursor: pointer;
  display:inline;
  position:absolute;  
  background: lightgray;
  color: black;
  border-radius: 5px;
  top:0px;
  right:0px;
  margin-top: 5px;
  margin-right: 7px;
}

.menuItem{
  margin: 10px;
}

</style>