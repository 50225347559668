<template>
  <div id="login" class="login-container">
    <div class="login-card">
      <h1 class="title">Metal Ninja</h1>
      <div v-if="!$auth.loading">
        <div v-if="$auth.isAuthenticated" class="welcome-message">
          Welcome to Steel Ninja!
        </div>
        <div v-else class="login-message">
          Welcome to Steel Ninja! Please login using your credentials.
        </div>
        <button v-if="!$auth.isAuthenticated" @click="login" class="login-button">
          Log in
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    targetUrl: {
      type: String,
      targetUrl: ''
    }
  },
  name: 'LoginView',
  methods: {
    login() {
      if(this.targetUrl){
        console.log('after login, redirecting to: ', this.targetUrl)
        sessionStorage.setItem('redirectUrl', this.targetUrl)
      }
        
      this.$auth.loginWithRedirect();
    },
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.welcome-message, .login-message {
  margin-bottom: 1.5rem;
  color: #666;
}

.login-button {
  background: linear-gradient(45deg, #3498db, #2980b9);
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .login-card {
    width: 90%;
    padding: 1.5rem;
  }

  .title {
    font-size: 2rem;
  }
}
</style>