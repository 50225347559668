
import * as THREE from 'three';
import { Vector3, Quaternion, Matrix4, Matrix3, ZeroSlopeEnding } from 'three';
import MatrixHelper from '../helpers/matrixHelper.js';
import MemHelper from '../helpers/memHelper.js';
import cStructureMain from './StructureMain'
import cBase from './Base'
import Null from './Null.js'
import {rebuildTypes, impactTypes, CORE } from '../_spec.js'
import TreeNode from '../helpers/TreeNode.js'

import _3dColumnStraight from '../3d/ColumnStraight'
import Util from '../utility.js';
import StructureBase from './StructureBase';
import StructureHelper from '../helpers/StructureHelper.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import StructureMain from './StructureMain';
import StructurePorch from './StructurePorch.js';
import { faCommentsDollar } from '@fortawesome/pro-duotone-svg-icons';
import CollisionHelper from '../helpers/CollisionHelper.js';
import CompassRose from '../3d/CompassRose.js';
import layerHelper from '../helpers/layerHelper.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import Model from './model.js';
import Appearance from './appearance.js';
import OptionHelper from '../helpers/optionHelper.js';
import DesignHelper from '../helpers/DesignHelper.js';
import SystemLayoutAnalyzer from './SystemLayoutAnalyzer.js';
import { vec3 } from 'three/examples/jsm/nodes/shadernode/ShaderNode.js';
import BuildLogic from './BuildLogic.js';
import SystemHelper from '../helpers/SystemHelper.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import StructureLeanTo from './StructureLeanTo';
import { CssSyntaxError } from 'postcss';

// proper terminology:
// Building here should be system.
// Structure class should be a building.
export default class System extends cBase{
    constructor(des, dManager, model){
        super(des);
        this.masterDesign = dManager;
        this.model = model;
        // build it at the origin       
        //this.onDetectImpact = new CustomEvent('detectImpact', { detail: elem.dataset.time });
        this.build();
        
        this.matCollisionZone = new THREE.MeshLambertMaterial( { color: 0xff0000, opacity:.3, transparent:true, side: THREE.DoubleSide } )
        this.collisionZones = [];        
    }

    
    addComponent(c){        
        super.addComponent(c);        
        if(!this.structures)
            this.structures=[];
        if(c.design.type === 'structure')
            this.structures.push(c);   
        this.group.add(c.group);
    }

    setPosition(des){
        if(!des)
            return;
        if(!des.pos)
            return;
        if(Util.isDefined(des.pos.x))
            this.group.position.x = des.pos.x;
        if(Util.isDefined(des.pos.z))
            this.group.position.z = des.pos.z;
    }

    setRotation(des){
        if(!des)
            return;
        if(Util.isUndefined(des.rotRad))
            return;
        
            this.group.rotation.y = des.rotRad;
        
    }

    processRebuild(type){
        switch(type)
        {
            case rebuildTypes.move:                
                //this.rebuildFull();
                this.setPosition(this.design);
                this.setRotation(this.design);
                break;
            default:
                this.rebuildFull();
                break;

        }
    }

    rebuildsDone(){
        let systemCornerTrimDetails = SystemHelper.getAllUnRotatedSystemCoordinates(this);
        let systemMainAndLeanToStructures = this.design.getAllMainAndLeanToStructures();
        systemMainAndLeanToStructures.forEach((structure)=>{
            let cStructure = this.getComponentById(structure.id);
            if(cStructure == null)
                return;
            cStructure.buildAndOrientCornerTrim(BuildLogic.GetLeftEndWallPosX(cStructure.getStructureLengthInches()), BuildLogic.GetRightEndWallPosX(cStructure.getStructureLengthInches()), cStructure.initTrimMaterials(), systemCornerTrimDetails[structure.id])
        })
        
        //this.build()
        //call build dynamic only
        // change build corner trim method

    }

    getAllSystemCoordinates(){
        let systemLayout = new SystemLayoutAnalyzer();
        
        this.components.forEach((component) => {

            let {FL,FR,BL,BR} = StructureHelper.getWorldCoordinateCornersOfBuilding(component);
            let FLcopy = new Vector3(FL.x, 0,FL.z);
            let BLcopy = new Vector3(BL.x, 0,BL.z);
            let BRcopy = new Vector3(BR.x, 0,BR.z);
            let FRcopy = new Vector3(FR.x, 0,FR.z);

            let componentDetails = {
                corners: [
                    {corner: CORE.corners.FL, coordinate: FLcopy},
                    {corner: CORE.corners.BL, coordinate: BLcopy},
                    {corner: CORE.corners.BR, coordinate: BRcopy},
                    {corner: CORE.corners.FR, coordinate: FRcopy},
                ],
                componentID: component.design.id,
                design: component.design,
                eaveHeight: component.design.getFrame().height,
                length: component.design.getFrame().length,
                width: component.design.getFrame().width,
                roofType: component.design.getRoof().roofType,
                pitchRatio: BlueprintHelper.pitchToPitchRatio(component.design.getRoof().pitch),
                xMax: Math.max(FLcopy.x, BLcopy.x, BRcopy.x, FRcopy.x),
                xMin: Math.min(FLcopy.x, BLcopy.x, BRcopy.x, FRcopy.x),
                yMax: Math.max(FLcopy.y, BLcopy.y, BRcopy.y, FRcopy.y),
                yMin: 0,
                //yMin: Math.min(FLcopy.y, BLcopy.y, BRcopy.y, FRcopy.y),
                zMax: Math.max(FLcopy.z, BLcopy.z, BRcopy.z, FRcopy.z),
                zMin: Math.min(FLcopy.z, BLcopy.z, BRcopy.z, FRcopy.z)
            }
            systemLayout.xMax = Math.max(componentDetails.corners[0].coordinate.x, componentDetails.corners[1].coordinate.x, componentDetails.corners[2].coordinate.x, componentDetails.corners[3].coordinate.x, systemLayout.xMax);
            systemLayout.xMin = Math.min(componentDetails.corners[0].coordinate.x, componentDetails.corners[1].coordinate.x, componentDetails.corners[2].coordinate.x, componentDetails.corners[3].coordinate.x, systemLayout.xMin);
            systemLayout.yMax = Math.max(componentDetails.corners[0].coordinate.y, componentDetails.corners[1].coordinate.y, componentDetails.corners[2].coordinate.y, componentDetails.corners[3].coordinate.y, systemLayout.yMax);
            systemLayout.yMin = Math.min(componentDetails.corners[0].coordinate.y, componentDetails.corners[1].coordinate.y, componentDetails.corners[2].coordinate.y, componentDetails.corners[3].coordinate.y, systemLayout.yMin);
            systemLayout.zMax = Math.max(componentDetails.corners[0].coordinate.z, componentDetails.corners[1].coordinate.z, componentDetails.corners[2].coordinate.z, componentDetails.corners[3].coordinate.z, systemLayout.zMax);
            systemLayout.zMin = Math.min(componentDetails.corners[0].coordinate.z, componentDetails.corners[1].coordinate.z, componentDetails.corners[2].coordinate.z, componentDetails.corners[3].coordinate.z, systemLayout.zMin);
            systemLayout.structureCoordinates.push(componentDetails);
        })

        return systemLayout.getSystemCornerTrimDetails();
    }

    rebuildFull(){
        this.destroy();
        this.create();
    }

    

    destroy(){
        this.removeStructures();
    }

    build(){
     this.buildStructures();

        this.rebuildModels();

        this.group.rotation.y = this.design.rotRad;
     /*
        let cr = new CompassRose(50);
        cr.group.position.x = -700;
        cr.group.position.z = -700;
        this.group.add(cr.group);
        layerHelper.setGroup(cr.group, CORE.layers.constraint, true);
*/
        // null component is added programmatically to the end of the design template
        this.collisionZones = [];
        this.rebuildsDone();
    }

    rebuildModels(){
        
        let modelDesigns = this.getChildModels();
        modelDesigns.forEach((m)=>{
            let model = this.buildModel(m);
            //this.structures.push(structure);
            this.addComponent(model);
            //if(this.components.length>1) 
                //this.components[i+1] = model;
        });
    }

    getChildModels(){
        return this.design.components.filter((c) => c.type === CORE.components.model );
    }
    
    buildModel(mDes){
        let m = new Model(mDes)
        m.build();
        this.group.add(m.group);
        m.setPosition(mDes);
        return m;
    }

    

    locateConstrainee(cConstrainer, cConstrainee, constraint){        
        // rotate first (since rotation affects position)
        //calculate the rotation necessary to put the mating edges together
        let matingAngleRadians =  ConstraintHelper.getConstraintMatingAngle(constraint);
        // get the rotation of the constrainer (relative to the system)
        let constrainerRadians = cConstrainer.design.rotRad;        
        // calculate s2 absolute rotation considering s1's absolute rotation and the rotation required to satisfy the constraint      
        cConstrainee.setRotation(matingAngleRadians + constrainerRadians );
        this.group.rotation.y =0 // unrotate this system so the math is predictable and axis-aligned
        // calculate the point on the main structure 
        let s1Point = cConstrainer.getConstrainerPosition(constraint.parent);        
        // calculate the point on the porch structure
        let s2Point = cConstrainee.getConstraineePosition(constraint.child);

        this.group.rotation.y =this.design.rotRad; // re-rotate the system
        
        // calculate the difference (axis-alignment) between the two points 
        let delta = new Vector3(s1Point.x -s2Point.x,0,s1Point.z -s2Point.z)
        // calculate the origin of the porch using the difference
        cConstrainee.alterPosition(delta); 
        cConstrainee.disableRotationInput();
        cConstrainee.setPosition(cConstrainee.design)
    }


    detectImpact(impact){
        //dispatchEvent(this.onDetectImpact);
        dispatchEvent(new CustomEvent('detectImpact', { detail: impact }));

        let constraintNode;
        switch(impact.change.name){
            case impactTypes.systemPosition:
                // auto-detect 
                //this.linkNearByStructures(impact.design);
                // search for any structure very close to the structure being moved
                //this.updateStructureLocationsForConstrainer(impact.design.id);
                this.addRebuildNeeded(rebuildTypes.move);
                break;
            case impactTypes.systemRotation:            
                // when the parent structure changes, we have to update all children
                // when a child changes, we need to update the child
                    
                // Building/Structure/Foundation

                // when the menu option value is changed, parent.id = 3, which is the structure, becuse context is the foundation
                // when the grabject is dragged, parent.id = 1, which is the RootBuilding, because context is the structure
                //                 
                //this.updateStructureLocationsForConstrainer(impact.design.parent.id);
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        
            case impactTypes.structureWidth:
                // updateStructureLocationForConstrainee() MUST be called first as it updates the location of the structure who initally made an impact change
                this.updateStructureLocationForConstrainee(impact.design); // in case this structure is a constrainee of others (impact.design is the constrainee)
                this.updateStructureLocationsForConstrainer(impact.design.id); // in case this structure is a constrainer of others (impact.design is the parent of 1+ children)
               

                // update the location of this structure based on the constraint where it is a child, if one exists.
                //constraintNode = ConstraintHelper.findFirstConstraintNodeWithId(this.masterDesign.constraintRootNode, impact.design.id);

                //this.updatePorchDimensionsOnEndsOfStructure(constraintNode, impact)
                // foreach constraint
                    // if the constrained structure is a porch on left or right end,
                        // update the porch size along the mating edge, based on the size relative to the main structure as it was before the change 

                // update location of all constrained structures
                //this.updateConstrainedStructureLocations()
                break;
            case impactTypes.leanToWidth: 
            case impactTypes.porchWidth:
                
                    // update the location of this structure based on the constraint where it is a child, if one exists.
                    constraintNode = ConstraintHelper.findFirstConstraintNodeWithId(this.masterDesign.constraintRootNode, impact.design.id);
    
                    this.updatePorchDimensionsOnEndsOfStructure(constraintNode, impact)
                    // foreach constraint
                        // if the constrained structure is a porch on left or right end,
                            // update the porch size along the mating edge, based on the size relative to the main structure as it was before the change 
    
                    // update height this structure's constrainees
                    let constraint = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode, impact.id);
                    this.updateMaxRidgeHeightsForConstraints(constraint);
                            
                    // updateStructureLocationForConstrainee() MUST be called first as it updates the location of the structure who initally made an impact change
                    this.updateStructureLocationForConstrainee(impact.design); // in case this structure is a constrainee of others (impact.design is the constrainee)
                    this.updateStructureLocationsForConstrainer(impact.design.id); // in case this structure is a constrainer of others (impact.design is the parent of 1+ children)
                    

                    break;
            case impactTypes.structureLength:
                // updateStructureLocationForConstrainee() MUST be called first as it updates the location of the structure who initally made an impact change
                this.updateStructureLocationForConstrainee(impact.design); // in case this structure is a constrainee of others (impact.design is the constrainee)
                this.updateStructureLocationsForConstrainer(impact.design.id); // in case this structure is a constrainer of others (impact.design is the parent of 1+ children)

                //constraintNode = ConstraintHelper.findFirstConstraintNodeWithId(this.masterDesign.constraintRootNode, impact.design.id);
                //this.updatePorchDimensionsOnSidesOfStructure(constraintNode, impact);
                // foreach constraint
                    // if the constrained structure is a porch on front or back side
                        // update the porch size along the mating edge, based on the size relative to the main structure as it was before the change 

                // update location of all constrained structures 
                //this.updateConstrainedStructureLocations()
                break;
            case impactTypes.porchLength:
            case impactTypes.leanToLength:
                    // update the location of this structure based on the constraint where it is a child, if one exists.
                    constraintNode = ConstraintHelper.findFirstConstraintNodeWithId(this.masterDesign.constraintRootNode, impact.design.id);
        
                    this.updatePorchDimensionsOnEndsOfStructure(constraintNode, impact)
                    // foreach constraint
                        // if the constrained structure is a porch on left or right end,
                            // update the porch size along the mating edge, based on the size relative to the main structure as it was before the change 

                    // updateStructureLocationForConstrainee() MUST be called first as it updates the location of the structure who initally made an impact change
                    this.updateStructureLocationForConstrainee(impact.design); // in case this structure is a constrainee of others (impact.design is the constrainee)
                    this.updateStructureLocationsForConstrainer(impact.design.id); // in case this structure is a constrainer of others (impact.design is the parent of 1+ children)
                    break;
            case impactTypes.structureHeight:
                // find all porches for this structure
                // for each, call updateParentHeightForPorch()
                let constraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode, impact.id);
                // loop through all constraints
                this.updateMaxRidgeHeightsForConstraints(constraints);
                break;
        }
    }

    updateMaxRidgeHeightsForConstraints(constraints){
        constraints.forEach((c)=> {
            let constraineeId = c.child.structureID;
            let compConstrainee = this.getComponentById(constraineeId);
            // we're looping through all constraints (not just the ones relevant to this system) so this constrained structure may not be known to this system 
            if(!compConstrainee)
                return;
            if(compConstrainee.design.type === CORE.components.structure)
                return; 
            let desConstrainer = this.masterDesign.getComponentDesignById(c.parent.structureID);                    
            compConstrainee.updateMaxRidgeHeight(desConstrainer.getFrame().height * 12);

            let constraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode, constraineeId);
            if(constraints.length > 0)
                this.updateMaxRidgeHeightsForConstraints(constraints);
        })
    }

    updateStructureLocationsForConstrainer(parentId){
        // This method is responsible for updating the location of all the children constrained to the structure who initally 
        // made an impact change and NOT the structure itself. The structure who initally made an impact change IS the constrainer.        
        
        let constrainingStructureComponent = this.getComponentById(parentId);
        
        let constraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode, parentId);
        // loop through all constraints
        constraints.forEach((c)=> {
            let constrainedStructureComponent = this.getComponentById(c.child.structureID);
            // we're looping through all constraints (not just the ones relevant to this system) so this constrained structure may not be known to this system 
            if(!constrainedStructureComponent)
                return;
            // locate the constrainee based on it's parent
            this.locateConstrainee(constrainingStructureComponent, constrainedStructureComponent, c);
            // propagate this change down the tree
            this.updateStructureLocationsForConstrainer(constrainedStructureComponent.design.id)            
        })        
    }


    updatePorchDimensionsOnSidesOfStructure(constraintNode, impact){
        constraintNode.constraints.forEach((constraint)=>{
            let structureId = constraint.child.id;
            let cStructure = this.getComponentById(structureId);
            let dStructure = cStructure.design;
            if(dStructure.type !== CORE.components.porch)
                return;

            cStructure.design.length = impact.design.getFrame().length;

        });
    }

    updatePorchDimensionsOnEndsOfStructure(constraintNode, impact){
        constraintNode.constraints.forEach((constraint)=>{
            let structureId = constraint.child.structureID;
            let cStructure = this.getComponentById(structureId);
            let dStructure = cStructure.design;
            if(dStructure.type != CORE.components.porch && dStructure.type != CORE.components.leanTo)
                return;

            cStructure.design.length = impact.design.getFrame().width;

        });
    }

    updateStructureLocationForConstrainee(constraineeDesign){
        // This method is responsible for updating the location of the structure who initally made an impact change
        // and NOT the structure's children. The structure who initally made an impact change IS the constrainee. 

        // find this constrainee's node in the constraint tree
        let constrainingNode = ConstraintHelper.findConstrainerOfNodeWithId(this.masterDesign.constraintRootNode, constraineeDesign.id);
        if(!constrainingNode || constrainingNode.id == ConstraintHelper.GetRootConstraintNodeId())
            return; // there is no constrainer
        
        let constrainingConstraint = ConstraintHelper.getConstraintWithChildId(constrainingNode, constraineeDesign.id);
    
        //let constrainingStructureDesign = this.masterDesign.getComponentDesignById(constrainingNode.id);
        let constrainerStructureComponent = this.getComponentById(constrainingNode.id)
        let constraineeStructureComponent = this.getComponentById(constraineeDesign.id)
        // locate the constrainee based on it's parent
        this.locateConstrainee(constrainerStructureComponent, constraineeStructureComponent, constrainingConstraint)
    
        }
    
        updateConstrainedStructureLocations(){
        }

destroy(){
    this.removeStructures();
}


removeStructures(){
        
    this.structures.forEach((s)=>{
        this.removeStructure(s);
    });
    this.components = this.components.filter((x) => x.design.type === CORE.components.null);
}

removeStructure(s){
    s.remove();
}

remove(){
    this.components.forEach((c)=>{
        c.remove();
    })
    super.remove();
}

    
    buildStructures(){
        this.structures = [];
        let structureDesigns = this.getChildStructures();
        structureDesigns.forEach((s,i)=>{
            let structure = this.buildStructure(s);
            //this.structures.push(structure);
            this.addComponent(structure);
            // if(this.components.length>1) 
            //     this.components[i+1] = structure;
        });

    }
    getChildStructures(){
        return this.design.components.filter((c) => c.type === CORE.components.structure || c.type === CORE.components.porch || c.type === CORE.components.leanTo);
    }


    getOptionsSpec(){
        return[
            OptionHelper.text("name","Name", impactTypes.structureName, 
                (v)=>{//fnIn
                    return v;
                }, 
                (v)=>{//fnOut
                    const regex = new RegExp('[\\\\/:*?"<>|]', 'g')
                    if (regex.test(v)) {
                        return v.replaceAll(regex, '');
                    }
                    else {
                        return v;
                    }
                },
                (v)=> {
                    //fnChange
                    this.design.name = v;
                }
                
            ),
            OptionHelper.numericUpDown("rotRad", "Rotation", impactTypes.systemRotation, "degrees", undefined, undefined, 15, true,
			()=>{ //fnIn
				// load the value as degrees
                let r =Math.round(this.design.rotRad*180/Math.PI); 
				return r;
			},
			undefined,			
			(v)=>{//fnChange				
				this.applyChangeRotation(v);
			}
			)
        
        ];
    }

    applyChangeRotation(v){
		if(v<0)
			v+=360; // don't let the rotation be negative
		// normalize the value
		v=v%360;
		// convert the value for storage in radians
		this.design.rotRad = v*Math.PI/180;
        this.addRebuildNeeded(rebuildTypes.move);
	}
    
    buildStructure(sDes){

        let structure;

        if(sDes.type === CORE.components.structure)
            structure = new StructureMain(sDes,  null, this.masterDesign, this.model);
        else if(sDes.type === CORE.components.leanTo){
            structure = new StructureLeanTo(sDes, null, this.masterDesign, this.model);
            this.updateParentHeightForPorch(sDes, structure);
        }
        else{
            structure = new StructurePorch(sDes, null, this.masterDesign, this.model);        
            this.updateParentHeightForPorch(sDes, structure);
        }
        this.group.add(structure.group);
        structure.setPosition(sDes);        
        return structure;
    }


    
    updateParentHeightForPorch(porchDesign, secondaryStructure) {
        let constrainingNode = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, porchDesign.id);
        if(constrainingNode)
        {
            let parentDesign = this.masterDesign.getComponentDesignById(constrainingNode.parent.structureID);
            if(parentDesign){
                secondaryStructure.updateMaxRidgeHeight(parentDesign.getFrame().height * 12);
            }
        }
    }



    
  static addStructure(structureDesign){
    
    DesignHelper.initLoadedComponent(structureDesign, (this.designManager.version == 2)?3:this.designManager.version);
    
    let mainBuildings =  this.getAllMainStructures();
    if(mainBuildings && mainBuildings.length>0){
      let mainBuilding = mainBuildings[0];
      let mainBuildingAppearance = mainBuilding.getAppearance();      
      let newStructureAppearance = structureDesign.getAppearance();
      Appearance.mergeDesign(mainBuildingAppearance, newStructureAppearance);      
    }

    let newComponent;
    if(structureDesign.type === CORE.components.structure)
      newComponent = new StructureMain(structureDesign, CORE.sides.frontSide, this.masterDesign);
    else
      newComponent = new StructurePorch(structureDesign, CORE.sides.frontSide, this.masterDesign);

    //this.building.updateNullComponent();
    // this new component is on the null component until the cursor is moved over a remotely plausible parent component
    let newId = this.masterDesign.addStructureDesignToSystem(newComponent.design, this.design);
    
    newComponent.design = this.masterDesign.getComponentDesignById(newId)
    newComponent.design.selected=true; // so that grabjects are built    

    this.addComponent(newComponent);
    
    // start tracking the new feature
    // this.store.commit('setSelectedComponentId', newId) // immediately skip to positioning the foundation
    
    return newComponent;
  }


    getDimensions(){
        
        let structureDims = [];;
        // get all the min/max dimensions for all structures
        this.design.getAllMainStructures().forEach((c, ci)=>{

            // these are all world min/max values 
            structureDims.push(this.structures[ci].getDimensions());
        })  
        

        // aggregate those by selecting the minimal of all world mins and maximal of all world maxs
        let aggregatedDims = {}        
        structureDims.forEach((d) => {
            
            if(aggregatedDims.xmin) 
                aggregatedDims.xmin = Math.min(aggregatedDims.xmin, d.xmin)
            else
                aggregatedDims.xmin = d.xmin;
            
            if(aggregatedDims.xmax)
                aggregatedDims.xmax = Math.max(aggregatedDims.xmax, d.xmax)
            else
                aggregatedDims.xmax = d.xmax;

            if(aggregatedDims.zmin)
                aggregatedDims.zmin = Math.min(aggregatedDims.zmin, d.zmin)
            else
                aggregatedDims.zmin = d.zmin;

            if(aggregatedDims.zmax)
                aggregatedDims.zmax = Math.max(aggregatedDims.zmax, d.zmax)
            else
                aggregatedDims.zmax = d.zmax;
        })

        return aggregatedDims;
    }
    
    getCollisionClass(){
        return CORE.collisions.classes.none;
    }

    static canEdit(){return true;}
    static canCopy(){return false;}    
    static canDelete(){return false;}    

    getComponentsNotOnWalls(){
        let notOnWalls = [];

        // loop through all structures
        this.components.forEach((c)=>{
            if(c.design.type ===  CORE.components.null)
                return;
            let structure = c;
            let frontWall = structure.wallFront;
            if(frontWall){
                notOnWalls.push(...frontWall.componentsNotOnWall);
            }
            let backWall = structure.wallBack;
            if(backWall){
                notOnWalls.push(...backWall.componentsNotOnWall);
            }
            let leftWall = structure.wallLeft;
            if(leftWall){
                notOnWalls.push(...leftWall.componentsNotOnWall);
            }
            let rightWall = structure.wallRight;
            if(rightWall){
                notOnWalls.push(...rightWall.componentsNotOnWall);
            }
        })
        
        return notOnWalls;
    }


    
    getDescription(){
        if(this.design.name)
            return this.design.name;
        return "Group";
    }     


    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.footprint);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.model);
    }
}
