<template>
    <div class="markup-configuration">
      <h1>Pricing</h1>
  
      <router-link :to="{ path: itemManagerRoutePath }">
            Item Manager
        </router-link>

        <h1>Pricing Schemes</h1>
      <div class="schemes-container">
        <CategoryMarkup
          title="Bolt Up Scheme"
          ref="boltUpScheme"
          @save="saveScheme('boltUp')"
        />
  
        <CategoryMarkup
          title="Weld Up Scheme"
          ref="weldUpScheme"
          @save="saveScheme('weldUp')"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import CategoryMarkup from '@/components/CategoryMarkup.vue';
  import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    components: {
        CategoryMarkup
    },
    data() {
        return {
            testMarkups: [
                {"category": "taper frame", "markup": 0.1 },
                { "category": "beam", "markup": 0.15 },
                { "category": "tube", "markup": 0.12 },
                { "category": "weld plates", "markup": 0.08 },
                { "category": "eavestrut", "markup": 0.1 },
                { "category": "purlin", "markup": 0.12 },
                { "category": "clips", "markup": 0.05 },
                { "category": "insulation", "markup": 0.1 },
                { "category": "panels", "markup": 0.15 },
                { "category": "trim", "markup": 0.09 },
                { "category": "fasteners", "markup": 0.04 },
                { "category": "accessories", "markup": 0.06 }
            ],
            boltUpScheme: {},
            weldUpScheme: {},
            categoryMarkupKind: {
                Bolt_Up: 0,
                Weld_Up: 1,
            }
        }
    },
    computed:{
      ...mapState('contextModule', ['current']),
      
      itemManagerRoutePath(){
        let pathParts = this.$route.path.split("/");
        let entityId = pathParts[2];
        return `/${pathParts[1]}/${entityId}/itemManager`
      }
    },
    methods: {
        ...mapActions('pricingModule', ['fetchPriceSchemes']),
    },
    async created() {
        let pathParts = this.$route.path.split("/");
        let entityId = pathParts[2];
        let schemes = await this.fetchPriceSchemes(entityId);
        this.$refs.boltUpScheme.scheme = schemes.find(scheme => scheme.kind === this.categoryMarkupKind.Bolt_Up)
        this.$refs.weldUpScheme.scheme = schemes. find(scheme => scheme.kind === this.categoryMarkupKind.Weld_Up)
    },

  }
  </script>
  
  <style scoped>
  .markup-configuration {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .schemes-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: fit-content;
  }
  
  @media (max-width: 768px) {
    .schemes-container {
      flex-direction: column;
    }
  }
  </style>