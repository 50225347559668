<template><div>
    <v-dialog transition="pop-out" />
    <modal name="quote-adjust-modal" :height="400" :adaptive="true" @closed="closeQAModal">
        <div class="quote-adjust-content">
            <h3 class="modal-header">Quote Price And Weight Adjustments</h3>
            <div class="modal-body">
                <span v-if="qaInput.invalid" style="color:red;text-align:center">{{qaInput.message}}</span><br />
                <table>
                    <thead>
                        <tr>
                            <th v-for="h in ['', 'Quantity', 'Unit', 'Unit Rate']" style="text-align:center">{{h}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in quoteAdjustmentRows" :key="index">
                            <td class="qaCell" style="padding-right:10px">{{row.material}}</td>
                            <td class="qaCell"><input type="number" min="0" class="qaNumberInput" v-model.number="row.unitAdj" /></td>
                            <td class="qaCell">
                                <select v-model="row.adjUnit" class="qaCell">
                                    <option v-for="unit in ['Lbs', 'Lft', 'Ea']" :value="unit" :key="unit">{{unit}}</option>
                                </select>
                            </td>
                            <td class="qaCell"><input type="number" min="0" class="qaNumberInput" v-model.number="row.sellPerUnit" /></td>
                        </tr>
                    </tbody>
                </table>
            </div> 
            <div class="modal-footer">
                <div v-if="qaSave.showQAResultMessage">
                    <p v-if="qaSave.successful">Price and weight adjustments were updated successfully.</p>
                    <p v-else style="color:red">An error occured while updating price and weight adjustments.</p>
                </div>
                <span>
                    <button v-on:click="closeQAModal" type="button" class="btn btn-primary">Close</button>
                    <button style="background-color: green;" :disabled="qaSave.inProgress" v-on:click="validateAndUpdateQuoteAdjustments" type="button" class="btn btn-primary">Update Quote </button><!--<status-indicator style="position:absolute; margin-left: 5px;" ref="update-quoteAdjustments-indicator" v-bind:status-width="22" v-bind:status-height="22"></status-indicator>-->
                </span>
            </div>
        </div>
    </modal>
</div></template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default{
    props: {
        qaData: {
            type: Object,
        }
    },
    data(){
        return{
            quoteAdjustmentRows: [
                {material: 'Frame', unitAdj: 0, sellPerUnit: 0, adjUnit: 'Lbs'},
                { material: 'Purlin', unitAdj: 0, sellPerUnit: 0, adjUnit: 'Lft' },
                { material: 'Sheets', unitAdj: 0, sellPerUnit: 0, adjUnit: 'Lbs' },
                { material: 'Trim', unitAdj: 0, sellPerUnit: 0, adjUnit: 'Lft'},
                { material: 'Acc', unitAdj: 0, sellPerUnit: 0, adjUnit: 'Ea' },
            ],
            qaInput:{
                invalid: false,
                message: ''
            },
            qaSave:{
                showQAResultMessage: false,
                inProgress: false,
                successful: true,
            }
            
        }
    },
    methods: {
        ...mapActions('projectModule', ['fetchProjectDetails', 'updateQuoteAdjustments']),
        async fetchProjectDetailsByRef(ref){
            this.quoteAdjustmentRows = await this.fetchProjectDetails(ref)
        },
        closeQAModal(){
            console.log('hit close qa modal')
            this.qaData.show = false;
            this.qaData.ref = null;
            this.qaSave.showQAResultMessage = false;
            this.$modal.hide('quote-adjust-modal');
        },
        async validateAndUpdateQuoteAdjustments(){
            console.log("validateAndUpdateQuote")
            let convertQuoteAdjustments = this.quoteAdjustmentRows.map(row => {
                return { material: row.material, unitAdj: Number(row.unitAdj), sellPerUnit: Number(row.sellPerUnit), adjUnit: row.adjUnit };
            })

            this.validateQuoteAdjustmentInputs(convertQuoteAdjustments);
            if (this.qaInput.invalid)
                return;

            this.qaSave.inProgress = true;
            // let si = this.$refs['update-quoteAdjustments-indicator'];
            // si.loading();

            let response = await this.updateQuoteAdjustments({reference: this.qaData.ref, quoteAdjustments: convertQuoteAdjustments})
            
            if(response.isError){
                this.qaSave.successful = false;
                this.qaSave.inProgress = false;
                this.qaSave.showQAResultMessage = true;
                this.closeQAModal();
                this.updateQAError(response.data)
            }
            else{
                this.qaSave.inProgress = false;
                this.qaSave.successful = true;
                this.qaSave.showQAResultMessage = true;
            }
        },
        validateQuoteAdjustmentInputs(convertQuoteAdjustments) {
            let isInvalid = false;
            let errorMessage = '';

            for (let i = 0; i < convertQuoteAdjustments.length; i++) {
            
                if (convertQuoteAdjustments[i].unitAdj == null || convertQuoteAdjustments[i].unitAdj == 0) {
                    // if (convertQuoteAdjustments[i].sellPerUnit != null && convertQuoteAdjustments[i].sellPerUnit > 0) {
                    //     isInvalid = true;
                    //     errorMessage = `${convertQuoteAdjustments[i].material} Quantity must be greater than 0 because the corresponding Unit Rate value is greater than 0.`
                    //     break;
                    // }
                }
                else{
                    if (convertQuoteAdjustments[i].sellPerUnit <= 0 || convertQuoteAdjustments[i].sellPerUnit == null) {
                        isInvalid = true;
                        errorMessage = `${convertQuoteAdjustments[i].material} Unit Rate must be non-negative and greater than 0.`
                        break;
                    }
                    else{
                        if (convertQuoteAdjustments[i].adjUnit == null) {
                            isInvalid = true;
                            errorMessage = `${convertQuoteAdjustments[i].material} Unit must be set`
                            break;
                        }
                    }
                }
                
            }

            this.qaInput.invalid = isInvalid;
            this.qaInput.message = errorMessage;
        },
        updateQAError(errData){
            if(errData.locked){
                this.showAlert_QuoteTemplateLocked();
            }
            else{
                this.$modal.show('dialog', {
                    title: 'Warning',
                    text: `${errData.msg}`,
                    buttons:[
                    {
                        title: 'OK',
                        handler: () => {
                        this.$modal.hide('dialog')
                        }
                    },
                    ]
                })
            }      
        },
        showAlert_QuoteTemplateLocked(){
            this.$modal.show('dialog', {
                title: 'Warning',
                text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
                buttons:[
                    {
                    title: 'OK',
                    handler: () => {
                        this.$modal.hide('dialog')
                    }
                    },
                ]
            })
        },
    },
    watch:{
        'qaData.show'(newVal) {
            console.log('qa data show', newVal)
            if (newVal) {
                this.$modal.show('quote-adjust-modal');
            }
        },
         'qaData.ref'(newVal) {
            if (newVal) {
                this.fetchProjectDetailsByRef(newVal)
            }
        }
    },
    async created(){

    }
}

</script>

<style lang="scss" scoped>

.modal-header{
    padding: 2%;
    border-bottom: 1px solid lightgrey;
    text-align: center;
}

.modal-body{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.modal-footer{
    margin-top: 5%;
}

.btn{
    padding: 2%;
    margin: 2%;
}

</style>