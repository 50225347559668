<template>
    <div class="categoryMarkup">
      <h2>{{ title }}</h2>
      <select v-model="scheme.scheme">
        <option value="0">Component Price</option>
        <option value="1">Cost Markup</option>
      </select>
  
      <div v-if="scheme.scheme == priceSchemeTypes.CostMarkup" class="category-markup">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Markup %</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="markup in scheme.markups" :key="markup.id">
              <td>{{ CategoryMarkupTypes[markup.category] }}</td>
              <td>
                <input
                  :disabled="true"
                  class="markup-input"
                  v-model.number="markup.markup"
                  type="number"
                  step="0.01"
                  placeholder="Markup %"
                  max="1"
                  min="0"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <button :disabled="true" @click="saveScheme">Save</button>
      </div>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  
  export default {
    name: 'CategoryMarkup',
    props: {
        title: {
        type: String,
        required: true
        },
        // scheme: {
        // type: Object,
        // required: true
        // }
    },
    data(){
        return{
            priceSchemeTypes: {
                NotSet: -1,
                ComponentPrice: 0,
                CostMarkup: 1,   
            },
            CategoryMarkupTypes:[
                "Taper_Frame",
                "Beam",
                "Tube",
                "Weld_Plates",
                "Eavestrut",
                "Purlin",
                "Clips",
                "Insulation",
                "Panels",
                "Trim",
                "Fasteners",
                "Accessories"
            ],
            scheme:{}
        }
    },
    computed: {
        printscheme(){
            console.log(this.scheme)
        }
    },
    methods: {
        saveScheme() {
        this.$emit('save', this.schemeData);
        }
    }
  }
  </script>
  
  <style scoped>
  .categoryMarkup {
    flex: 1;
    min-width: 0;
    width: 70%;
  }
  
  h2 {
    margin-top: 0;
    text-wrap: nowrap;
  }
  
  .category-markup {
    margin-top: 10px;
  }
  
  .markup-input {
    text-align: right;
  }
  
  table {
    width: fit-content;
    border-collapse: collapse;
    font-size: 0.9em;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  input, select {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  select {
    margin-top: 5px;
  }
  </style>