<template>
  <div id="app">
    <NavBar></NavBar>
    
    
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import ContextLogic from './lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
//import SidebarLayout from './components/SidebarLayout.vue'
import SidebarLayout from './components/SidebarLayout.vue'
import {CORE} from './constants.js'
import NavBar from './components/NavBar.vue';

export default{
  name: 'App',
  data(){
    return{
      collapsed:false,
      dashboardPath: null,
      routePath:''
    }
  },
  components:{
    SidebarLayout,
    NavBar
  },
  watch:{
    $route(to, from) {
      // React to route changes...
      console.log('Route changed from', from.path, 'to', to.path)
      // Perform any necessary actions here
      this.routePath = this.$route.path; // update this to force showSidebar to recalculate
    }
  },
  computed:{  
    ...mapState('contextModule', ['currentContext', 'mode']),  
    showSidebar(){
      console.log('app: showSidebar')
      if(!this.$auth || !this.$auth.isAuthenticated)
      {
        return false;
      }
      let pathsWithoutSidebar=['design','login', ];
      return !(pathsWithoutSidebar.some(path =>  this.routePath.includes(path)));
    },
    
    toggleLabel(){
      return this.collapsed?"]>":"<]";
    },
    ...mapState('contextModule', ['current']),
    sdkClientLoaded(){
      //Check that the SDK client is not currently loading before accessing is methods
      // console.log('isLoading',this.$auth.loading)
      // console.log('isAuth', this.$auth.isAuthenticated)
      return !this.$auth.loading;
    }
  },
  methods: {
    toggleSidebar(){
      this.collapsed=!this.collapsed;
      localStorage.setItem('sidebar-collapsed', JSON.stringify(this.collapsed))
    },
    ...mapActions('contextModule', ['']),
    ...mapMutations('contextModule', ['setMode']),
    
    
    async setCurrentContextForUser(){
      // let currentUser = this.fetch(this.$auth.user.sub);
      // sessionStorage.setItem('currentUser', currentUser);
      
      // let entity = this.fetchEntity(currentUser.entityId);
      ContextLogic.initContext(this);
      if(this.current.mode == CORE.entities.enterprise)
        this.dashboardPath = '/';
      else if(this.current.mode == CORE.entities.company)
        this.dashboardPath = `/company/${JSON.parse(sessionStorage.getItem('currentUser')).entityId}`;
      else
        this.dashboardPath = `/contractor/${JSON.parse(sessionStorage.getItem('currentUser')).entityId}`;

      }
  },
  created() {
    console.log('app created')
    let sCollapse = localStorage.getItem('sidebar-collapsed');
    this.collapsed = JSON.parse(sCollapse);
  }
}
 
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 100vh;
  overflow-y:auto;
}

nav {
  

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

}
body, html {
            margin: 0;
            padding: 0;
            height: 100%;
            font-family: Arial, sans-serif;
        }
        // #app {
        //     display: flex;            
        //     min-height: 100vh;
        // }
        #main {
          display:inline-block;
            flex-grow: 1;
            padding: 20px;
        }
        #sidebar.collapsed {
            width: 50px;
        }
        .stickyBottom{
          position: fixed;
            left: 10px;
            bottom: 10px;
            z-index: 1;

        }

</style>
