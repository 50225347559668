/*
Controller should disappear
Model will be a helper
View will be a helper, maybe.


*/



/*
Modifies the model based on the user's input through the view
*/
import {CORE, editingModes, cameraModes, selectionTypes, modes, rebuildTypes, impactTypes } from './_spec.js'
import Vue from 'vue'
import FeatureHelper from './helpers/featureHelper.js'
import OverlayHelper from './helpers/overlayHelper.js'
import PickHelper from './helpers/PickHelper.js'
import * as THREE from 'three';
import ComponentHelper from './helpers/featureHelper.js'
import fetch from 'node-fetch';
import Util from './utility.js'
import Dictionary from './helpers/Dictionary.js'
import { Vector3 } from 'three';
import ConstraintHelper from './helpers/ConstraintHelper.js'
import DesignHelper from './helpers/DesignHelper.js'
import api from '@/api.js'
export default class controller  {

    constructor(){
      this.collisionZoneModes = [
        editingModes.edit,
        editingModes.editGrabject
      ]
      this.contextMenuData={
        sceneObject: {}, 
        position: {},
        options: []
      };
      //this.editableComponents = [];
      // initialize vue data objects with all possible future properties here so it can bind to them
    }

    isEditable(){
      return this.store.state.editable;
    }

    showQuoteWarning(){
      this.modal.show('dialog', {
        title: this.store.getters.designerWarningTitle,
        text: this.store.getters.designerWarningMessage,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    showColorChangeScopePrompt(impact){      
      console.log(impact);
      const currentStructureName = this.store.getters.structure.design.name;
      let currentSystemDesign = this.m.getCurrentSystemDesign();
      const currentSystemName = currentSystemDesign.name;
      let colorSettingName = impact.change.name;
      switch(colorSettingName){
        case impactTypes.colorRoof: colorSettingName='Roof'; break;
        case impactTypes.colorWall: colorSettingName='Wall';break;
        case impactTypes.colorWainscot: colorSettingName='Wainscot';break;
        case impactTypes.colorTrim: colorSettingName='Trim';break;
        case impactTypes.colorTrimCornerAndBase: colorSettingName='Corner and Base Trim'; break;
        case impactTypes.colorTrimDoor: colorSettingName='Door Trim'; break;
        case impactTypes.colorTrimDownspout: colorSettingName='Downspout Trim'; break;
        case impactTypes.colorTrimEaveAndRake: colorSettingName='Eave and Rake Trim'; break;
        case impactTypes.colorTrimWalksAndWindows: colorSettingName='Walk Door And Window Trim'; break;
      }
      if(currentSystemDesign.components.length < 2)
        return;
      this.modal.show('dialog', {
        title: `${colorSettingName} Color Change`,
        text: `Do you want to apply this change to only <b>${currentStructureName}</b> or all structures in <b>${currentSystemName}</b>?`,
        buttons: [
          {
            title: `Only This Structure`,
            handler: () => {
              this.modal.hide('dialog')
              // this has already been handled
            }
          },
          {
            title: `Entire Building System`,
            handler: () => {
              this.modal.hide('dialog');
              // apply this one color change to all structures in the building system
              DesignHelper.forEachStructureInSystem(currentSystemDesign, (structureDesign) =>{                
                DesignHelper.applyColorChangeToStructure(impact, structureDesign);
              })
              let designRootComp = this.m.building;
              designRootComp.addRebuildNeeded(rebuildTypes.full);
              designRootComp.processRebuilds();
            }
          }
        ]
      })
    }

    showNameConflictWarning(name){
      this.modal.show('dialog', {
        title: 'Building Name Conflict',
        text: `Only one building can have the name "${name}". You must use a unique name for each building.`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    showCannotApplyGalvOptionsBecausePorchChildren(porchNames, parentName){
      this.modal.show('dialog', {
        title: `Warning: The Galvanized Setting Was Not Applied`,
        text: `Galvanized beams and purlins are only available for Bolt Up frame types. 
        The following porches have a non-bolt up frame type and are linked to the ${parentName}: ${porchNames.join(', ')}.`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    showUnappliedGalvOptionsWarning(structureName, structureFrameType){
      let dictionary = new Dictionary();
      this.modal.show('dialog', {
        title: `Warning: ${structureName}'s Galvanized Options Will Be Unset`,
        text: `You are changing the structure frame type from Bolt Up to ${dictionary.getTerm(structureFrameType)},
        and you have galvanized beams and/or purlins checked in Options. Galvanized beams and purlins are not available for Weld Up and Weld Plus 
        frame types.`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }
    showUnappliedPorchFrameTypeWarning(parentName, porchName, porchFrameType){
      let dictionary = new Dictionary();
      this.modal.show('dialog', {
        title: `Warning: Frame Type For ${porchName} Could Not Be Changed`,
        text: `You attempted to change the ${porchName} from Bolt Up to ${dictionary.getTerm(porchFrameType)},
        but the porch has inherited galvanized beams and/or purlins options from ${parentName}. Galvanized beams 
        and purlins are not available for Weld Up and Weld Plus frame types.`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    showCannotGableTieIn(endStructure, sideStructure){
      this.modal.show('dialog', {
        title: `Warning: Unable To Gable Tie ${endStructure} Into ${sideStructure}`,
        text: `${endStructure} has an end skirt and cannot be gable tied into ${sideStructure}.`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    promptDelete(id){
      if(id===null)
        return;

      let comp = this.m.getComponentById(id);
      if(comp === null)
        return;

      if(FeatureHelper.componentCanNotBeDeleted(comp.design.type))
        return;

      let des = comp.design;
      let descr = comp.getDescription()
      

      this.modal.show('dialog', {
        title: 'Confirm Deletion',
        text: `Are you sure you want to delete the ${descr}?`,
        buttons: [          
          {
            title: `Yes, delete`,
            handler: () => {
              this.store.dispatch('deleteComponent',id);
              this.modal.hide('dialog')
            }
          },
          {
            title: 'No, cancel',
            handler: () => {
              this.modal.hide('dialog')
            }
          }
        ]
      })
    }

    togglePorch(side){
      this.m.togglePorch(side);
      this.store.dispatch('refreshStructureNameMap');
      this.trackChange();
    }

    toggleLeanTo(side){
      this.m.toggleLeanTo(side);
      this.store.dispatch('refreshStructureNameMap');
      this.trackChange();
    }

    wrapShed(sideA, sideB){
      this.m.wrapShed(sideA, sideB)
      this.trackChange();
    }

    trackChange(){
      this.m.trackChange();
      
      
      if(this.store.getters.showQuote)
        this.store.dispatch('quoteDesignDebounce');
    }

    init(m,v, store, modal){
      this.m = m;
      this.v = v;
      this.store = store;
      this.modal = modal;
      this.store.commit('setEditingMode_View')
      
      switch(this.store.getters.mode){
        case modes.sandbox:
          this.initSandboxMode();
          break;
        case modes.viewEdit:
          this.initViewEditMode();
          break;
      }   
      
    }

    initSandboxMode(){
    }

    initViewEditMode(){
      this.v.setLayersByName(this.store.state.view.layers.current)
      this.store.dispatch('updateMetaData');
      this.store.commit('updateLastSavedDesign', this.m.stringifyCircularJSON(this.m.designManager))
      this.v.onWindowResize();
      // auto select the first structure(building)
      this.store.commit('setSelectedComponent', this.m.getCurrentStructureComponent().design.id)
      this.store.dispatch('refreshStructureNameMap');
      
    }

    async getQuoteData(url,designId){
      //return await this.getQuoteData2(url, designId);
      return await this.getQuoteData3(url, designId);
    }

    async getQuoteData2(url,designId){
      url+="2";
      let quoteFieldBody = JSON.stringify({
        design: this.m.designManager,
        reference: this.store.state.bqRef
      });
      return await this.RunQuoteFlow(url, quoteFieldBody)
    }
    
    async getQuoteData3(url,designId){
      url+="3";
      let quoteFieldBody = this.m.stringifyCircularJSON({
        design: this.m.designManager,
        reference: this.store.state.bqRef
      });
      

      
      
      return await this.RunQuoteFlow(url, quoteFieldBody)
    }

    async RunQuoteFlow(url, body){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }

      let method = 'POST'
      const options = {method, headers};
      options.body = body;
     
      try{      
        let res = await fetch(url, options);
        return res;
      }
      catch{
        console.log(`quote fetch failed for ${designId}`);
        return null;
      }      
    }
    async fetchFeatureFlagsForProjectLvl1Entity(url, projectRef){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }

      let method = 'GET'
      const options = {method, headers};
      // options.body = body;
     
      try{      
        let res = await fetch(url, options);
        return res;
      }
      catch{
        console.log(`feature flag fetch failed for  ${projectRef}`);
        return null;
      } 
    }
    getWallHeightQuoteFieldValue(wallDesign){
      if(wallDesign.openWall)
        return "OPEN"; //https://trello.com/c/XAGuSaLL/98-bolt-template-open-bay-portal-frame-fixes

      if (wallDesign.openHeight>0)
        return (wallDesign.openHeight/12).toFixed(2);
      return null;
    }

    addStructure(){
      // creates a feature, but does not immediately plcae it somewhere on the barn
      // so there's no scene object available right away
      this.deselectComponent();
      let component = this.m.addMainStructure();
      let ud = component.getInitialGrabjectUserData();
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode
      this.store.dispatch('updateMetaData');
    }

    addGarage(){
      // creates a feature, but does not immediately plcae it somewhere on the barn
      // so there's no scene object available right away
      this.deselectComponent();
      let component = this.m.addGarage();      
      let ud = component.getInitialGrabjectUserData();
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode
      this.store.dispatch('updateMetaData');
    }

    
    addGabledPorch(){
      // creates a feature, but does not immediately plcae it somewhere on the barn
      // so there's no scene object available right away
      this.deselectComponent();
      let component = this.m.addGabledPorch();      
      let ud = component.getInitialGrabjectUserData();
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode
      this.store.dispatch('updateMetaData');
    }

    
    addModel(initialDesign){
      // creates a feature, but does not immediately plcae it somewhere on the barn
      // so there's no scene object available right away
      this.deselectComponent();
      let component = this.m.addModel(initialDesign);      
      let ud = component.getInitialGrabjectUserData();
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode
      this.store.dispatch('updateMetaData');
    }
    
    addComponent(type, presetDesign){
      // creates a feature, but does not immediately plcae it somewhere on the barn
      // so there's no scene object available right away
      this.deselectComponent();
      let component = this.m.addComponent(type, presetDesign);
      let ud = component.getInitialGrabjectUserData();
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode
    }

    copyComponent(id){
      this.deselectComponent();
      let original = this.m.getComponentById(id);
      let component = this.m.addComponent(original.design.type);
      component.applyDesign(original.design);
      component.design.parent.id = CORE.nullComponentId;
      let ud = component.getInitialGrabjectUserData();
      
      this.m.enableCollisionZones(component.getCollisionClass());
      this.store.commit('setEditingMode_Edit')
      this.setEditingGrabject(ud); // go from edit mode to grabject mode   
    }

    centerComponentOnWall(id){
      //let original = this.m.getComponentById(id);      
      let design = this.m.designManager.getComponentDesignById(id);
      let component = this.m.getComponentById(id);
      let parent = this.m.getComponentById(design.parent.id);
      parent.centerComponent(design);      
      parent.addRebuildNeeded(rebuildTypes.full);
      parent.processRebuilds();
    }

    getContextMenuData(){
      return this.contextMenuData;
    }
    
    getWizardTabData(id){      
      switch(id){
        case 'basic': return this.getWizardBasicTabOptions();
        case 'framing': return this.getWizardFramingTabOptions();
        case 'openings': return [];
        case 'colors': return this.getWizardColorsTabOptions();
        case 'options': return this.getWizardOptionsTabOptions();
      }
      return;
    }

    getWizardBasicTabOptions(){
      // layer setup
      

      // option setup
      if(typeof this.m === 'undefined')
        return null; // wizard mounts first before controller init is called with model.
        
      let structure = this.m.getCurrentStructureComponent(); // structure
      let options = {
        structure:structure.getOptions(),
        // frontSide: structure.sideFront.design,
        // backSide: structure.sideBack.design,
        // leftSide: structure.sideLeft.design,
        // rightSide: structure.sideRight.design
      };
      return options;
    }

    getWizardFramingTabOptions(){
      let structure = this.m.getCurrentStructureComponent(); // structure
      let wallFront = structure.wallFront;
      let wallBack= structure.wallBack;
      let wallLeft = structure.wallLeft;
      let wallRight = structure.wallRight;
      let options = {
        structure:structure.getOptions(),      
        wallFront: wallFront.getOptions(),
        wallBack: wallBack.getOptions(),
        wallLeft: wallLeft.getOptions(),
        wallRight: wallRight.getOptions(),        
      };
      return options;
    }

    getWizardColorsTabOptions(){
      let structure = this.m.getCurrentStructureComponent(); // structure
      let colors = structure.appearance;
      let options = {
        colors: colors.getOptions(),      
      };
      return options;
    }

    getWizardOptionsTabOptions(){
      let structure = this.m.getCurrentStructureComponent(); // structure
      let options = structure.options;
      
      let opts = {
        structure:structure.getOptions(),
        options: options.getOptions()
      };
      return opts;
    }

    setLayers(layers){
      this.v.updateCameraLayers(layers);
    }    

    showFront(){     
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.front.x, 100, pos.front.z);
    }

    showBack(){     
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.back.x, 100, pos.back.z);
    }

    showLeft(){     
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.left.x, 100, pos.left.z);
    }

    showFrontLeftPerspective(set){
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.flp.x, pos.flp.y, pos.flp.z, set);
    }
    showFrontRightPerspective(){
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.frp.x, pos.frp.y, pos.frp.z);
    }
    showBackLeftPerspective(){
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.blp.x, pos.blp.y, pos.blp.z);
    }
    showBackRightPerspective(){
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.brp.x, pos.brp.y, pos.brp.z);
    }

    showRight(){     
      let pos = this.getExteriorPresetCameraDistances();
      this.v.setCameraPosition(pos.right.x, 100, pos.right.z);
    }
    
    getExteriorPresetCameraDistances(){
      // this used to be for a single structure with child porches
      // but now it's for a building with potentially multiple structures, all structures being only direct children of the building.
      let dim = this.m.getBuildingDims();

			this.v.ctrlOrbit.target.set(dim.xCenter,100,dim.zCenter);
      this.v.ctrlOrbit.update();

      //let structure = this.m.getFirstComponentStructure(); // structure
      
      let marginPercent = 35;
      let fovDeg = this.v.cameraOrbit.fov;
      let aspectRatio = this.v.cameraOrbit.aspect;
      let vFOV = fovDeg * Math.PI / 180;
      //https://stackoverflow.com/questions/17837652/calculating-frame-and-aspect-ratio-guides-to-match-cameras/17840405#17840405
      let halfHorizontalFov = Math.atan( Math.tan( vFOV / 2 ) * aspectRatio );
      //let vFovRad = 2 * Math.atan( Math.tan( hFOV / 2 ) / aspectRatio );
      

      let widthInches = dim.width;
      let lengthInches = dim.length;

      let halfWidthInches = widthInches/2;
      let halfLengthInches = lengthInches/2;

      let widthWithPorchesInches = widthInches;
      let lengthWithPorchesInches = lengthInches;
      /*
      let frontSide = structure.sideFront.design;
      let frontPorch = frontSide.getPorch();
      if(frontPorch)
        widthWithPorchesInches += frontPorch.dim.width;

      let backSide = structure.sideBack.design;
      let backPorch = backSide.getPorch();
      if(backPorch)
        widthWithPorchesInches += backPorch.dim.width;
      
      let leftSide = structure.sideLeft.design;
      let leftPorch = leftSide.getPorch();
      if(leftPorch)
        lengthWithPorchesInches += leftPorch.dim.width;

      let rightSide = structure.sideRight.design;
      let rightPorch = rightSide.getPorch();
      if(rightPorch)
        lengthWithPorchesInches += rightPorch.dim.width;
*/
      let halfVisibleWidthInches = (widthWithPorchesInches / 2) * (1 +marginPercent/100); // half width in inches and some percent for margin      
      

      let leftViewX = halfVisibleWidthInches / halfHorizontalFov;
      //if(leftPorch)
        //leftViewX += leftPorch.dim.width;
      leftViewX += halfLengthInches; // add the length of the building
      let rightViewX = halfVisibleWidthInches / halfHorizontalFov;
      rightViewX += halfLengthInches; // add the length of the building

      let halfVisibleLengthInches = (lengthWithPorchesInches / 2) * (1 +marginPercent/100); // half length in inches and some percent for margin      
      let backViewZ = halfVisibleLengthInches / halfHorizontalFov;
      backViewZ += widthInches; // add the width of the building
      let frontViewZ = halfVisibleLengthInches / halfHorizontalFov;
      frontViewZ += widthInches; // add the width of the building

      // front-left perspective      
      // find the X and Z coordinates of the camera in just those 2 dimensions, first
      let viewAngleDeg = 30;
      let viewAngleRad = viewAngleDeg * Math.PI/180;
      

      
      let pointFL = new THREE.Vector2(-halfLengthInches, halfWidthInches); // X and Z
      let pointFR = new THREE.Vector2(halfLengthInches, halfWidthInches); // X and Z
      let pointBL = new THREE.Vector2(-halfLengthInches,-halfWidthInches); // X and Z
      let pointBR = new THREE.Vector2(halfLengthInches, -halfWidthInches); // X and Z
/*
      if(leftPorch)
        pointFL.x -= leftPorch.dim.width;

      if(rightPorch)
        pointFR.x += rightPorch.dim.width;

      if(frontPorch) {      
        pointFL.y += frontPorch.dim.width; // y is used for Z axis here
        pointFR.y += frontPorch.dim.width; // y is used for Z axis here
      }

      if(backPorch){
        pointBL.y -= backPorch.dim.width;
        pointBR.y -= backPorch.dim.width;
      }*/
      
      let widthWeight = .9;
      let lengthWeight = .9;
      let ltw = lengthInches / widthInches;
      // high ratio means add less to z
      // high ratio subtract more from x
      
      // equal width and length => 45 degrees
      // more width > more toward 45
      // more length > 
      
      

      // Front Left 
      let flCz = pointFL.y + (lengthWeight*lengthInches)  // Z coordinate
      let flCx = pointFL.x - (widthWeight*widthInches)// X coordinate
      let flXZ =new THREE.Vector2(flCx, flCz); 
      let flDist = flXZ.distanceTo(pointFL);
      let flCy = flDist * Math.tan(viewAngleRad)/2;
      let flp = new THREE.Vector3(flCx,flCy, flCz);
      

      // Front Right 
      let frCz = pointFR.y + (lengthWeight*lengthInches)  // Z coordinate
      let frCx = pointFR.x + (widthWeight*widthInches)// X coordinate      
      let frXZ =new THREE.Vector2(frCx, frCz); 
      let frDist = frXZ.distanceTo(pointFR);
      let frCy = frDist * Math.tan(viewAngleRad)/2;
      let frp = new THREE.Vector3(frCx,frCy, frCz);    

      
      // Back Left 
      let blCz = pointBL.y - (lengthWeight*lengthInches)  // Z coordinate
      let blCx = pointBL.x - (widthWeight*widthInches)// X coordinate      
      let blXZ =new THREE.Vector2(blCx, blCz); 
      let blDist = blXZ.distanceTo(pointBL);
      let blCy = blDist * Math.tan(viewAngleRad)/2;
      let blp = new THREE.Vector3(blCx,blCy, blCz);    


      // Back Right 
      let brCz = pointBR.y - (lengthWeight*lengthInches)  // Z coordinate
      let brCx = pointBR.x + (widthWeight*widthInches)// X coordinate      
      let brXZ =new THREE.Vector2(brCx, brCz); 
      let brDist = brXZ.distanceTo(pointBR);
      let brCy = brDist * Math.tan(viewAngleRad)/2;
      let brp = new THREE.Vector3(brCx,brCy, brCz); 


      return {
        flp,
        frp,
        blp,
        brp,
        front:{
          x: 0,
          z: frontViewZ
        },
        right:{
          x:rightViewX,
          z:0
        },
        back:{
          x: 0,
          z: -backViewZ
        },
        left:{
          x:-leftViewX,
          z:0
        }
      }
    }

    getComponentById(id){
      return this.m.getComponentById(id)
    }


    getContextMenuDataForComponent(id){
      let component = this.m.getComponentById(id)
      let options = component.getOptions();
      let design = component.design;
      return {design, options};
    }

    update(buildBack=true){
      /*
      Build the entire structure from scratch (wipe the scene and re-add everything)
      Note:
       - this is very expensive, so don't call a global barn update unless you have to
      */
      this.m.build(buildBack);
      if(this.collisionZoneModes.includes(this.store.getters.editingMode)){
        this.m.enableCollisionZones()
      }
    }

    getMetaData(){      
      let desFrame = this.m.getFirstStructureFrameDesign();
      
      return {
        width: desFrame.width,
        length: desFrame.length,
        height: desFrame.height,
        frameType: desFrame.frameType,
        isMultiStructure: this.m.isMultiStructure()
      }
    }

    selectComponent(id){

      if(id!==null){
        let design = this.m.designManager.getComponentDesignById(id);
        if(design.type === CORE.components.roof)
        {
          id = design.parent.id;
        }
      }
      this.store.commit('setSelectedComponentId',id);



      this.m.updateSelectedProps(id);

      
      // update the component tree now that something is selected
      this.store.commit('updateComponentTreeData', this.m.getComponentTreeMenuData());

      let component;
      let outlineMeshes;

      if(id!==null){
        // get the component for that id
        component = this.m.editComponent(id);        
        component.showGrabjects(true);
        // fetch the outline meshes for that component
        if(component)
            outlineMeshes = this.getOutlineMeshesForComponent(component);
      }      
      
      this.v.setOutlinedMeshes(outlineMeshes);

      return component;
    }

    getOutlineMeshesForComponent(component){
      // all components get a say based on who was selected
      let allSelectedComponentDesigns = this.m.detectAllSelected(component, selectionTypes.default);
      let outlineMeshes = [];
      allSelectedComponentDesigns.forEach((cd)=>{
        let comp = this.m.getComponentById(cd.design.id);
        if(typeof comp.getOutlineMeshes !== 'function')
        {
          console.log('no outline')
        }
        else{

        
        let newMeshes = comp.getOutlineMeshes();
        if(newMeshes)
          outlineMeshes.push(...newMeshes);
        }
      })
      return outlineMeshes;
    }

    contextMenuChange(change){
      this.m.updateComponentDesign(change);
      let editingComponent = this.m.getEditingComponent();
      if(editingComponent)
      {
        let outlineMeshes = this.getOutlineMeshesForComponent(editingComponent);
        this.v.setOutlinedMeshes(outlineMeshes);
      }
      this.trackChange();      
    }

    
    deleteAddingComponent(){
      // delete triggered during addition of a new component
      if(!this.m.editingComponent())
        return;


      // delete system from design if editingComponent is a main structure
      if(this.m.store.getters.addingComponent === "new building"){
        let desSystem = this.m.getComponentById(this.m.editingComponent().design.parent.id).design
        this.m.designManager.removeSystemFromDesign(desSystem)
        ConstraintHelper.deleteConstraint(this.m.designManager.constraintRootNode, this.m.editingComponent().design.id);
      
        
        this.store.dispatch('deleteComponent', this.m.editingComponent().design.id);
      }
      else{
        if(this.store.state.optsContext.onCancel)
          this.store.state.optsContext.onCancel();
        else if(this.store.state.optsContext.onClose)
        this.store.state.optsContext.onClose();
      }
        
      

      // reset editingComponent to first structure in first system
      let firstStructureID = this.m.designManager.design.getSystem().getStructure().id
      this.store.commit('setSelectedComponentId', firstStructureID);
    }
    

    contextMenuDelete(componentId){
      // delete triggered from a component options panel

      // let desSystem = this.m.getComponentById(this.m.editingComponent().design.parent.id).design
      // this.m.designManager.removeSystemFromDesign(desSystem)
      this.m.deleteComponent(componentId);
      this.updateContextMenu();
      this.store.commit('setEditingMode_View')
      this.trackChange();
      
      // reset editingComponet to first structure in first system
      let id = null;
      let firstStructure = this.m.designManager.design.getSystem().getStructure();
      if(firstStructure !== null)
        id = firstStructure.id;
      this.store.commit('setSelectedComponentId', id);
    }

    updateContextMenu(){
      
      //this.contextMenuData.sceneObject= null;
      //this.contextMenuData.position= {};

      if(this.store.getters.selectedComponentId!==null){
        //let component = this.m.getComponentById(this.store.getters.selectedComponentId); // use store();
        //let cmPosition = component.getContextMenuPosition();
        
        //let coords = OverlayHelper.getScreenCoordinatesForVector(cmPosition, this.v.camera);
        //this.contextMenuData.sceneObject= selected;
        //this.contextMenuData.position= coords;   
        
        this.contextMenuData.options = this.m.getEditingComponentOptions();
        this.contextMenuData.componentId = this.m.getEditingComponent().design.id;
      }
    }

    contextMenuConfirm(){
      this.stopEditingComponent();
      this.deselectComponent();
    }
    
    contextMenuApplyChange(data){
      // this is not used because the context menu was too large and was disabled
      this.m.updateEditingComponent(data);
    }

    deselectComponent(){
      this.store.commit('setSelectedComponent', null)
      //this.selectComponent(null);
      this.v.setOutlinedMeshes(null);
      this.store.commit('hideOptionsPanel');
      this.store.commit('setEditingMode_View')
    }

    inputKeyDown(e){
      //console.log('inputKeyDown', e);
      if (e.target.tagName.toLowerCase() === 'input') {
        // ignore input tab keypresses
        return;
      }
      if(e.code == 'KeyM'){
        console.log(this.v.renderer.info)
        if(this.v.stats.domElement.style.display === 'none'){
          this.v.stats.domElement.style.display = 'block';
            
        let {objects, triangles, vertices} = this.v.getObjectTriVertCounts()
        console.log(`objects: ${objects}`)
        console.log(`triangles: ${Number(Math.round(triangles))}`)
        console.log(`vertices: ${Number(vertices)}`)
        }
        else
          this.v.stats.domElement.style.display = 'none';

      }

      if(e.code == 'KeyO'){
        let counts = this.v.getObjectTriVertCounts(true);
        console.log(counts);
      }

      if(e.code==='Space'){


        var performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};

        var canvas = this.v.canvas;
        var gl = canvas.getContext("experimental-webgl");
        if(gl==null)
          gl = canvas.getContext("webgl");
        if(gl==null)
          gl = canvas.getContext("webgl2");
        

        function getUnmaskedInfo(gl) {
          var unMaskedInfo = {
            renderer: '',
            vendor: ''
          };

          var dbgRenderInfo = gl.getExtension("WEBGL_debug_renderer_info");
          if (dbgRenderInfo != null) {
            unMaskedInfo.renderer = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
            unMaskedInfo.vendor = gl.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL);
          }

          return unMaskedInfo;
        }

        let context = {
            design: this.m.designManager.design,
            glRENDERER: gl.getParameter(gl.RENDERER),
            glVENDOR: gl.getParameter(gl.VENDOR),
            glUmRenderer: getUnmaskedInfo(gl).renderer,
            glUmVendor: getUnmaskedInfo(gl).vendor,
            performance            
        }
        //Rollbar.error("Spacebar", context);
      }
        
      if(e.code==='Escape')
        this.store.commit('setEditingMode_View')
      if(e.code === 'KeyP' && e.ctrlKey==false){
        let calcDesign = this.m.getCalculatedDesign()
        console.log(calcDesign);
        calcDesign.componentCount = DesignHelper.componentCount;
        console.log(this.m.stringifyCircularJSON(calcDesign));
      }
      if(e.code === 'KeyX'){
        // load
        //const scene = new THREE.ObjectLoader().parse( json );

        // save
        //const json = this.v.scene.toJSON();
        //console.log(json);
      }
      
      if(e.code === 'KeyI'){        
        //this.v.scene =  new THREE.ObjectLoader().parse( window.importDesign );
      }

      if(e.code === 'KeyC'){
        //console.log(this.m.getAllComponentIds());
      }  
      if(e.code === 'KeyM'){ // M is for Model
        // 3D printing export
        //this.addExporter()
        //var exporter = new THREE.STLExporter();
        //var stlString = exporter.parse(this.v.scene);
        //console.log(stlString);
      }

      if(e.ctrlKey && e.altKey && e.which === 81) // Ctrl + Alt + q
      {
        this.store.dispatch('toggleQuoting');  
      }

      if(e.ctrlKey && e.altKey && e.shiftKey && e.which ===69) // Ctrl + Alt + e
        this.store.dispatch('toggleEditing');

      if(e.key === 'Delete'){
        this.store.dispatch('promptDeleteComponent', this.store.getters.selectedComponentId);
      }

      if(e.code === 'KeyV'){
        this.store.commit('setEditingMode_View');

      }

      if(e.code === 'KeyC' && e.ctrlKey==false){
        //console.log(this.v.camera.position);

        switch(this.store.getters.camera.mode){
          
          case cameraModes.orbit:
            
            this.store.dispatch('switchToCamera',  cameraModes.first);
            break;
          case cameraModes.first:
            //this.store.commit('setCameraMode_Orbit');
            //this.v.switchCameraTo_Orbit();
            this.store.dispatch('switchToCamera', cameraModes.layout);
            break;
          case cameraModes.layout:
            this.store.dispatch('switchToCamera',  cameraModes.orbit);
            break;
        }
        
      }

      if(this.store.getters.camera.mode === cameraModes.first)
        this.v.processKeyDownFirstPerson(e);
    }

    addExporter(){
      // https://observablehq.com/@fil/export-a-three-js-scene-to-stl#CODE
      // http://piscis.github.io/webgl-3d-viewer/example/
      // https://github.com/atnartur/three-STLexporter
      /*
      THREE.STLExporter = function() {};

      THREE.STLExporter.prototype = {
        constructor: THREE.STLExporter,

        parse: (function() {
          var vector = new THREE.Vector3();
          var normalMatrixWorld = new THREE.Matrix3();

          return function(scene) {
            var output = "";

            output += "solid exported\n";

            scene.traverse(function(object) {
              if (object instanceof THREE.Mesh) {
                var geometry = object.geometry;
                var matrixWorld = object.matrixWorld;
                var mesh = object;

                if (geometry instanceof THREE.Geometry) {
                  var vertices = geometry.vertices;
                  var faces = geometry.faces;

                  normalMatrixWorld.getNormalMatrix(matrixWorld);

                  for (var i = 0, l = faces.length; i < l; i++) {
                    var face = faces[i];

                    vector
                      .copy(face.normal)
                      .applyMatrix3(normalMatrixWorld)
                      .normalize();

                    output +=
                      "\tfacet normal " +
                      vector.x +
                      " " +
                      vector.y +
                      " " +
                      vector.z +
                      "\n";
                    output += "\t\touter loop\n";

                    var indices = [face.a, face.b, face.c];

                    for (var j = 0; j < 3; j++) {
                      var vertexIndex = indices[j];
                      if (mesh.geometry.skinIndices.length == 0) {
                        vector
                          .copy(vertices[vertexIndex])
                          .applyMatrix4(matrixWorld);
                        output +=
                          "\t\t\tvertex " +
                          (+mesh.position.x + +vector.x) +
                          " " +
                          (+mesh.position.y + +vector.y) +
                          " " +
                          (+mesh.position.z + +vector.z) +
                          "\n";
                      } else {
                        vector.copy(vertices[vertexIndex]); //.applyMatrix4( matrixWorld );

                        // see https://github.com/mrdoob/three.js/issues/3187
                        const boneIndices = [];
                        boneIndices[0] = mesh.geometry.skinIndices[vertexIndex].x;
                        boneIndices[1] = mesh.geometry.skinIndices[vertexIndex].y;
                        boneIndices[2] = mesh.geometry.skinIndices[vertexIndex].z;
                        boneIndices[3] = mesh.geometry.skinIndices[vertexIndex].w;

                        const weights = [];
                        weights[0] = mesh.geometry.skinWeights[vertexIndex].x;
                        weights[1] = mesh.geometry.skinWeights[vertexIndex].y;
                        weights[2] = mesh.geometry.skinWeights[vertexIndex].z;
                        weights[3] = mesh.geometry.skinWeights[vertexIndex].w;

                        const inverses = [];
                        inverses[0] = mesh.skeleton.boneInverses[boneIndices[0]];
                        inverses[1] = mesh.skeleton.boneInverses[boneIndices[1]];
                        inverses[2] = mesh.skeleton.boneInverses[boneIndices[2]];
                        inverses[3] = mesh.skeleton.boneInverses[boneIndices[3]];

                        const skinMatrices = [];
                        skinMatrices[0] =
                          mesh.skeleton.bones[boneIndices[0]].matrixWorld;
                        skinMatrices[1] =
                          mesh.skeleton.bones[boneIndices[1]].matrixWorld;
                        skinMatrices[2] =
                          mesh.skeleton.bones[boneIndices[2]].matrixWorld;
                        skinMatrices[3] =
                          mesh.skeleton.bones[boneIndices[3]].matrixWorld;

                        var finalVector = new THREE.Vector4();
                        for (var k = 0; k < 4; k++) {
                          var tempVector = new THREE.Vector4(
                            vector.x,
                            vector.y,
                            vector.z
                          );
                          tempVector.multiplyScalar(weights[k]);
                          //the inverse takes the vector into local bone space
                          tempVector
                            .applyMatrix4(inverses[k])
                            //which is then transformed to the appropriate world space
                            .applyMatrix4(skinMatrices[k]);
                          finalVector.add(tempVector);
                        }
                        output +=
                          "\t\t\tvertex " +
                          finalVector.x +
                          " " +
                          finalVector.y +
                          " " +
                          finalVector.z +
                          "\n";
                      }
                    }
                    output += "\t\tendloop\n";
                    output += "\tendfacet\n";
                  }
                }
              }
            });

            output += "endsolid exported\n";

            return output;
          };
        })()
      };
*/
    }

    inputKeyUp(e){
      if(!this.store){
        console.log('inputKeyUp no store')
      }
      if(this.store.getters.camera.mode === 'first')
        this.v.processKeyUpFirstPerson(e);
    }

    activeInputEnd(){
      //this.updateContextMenu();
      if (this.isModeGrabject()){        
        //this.m.editingComponent().setGrabjectTransparency(false)
        this.editComponentById(this.m.editingComponent().design.id);
        let editingGrabject = this.store.getters.editingGrabject;
        let comp = this.m.getComponentById(editingGrabject.componentId);
        if(this.store.getters.isInLayoutView && comp.design.type === CORE.components.structure){
          let desSystem = this.m.getComponentById(comp.design.parent.id).design;
          // auto-detect 
            if(this.store.getters.autolink)
              this.m.linkNearBySystems(desSystem);
        }
        this.store.commit('setEditingGrabject', null)
        
      }
    }

    //mouse down move and touch screen move
    inputActiveMove(pickingData){
      if(this.isModeGrabject() || 
        this.isModeEdit()){
        this.moveGrabject(pickingData, true)
      }
    }

    cameraMoved(){
      //this.updateContextMenu();
      //this.v.stopCameraAnimation();
    }

    inputMove(picked) {
      if (this.store === undefined)
        return;

      if(this.isModeGrabject()){
        // move the grabject to the new position on an  appropriate surface
        let newPosition = this.moveGrabject(picked);
        if(newPosition===false)
          return;
        // now that the model has been updated, redraw only what just changed.
      }      

      if(this.isModeEdit()){
        let obj = this.getPickedGrabjectForEditingComponent(picked);
        if(obj)
          this.m.highlightGrabject(obj);
      }
    }

    inputClick(picked){

      if(this.isModeView()) {
        this.viewInputClick(picked);
      }
      else if (this.isModeGrabject()){
        this.grabjectInputClick(picked);        
      }
      else if(this.isModeEdit()) {
        this.editInputClick(picked);
      }
    }

    grabjectInputClick(picked){

      // get a grabject scene object if it was picked.
      // the following line does not consider the component to which the grabject belongs
      //let obj = this.v.pickHelper.getClosestObjectOfType(picked, [CORE.components.grabject]);
      let obj = this.getPickedGrabjectForEditingComponent(picked);
      if(obj===null){
        this.moveGrabject(picked, true)
        return;
      }

      this.setEditingGrabjectFromScene(obj); // set the grabject
      this.moveGrabject(picked, true)
    }
    
    viewInputClick(picked){
      // process a mouse click while in view mode
        let obj = this.getPickedObjectOfClickableComponent(picked);
        if(obj === null){
          this.deselectComponent();
          return;
        }       
        let id = this.m.getComponentIdFromObject(obj);

        // transition to edit mode
        this.editComponentById(id); 
    }

    
    getPickedObjectOfClickableComponent(picked){
      if(typeof picked.intersectedObjects === 'undefined' || 
        picked.intersectedObjects.length  === 0 )
        return null;

      let intersections = picked.intersectedObjects;
      let obj=null;
      intersections.forEach(function(i){
        if(typeof i.object.userData === 'undefined' || 
          obj!=null || i.object.userData.selectable === false)
          return;
        
          let type = i.object.userData.type;
          if(FeatureHelper.ComponentTypeIsClickable(type))
            obj = i.object;
      });

      return obj;
    }

    editComponentById(id){

      if(!this.isEditable()){
        return;
      }

      if(!id)
        return;

      let component = this.m.getComponentById(id);

      if(!component){
        console.log(this.m.designManager.design);
        throw `editComponentById: no component has id ${id}`  
      }      

      // THIS is a hack for first person camera mode such that
      // if you get too close to the wall and can't click out, 
      // you can simply click the wall again to toggle it's selection
      if(component.design.type === CORE.components.fsw ||
        component.design.type === CORE.components.bsw ||
        component.design.type === CORE.components.lew || 
        component.design.type === CORE.components.rew){
        if(this.store.getters.selectedComponentId === id)
        {
          this.store.commit('setSelectedComponent', null)
          this.store.commit('setEditingMode_View');
          return;
        }
      }


      this.store.commit('setSelectedComponent', id);
      
      // I need to understand how the selection/edit system works before I can work out where I should 
      // use  allSelectedComponentDesigns to gather up the outline meshes
      
      let allSelectedComponentDesigns = this.m.detectAllSelected(component, selectionTypes.default);
      allSelectedComponentDesigns.forEach((cd)=>{
        let comp = this.m.getComponentById(cd.design.id);
        comp.showGrabjects(true);
        
      })
      //component.showGrabjects(true);
      this.store.commit('setEditingMode_Edit')
    }

    setEditingGrabjectFromScene(grabjectSceneObject){
    // the controller sets previousGrabjectPosition for the model when a different
    // grabject is selected so that the last position for an old grabject is never
    // applied to a new grabject
      this.m.previousGrabjectPosition = grabjectSceneObject.getWorldPosition(new THREE.Vector3());

      this.setEditingGrabject(grabjectSceneObject.userData);
    }

      // grabjectSceneObject must be a grabject, not just whatever was clicked on.
    setEditingGrabject(data){
      // use this method to setup the EditingGrabject when a scene object is clicked.
      // identify function
      
      
      let eg  = { // update store
        role: data.role,
        allowedParentComponentTypes: data.allowedParentComponentTypes,
        basis: data.basis,
        componentId: data.parent.id
      };
      this.store.commit('setEditingGrabject', eg)
      this.store.commit('setEditingMode_Grabject')// takes us from view mode to editgrabject mode  
    }

    moveGrabject(pickingData, clicked){
      // a grabject typically moves or resizes a feature      
      if(typeof this.store.getters.editingGrabject === 'undefined' || this.store.getters.editingGrabject===null){ // use store
        return;
      }

      if(!this.isEditable())
        return;

      let {role, allowedParentComponentTypes, basis} = this.store.getters.editingGrabject; // use store
      
      let ec = this.m.editingComponent();
      if(!ec){
        console.log(`editing component is falsey`);
        return;
      }      

      // find intersections with components on which this grabject moves
      let pointerIntersection;


      // if the grabject is a position grabject,
      if(role=== CORE.grabjects.position || role == CORE.grabjects.rotation){// then any valid type of component can become the parent 
        // we have to ignore the current component and its sub-components since you can't put a component on itself or it's child-component.     
        let ignoreIds = [];
        // if the editing component is sound
        ignoreIds = ec.getAllComponentIds(); // make sure this component isn't tripped up by its self or its children

        // find intersections with components on which this grabject can be moved
        pointerIntersection = this.v.pickHelper.closestIntersectionWithObjectOfType(pickingData, allowedParentComponentTypes, ignoreIds);
      }
      else{      
        let componentId;
        let pickRole;

        if(basis === 'porch'){
          // we know we're looking for a frame-side to pick-test against
          // we need to know what constraining structure the frameside is part of
          
          // find the constrainingNode for this component
          let con4 = ConstraintHelper.getConstraintWithChildId(this.m.designManager.constraintRootNode, ec.design.id);
          let side = con4.parent.matingSide;
          // find the design for that component
          let parentDesign = this.m.designManager.getComponentDesignById(con4.parent.structureID);          
          // find the correct frameside in design (since there are four)
          // switch(side)
          // {
          //   case CORE.sides.frontSide:
          //     componentId = parentDesign.getFrontFrameSide().id;
          //     break;
          //     case CORE.sides.backSide:
          //     componentId = parentDesign.getBackFrameSide().id;
          //     break;
          //     case CORE.sides.leftEnd:
          //     componentId = parentDesign.getLeftFrameSide().id;
          //     break;
          //     case CORE.sides.rightEnd:
          //     componentId = parentDesign.getRightFrameSide().id;
          //     break;
          // }
          // // the framesides need to have taller impact walls [done]

        }
        else
        if(basis === 'self'){
          // a component may have multiple internal meshes for picking
          // get the current component
          componentId = ec.design.id;
          pickRole = role;

        }
        else{ // fallback to assuming the parent
          // a parent has a single mesh for picking
          // get the current component's parent          
          componentId = ec.design.parent.id;          
        }
        // allow only the targeted component
        pointerIntersection = this.v.pickHelper.closestIntersectionWithObjectOfIdType(pickingData, componentId, pickRole);
        
        
      }

      // find intersections with components on which this grabject moves
      //let pointerIntersection = this.v.pickHelper.closestIntersectionWithObjectOfType(pickingData, allowedParentComponentTypes, ignoreIds);
    
      if(pointerIntersection==null){
        //console.log('no intersection');
        return;
      }
      //console.log(pointerIntersection);
      let newPosition = pointerIntersection.point;

      //TODO: // make the ground a component, rather than all this junk. 9/27/2021
      // having a ground component doesn't fit the scheme very well right now.
      // there would never be multiple grounds
      // 

      let parentId;
      let parentComp;
      let newSnappedPosition;
      if(pointerIntersection.id !== null){
        if(pointerIntersection.objectType === CORE.components.ground)
        {
          newSnappedPosition = PickHelper.getGridSnappedPosition(newPosition, true, true);
          //parentId = 
        }
        else
        {
          parentId = pointerIntersection.id;
          parentComp = this.m.getComponentById(parentId);
          if(parentComp===null)
            {

              console.log(this.m.getCalculatedDesign())
              console.error("moveGrabject parent comp is null", parentId, )            
              return;

            }
          newSnappedPosition = parentComp.gridSnapWorldPosition(newPosition);
        }
      
      }
      else{
        
        //newSnappedPosition = gridSnapWorldPosition(newPosition); // ground component
        newSnappedPosition= newPosition;
      }      
      
     
      let similarPos = true;
      // compare to the last point, if the same, halt everything. this is for editing optimization
      if(typeof this.lastSnappedPosition === 'undefined')
      {
        this.lastSnappedPosition = new Vector3();
        this.lastSnappedPosition.copy(newSnappedPosition); 
        similarPos=false;
      }
      else
      {
        let distance = this.lastSnappedPosition.distanceTo(newSnappedPosition);          
        if(distance > 2){
          similarPos=false
        }
      }

      if(this.store.state.addingComponent && clicked){
        // if the parent is the NULL component
        if(ec.design.parent.id === 2)           
          return; // then this component hasn't actually been successfully placed anywhere on the structure yet
          // example of this is an OHD that's too tall to actually fit on the wall. 
        else
          this.store.dispatch("stopAddingComponent");
      }
        
      
      if(similarPos)
        return false;
      
      //console.log('NOT similar position to last snapped position')
      
      this.lastSnappedPosition.copy(newSnappedPosition);

      if(newPosition === null){
        console.log('null position');
        return;
      }
      
      let change;
      //  figures out what else to call 
      if(role === CORE.grabjects.position || role=== CORE.grabjects.rotation){
        if(Util.isUndefined(parentId))
        {
          if(ec.design.type == 'structure')
          {
            if(role===CORE.grabjects.position)
              this.m.setSystemPosition(newSnappedPosition);
            else
            {              
              this.m.setFoundationRotation(newSnappedPosition);
            }
          }
          else if (ec.design.type== 'model'){
            if(role===CORE.grabjects.position)
              this.m.setComponentPosition(newSnappedPosition);
          }
        }
        else
        // we just need to tell the feature where to be
        // the feature may not have been added to the scene (built) yet
        change = this.m.setEditingComponentPosition(newSnappedPosition, parentId);
      }
      else
        // tell the component that one of its non-position grabjects has moved
        change = this.m.setEditingComponentSize(role, newSnappedPosition, parentId);


      if(!change)
        return;

      /*
      If a door or window is moved,
      if the parent of a door or window is an end wall,
      and that end wall is coplanar with end of frame,
      rebuild blueprint
      */
     /*
      let doorsAndWindows = [
        CORE.components.window,
        CORE.components.doorRollup,
        CORE.components.doorWalkin3o7o,
        CORE.components.doorWalkin4o7o,
        CORE.components.doorWalkin6o7o,
      ]
      
      if(doorsAndWindows.includes(this.m.editingComponent().design.type) && 
        (parentComp.design.type === CORE.components.lew || parentComp.design.type === CORE.components.rew) &&
        parentComp.design.frameLine === 0
      ){
      // upgrade this change to blueprint (in case a column was affected which is relied upon by a porch)
        change = CORE.updates.blueprint;
      }
      
      if(change === CORE.updates.blueprint)
        this.update();
*/
      this.trackChange(); // keep the menu up to date (not part of vue for the sake of performance)

      let ec2 = this.m.editingComponent();
      if(ec2){
        let ecId = ec2.design.id;
        this.store.dispatch('showEditComponentOptions', ecId);
      }
    }


    stopEditingComponent(){
      this.m.stopEditingComponent();
      /*
      let component = this.m.getEditingComponent();
      if(component)
        component.showGrabjects(false);
      */
     this.m.hideAllGrabjects();
    }

    
  getPickedGrabjectForEditingComponent(pickingData){
    if(!this.isEditable())
      return null;
    // currently, this is searching for any intersection with a grabject, and highlighting it. That's not so bad.
    
    
    // for each grabject
    //  - get the worldMatrixPosition
    //  - get the 2D location of that position
    //  - check distance between grabject's 2D location and 2D cursor position.
    // closest grabject get highlighted 
    for (var which = 0; which < pickingData.intersectedObjects.length; which++) {
      let obj = pickingData.intersectedObjects[which].object;
      if (!obj.userData)
        continue;

      if(this.m.editingComponent() === null)
        return null;

      if(obj.userData.type === CORE.components.grabject && obj.userData.parent.id === this.m.editingComponent().design.id){        
        return obj;
      }
    }
    return null;
  }


    editInputClick(picked){
      // process mouse click while in edit mode

      if(!picked.intersectedObjects || 
        !picked.intersectedObjects[0] || 
        picked.intersectedObjects[0]===null || 
        !picked.intersectedObjects[0].object.userData)
        {          
          this.deselectComponent();          
          return
        }

      if(!this.isEditable()){
        this.deslectObjects();
        return;
      }

      // user can select a grabject while in edit mode
      // the following line presents a defect because grabjects exist even when not visible, and
      // getclosestObjectOfType does not discern which component's grabjects to consider valid
      //let obj = this.v.pickHelper.getClosestObjectOfType(picked, [CORE.components.grabject]);      
      let obj = this.getPickedGrabjectForEditingComponent(picked);
      if(obj!==null){
        // indicated it is selected somehow
        obj.parent.parent.children.forEach((o)=>{
          o.children.forEach((o2) =>{
            o2.material.color = new THREE.Color( CORE.colors.grabject.active ); //GREEN for actively being pushed
          })
          
        })
        this.setEditingGrabjectFromScene(obj);
        //this.store.dispatch('showEditComponentOptions', obj.userData.parent.id);
        return;

      }

      // user can select a new feature to edit while already in edit mode for another feature
      if(this.store.getters.editingGrabject == null){ // use store
        
        this.stopEditingComponent();
        let obj = this.getPickedObjectOfEditableComponent(picked);
        if(obj ===null){
          this.deselectComponent();          
          return;
        }
        let id = this.m.getComponentIdFromObject(obj);
          
        this.editComponentById(id); 
        return;
      }
      
      // find the first intersected object that the models editingComponent can go on      
      //get the point at that intersection
      this.moveGrabject(picked);
    }

    
    getPickedObjectOfEditableComponent(picked){
      if(typeof picked.intersectedObjects === 'undefined' || 
        picked.intersectedObjects.length  === 0 )
        return null;

      let intersections = picked.intersectedObjects;
      let obj=null;
      intersections.forEach((i)=>{
        if(typeof i.object.userData === 'undefined' || 
          obj!=null)
          return;
        
          let type = i.object.userData.type;
          if(type == CORE.components.roof)
            obj = i.object;
          if(FeatureHelper.ComponentTypeIsEditable(type))
            obj = i.object;
      });

      return obj;
    }

    isModeGrabject(){
      return this.store?.getters.editingMode === 'editGrabject'
    }

    isModeEdit(){
      return this.store?.getters.editingMode === 'edit'
    }
    isModeView(){
      return this.store?.getters.editingMode === 'view'
    }
    
  }
