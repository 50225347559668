
import * as THREE from 'three';
import {CORE, rebuildTypes , rakeTrimStyles, eaveTrimStyles, impactTypes} from '../_spec.js';
import Foundation from './foundationbase';
import RoofPorchSlope from './RoofPorchSlope';
import BlueprintHelper from '../helpers/blueprintHelper.js'
import cStructureBase from './StructureBase'
import cStructureSecondary from './StructureSecondary.js'
import OptionHelper from '../helpers/optionHelper.js'
import BuildLogic from './BuildLogic.js';

import Util from '../utility.js'
import FramelineHelper from '../blueprints/FramelineHelper.js'
import { Vector3, Quaternion } from 'three';
import _3dTrimCorner from '../3d/TrimCorner.js'
import _3dTrimGutterDownspout from '../3d/TrimGutterDownspout.js'
import _3dColumnStraight from '../3d/ColumnStraight.js'
import _3dRafterStraight from '../3d/RafterStraight.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import _3dDistHori from '../3d/DistHori.js'
import layerHelper from '../helpers/layerHelper.js';
import StructureHelper from '../helpers/StructureHelper.js';
import Grabject from './grabject.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import PickHelper from '../helpers/PickHelper.js';
import TreeNode from '../helpers/TreeNode.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import StructureBase from './StructureBase';
import { isPowerOfTwo } from 'three/src/math/MathUtils.js';
import DesignManager from '../design.js';
import SystemHelper from '../helpers/SystemHelper.js';
import DesignHelper from '../helpers/DesignHelper.js';
import StructureSecondary from './StructureSecondary.js';
import Appearance from './appearance.js';
import Options from './options.js';

export default class StructurePorch extends cStructureSecondary{
    /*
    this class represents a standard rectangular structure 
    The Ameristall raised center aisle would require another structure class
    */   
    constructor(design, side, mDesign, model, pos, rot){  
        for(let comp of design.components){
            if(comp.type === CORE.components.bsw){
                comp.openWall = true;
                break;
            }
        }
                  
        super(design, side, mDesign, model, pos, rot);  
    }

    
    initRebuildHierarchy(){        
        super.initRebuildHierarchy();
    }

    migrate(design){
        super.migrate(design);
        if(Util.isUndefined(design.left) || Util.isUndefined(design.right))
        {
            design.left = {
                wrapValid: false,
                wrapEnabled: false
            }
            design.right = {
                wrapValid: false,
                wrapEnabled: false
            }
        }

        if(!design.dim)
            design.dim ={
                heightOffset:0
            }
     
        design.roofFrameType= CORE.roof.frameTypes.bypass;
    }

    getOptionsSpec(){

        let baseOptions = super.getOptionsSpec();

        let myConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
        let sideOfMyParent = myConstraint.parent.matingSide;
        const {left: adjLeftSide, right: adjRightSide} = BlueprintHelper.getAdjacentSides(sideOfMyParent);

        // [DONE] frame type (option only enabled for bolt-up main structure)
        // [DONE] EndSkirting        
        // [DONE]Wainscoting?
        // [DONE] Open to main structure (always back for now)
        // [DONE] Include back wall checkbox
        // 
        // start
        // length
        // width

        return [
            this.getOption_FrameType(),
            // OptionHelper.switch("roofType", "Roof", "Slope", "Gable", impactTypes.structureRoofType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            // true,
            // ()=>{//fnIn
            //     return this.roofDesign.roofType === CORE.roof.types.gable;
            // },
            // (v)=>{//fnOut
            //     if(v)
            //         return CORE.roof.types.gable;
            //     return CORE.roof.types.slope;
            // },
            // (v)=>{//fnChanged
            //     this.roofDesign.roofType = v;
            //     //this.updateMaxRidgeHeight()
            // }
            // ),

            OptionHelper.numericUpDown("width","Width", this.getImpactTypeForWidth(), "ft.", 
            this.getWidthMinimum(), 100, 1,
            true,
//            OptionHelper.numericUpDown("width","Width", impactTypes.structureWidth, "ft.", 20, 100, 1,
            ()=>{ //fnIn
                return this.frameDesign.width;
            }, 
            undefined,
            (v)=>{ //fnChange
                this.applyChangeWidth(v*12);
            },  
            ),

            OptionHelper.numericUpDown("length","Length", this.getImpactTypeForLength(), "ft.", 6, 300, 1,
            true,
//            OptionHelper.numericUpDown("length","Length", impactTypes.structureLength, "ft.", lengthMin, 300, 1,
            ()=>{
                return this.frameDesign.length;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                this.frameDesign.length = v;
            }
            ),

            OptionHelper.numericUpDown("dim.heightOffset","Height offset",  impactTypes.structureHeight, "in.", undefined, undefined, 1, true,
            undefined,
            undefined,
            (v)=>{
                let shedMinPositiveHeightOffset = 12;
                // heightOffset are relative to the low-eave height of a main building
                // heightOffset has to either be flush or leave space (12 inches) for a short downspout
                // TODO: CBH - short-downspout rule enforcement is not as detailed as it should be...
                // this rule should ALWAYS apply to the FSW shed since the front of a main building is always a low side of the roof
                // this rule should apply to BSW sheds when the main building has a gabled roof
                // this rule should NEVER apply to BSW sheds when the main building has a single slope
                // this rule should apply to any shed wrapped with FSW shed because the entire wrapped shed should not go somewhere that the FSW shed cannot go
                // as of 2023.06.08, no detection of "is this shed wrapped with a front shed" is done. 
                // it's simplest and acceptable for now to always apply the rule to all sheds 

                // if this was flush, but now is in-between 0 and 12, go to 12, because the user increased the value and 12 is the next valid value above 0
                if(this.design.dim.heightOffset == 0 && v>0 && v<shedMinPositiveHeightOffset)
                    v=shedMinPositiveHeightOffset;
                
                // if this was 12, but now is in-between 0 and 12, go to 0, because the user decreased the value and 0 is the next valid value below 0
                else if(this.design.dim.heightOffset == 12 && v>0 && v<shedMinPositiveHeightOffset)
                    v=0;
                // if the new offset is in-between 0 and 12 (but the user did not request the next valid value up or down)
                else if (v>0 && v<shedMinPositiveHeightOffset) {
                    // if the value is coming from above the valid range, stop at the top edge of the valid range
                    if(this.design.dim.heightOffset > shedMinPositiveHeightOffset)
                        v = 12;                        
                    else // otherwise, the value is coming from below the valid range, stop at the bottom edge of the valid range
                        v =0;                    
                }
                
                this.design.dim.heightOffset = v;
                this.updateHeightFromHeightOffset(this.design, this.frameDesign, this.maxRidgeHeight );
            },
            "Inches below the low eave height. Negative values are allowed for tieing-in a shed above the low eave height, such as EW sheds or BSW sheds on a single slope building."),
            OptionHelper.numericUpDown("dim.height","Height",  impactTypes.structureHeight, "in.", 12, 240, 1, false, 

            ),
            OptionHelper.numericUpDown("roofPitch","Pitch", impactTypes.structureRoofPitch, "/12", 0, 6, 1.0/4.0,
            true,
            //fnIn
            ()=>{ 
                return this.roofDesign.pitch;
            },
            undefined,
            (v)=>{ // fnChanged
                StructureSecondary.updateRoofPitch(this.design, v);
            }
            ),
            OptionHelper.switch("roofFrameType", "Roof Frame Type", null, "Flush", "Bypass", impactTypes.structureRoofFrameType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            true,
            ()=>{//fnIn
                return this.roofDesign.roofFrameType === CORE.roof.frameTypes.bypass;
            },
            (v)=>{//fnOut
                if(v)
                    return CORE.roof.frameTypes.bypass;
                return CORE.roof.frameTypes.flush;
            },
            (v)=>{//fnChanged
                this.roofDesign.roofFrameType = v;
            }
            ),   

            OptionHelper.checkbox(`left.wrapEnabled`,'wrap to '+CORE.sides.getName(adjLeftSide), impactTypes.porchWrap,
            this.design.left.wrapValid,
            undefined, //fnIn
            

            undefined, //fnOut
            undefined
            ),
            OptionHelper.checkbox(`right.wrapEnabled`,'wrap to '+CORE.sides.getName(adjRightSide),  impactTypes.porchWrap,
            this.design.right.wrapValid,
            undefined, //fnIn
            
            undefined, //fnOut

            undefined),

            //OptionHelper.header('Left End'),
            //this.getOption_OpenBaysLeftEnd(),
            //this.getOption_SkirtLeftEnd(),

            //OptionHelper.header('Right End'),
            //this.getOption_OpenBaysRightEnd(),
            //this.getOption_SkirtRightEnd(),

            //...this.getOption_Wainscoting(),

            // OptionHelper.header('Back Opening'),
            // OptionHelper.checkbox(`openToMain`,'Main Structure Opening', impactTypes.structureWalls,
            // true,
            // undefined, //fnIn
            // undefined, //fnOut
            // (v)=>{ // fnChanged
            //     this.design.openToMain = v;
            //     if(v)
            //      this.design.backWall = false;
            // }
            // ),

            // OptionHelper.checkbox(`backWall`,'Include Back Wall', impactTypes.porchBack,
            // true,
            // undefined, //fnIn
            // undefined, //fnOut
            // (v)=>{
            //     this.design.backWall = v;
            //     if(v)
            //      this.design.openToMain = false;
            // }
            // ),

            

        ];
        
    }

    getImpactTypeForLength(){
        return impactTypes.porchLength;
    }

    getImpactTypeForWidth(){
        return impactTypes.porchWidth;
    }

    porchesCanWrap(p1Design, p2Design){
        // assumes p1 and p2 are adjacent
        if(!p1Design || !p2Design) throw "invalid argument: p1 and p2 must be truthy"


        if(p1Design.getFrame().width !== p2Design.getFrame().width ||
            p1Design.getFrame().height !== p2Design.getFrame().height ||
            p1Design.getRoof().pitch !== p2Design.getRoof().pitch)
            return false;

        return this.porchEndsMeet(p1Design, p2Design);
    }

    porchEndsMeet(p1Design, p2Design){

        let p1Constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, p1Design.id);
        let p2Constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, p2Design.id);
                
        let p1Side = p1Constraint.parent.matingSide;
        let p2Side = p2Constraint.parent.matingSide;

        let commonParent = this.masterDesign.getComponentDesignById(p1Constraint.parent.structureID);
        let commonParentWidth = commonParent.getFrame().width;
        let commonParentLength = commonParent.getFrame().length;
        let p1 = this.masterDesign.getComponentDesignById(p1Constraint.child.structureID);
        let p2 = this.masterDesign.getComponentDesignById(p2Constraint.child.structureID);
        let p1Offset = p1Constraint.parent.length;
        let p2Offset = p2Constraint.parent.length;
        let p1Length = p1.getFrame().length;
        let p2Length = p2.getFrame().length;

        switch(p1Side)
        {
            
            case CORE.sides.leftEnd: // porch on left end of main structure
                if (p2Side === CORE.sides.backSide && // if the adjacent porch is the backSide porch, and
                    p1Offset===0 && (p2Offset + p2Length) == commonParentLength)
                    return true; // then they are not only adjacent porches, but meet at the structure, and therefore wrappable.

                if (p2Side === CORE.sides.frontSide && 
                    (p1Offset + p1Length) == commonParentWidth && p2Offset == 0)
                    return true;

                // porch on right end of structure cannot be adjacent to porch on left end of structure
                break;
            case CORE.sides.rightEnd: // porch on right end of main structure                

                if (p2Side === CORE.sides.frontSide && 
                    p1Offset===0 && (p2Offset + p2Length) == commonParentLength)                    
                    return true;

                if (p2Side === CORE.sides.backSide && 
                    (p1Offset+p1Length) == commonParentWidth && p2Offset == 0)
                    return true;

                // porch on left end of structure cannot be adjacent to porch on right end of structure
                break;
            case CORE.sides.frontSide:
                if (p2Side === CORE.sides.leftEnd && 
                    p1Offset===0 && (p2Offset + p2Length) == commonParentWidth)
                    return true;
                if (p2Side === CORE.sides.rightEnd && 
                    (p1Offset + p1Length) == commonParentLength && p2Offset===0)
                    return true;

                // porch on back side of structure cannot be adjacent to porch on front side of structure
                break;

            case CORE.sides.backSide:
                if(p2Side === CORE.sides.leftEnd &&
                    (p1Offset + p1Length) == commonParentLength && p2Offset === 0)
                    return true;
                if (p2Side === CORE.sides.rightEnd && 
                    p1Offset == 0 && (p2Offset + p2Length) === commonParentWidth)
                    return true;
                
                // porch on front side of structure cannot be adjacent to porch on back side of structure
                break;
        }

        return false;

    }

    porchEndFullyExtended(pDesign, indexEnd){
        if(indexEnd === "low" && pDesign.frame.lowIndex===0) 
            return true;
        
        if(indexEnd === "high" && pDesign.frame.highIndex===-1) 
            return true;

        return false;
        // porch is the porchDesign
        // end is the low or the high end
    }

    processInputGrabject(input){
        if(input.role === CORE.grabjects.position){
            throw 'Porch position grabject is not currently supported'
        }
        else
            return this.updateSize(input);
    }

    processImpactFromSelf(impact){
        impact.handled.push(this.design.id);
        switch(impact.change.name){
            case impactTypes.structureWidth:
            case impactTypes.structureHeight:
            case impactTypes.structureRoofPitch:
            case impactTypes.structureWrap:                
            case impactTypes.structureLength:
            case impactTypes.structureRoofType:
            case impactTypes.structureRoofPitch:
            case impactTypes.structureOpenBays:
            case impactTypes.structureRoofFrameType:
            //case impactTypes.addPorch:
            case impactTypes.porchLength:
            case impactTypes.porchWidth:
            case impactTypes.porchWrap:            
                this.specificInit();
                this.initBlueprints();
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
        //this.calculateFramelines();
    }

    processImpactFromOther(impact){
        impact.handled.push(this.design.id);
        // impact is an impact from any component (including itself)
        // return 0+ impacts for itself 
        //console.log('Porch detecting impact:', impact);
        let constraint = ConstraintHelper.areConstrainedTogether(this.design.id, impact.id, this.masterDesign.constraintRootNode);

        switch(impact.change.name){
            case impactTypes.structureLength:   
            case impactTypes.structureFrameType:         
            case impactTypes.structureBaySpacing:
                if(constraint)
                    this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.structureWidth:
                
                if(constraint)
                    this.addRebuildNeeded(rebuildTypes.full);
                
                    if(impact.design.type === CORE.components.porch){
                        return this.detectWrappedPorchChangeImpact(impact);
                    }
                break;
            case impactTypes.foundationHeight:            
                //  check if the foundation's parent is constrained to this structure
                constraint = ConstraintHelper.areConstrainedTogether(this.design.id, impact.design.parent.id, this.masterDesign.constraintRootNode);
                if(constraint){
                    this.addRebuildNeeded(rebuildTypes.full);
                }
                break;
            case impactTypes.structureHeight:        
            case impactTypes.structureRoofPitch:    
            case impactTypes.structureWrap:            
            case impactTypes.porchWrap:            
            case impactTypes.addPorch:
            case impactTypes.porchWidth:
                let change = this.detectWrappedPorchChangeImpact(impact);

                if(change)
                    this.addRebuildNeeded(rebuildTypes.full);
                else{
                    if(impact.change.name === impactTypes.structureRoofPitch)
                        this.addRebuildNeeded(rebuildTypes.roof)
                }
                
                
                return change;
            case impactTypes.deletePorch:
                return this.detectPorchDeleteImpact(impact);
            case impactTypes.optionsGutter:
                this.addRebuildNeeded(rebuildTypes.roof);
                break;   
            case impactTypes.colorTrim:         
            case impactTypes.colorRoof:                
                this.addRebuildNeeded(rebuildTypes.roof);
                this.components[0].appearanceDesign = impact.design
                break;
            case impactTypes.optionsGalvanizedBeams:                
            case impactTypes.optionsGalvanizedPurlins:
            case impactTypes.optionsWrapTubePorchCols:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
                // if other porch got shorter, update wrapValidity, issue change if appropriate
                // if other porch got longer, update wrapValidity, issue change if appropriate
        }
        this.specificInit();
        this.initBlueprints();
    }
    getAddImpactType(){
        return impactTypes.addPorch;
    }

    detectPorchDeleteImpact(impact){
        const {left: adjLeftSide, right: adjRightSide} = BlueprintHelper.getAdjacentSides(this.design.side);
        
        if(adjLeftSide=== impact.design.side){
            this.design.left.wrapValid=false;
            this.addRebuildNeeded(rebuildTypes.full);
        }

        if(adjRightSide === impact.design.side){
            this.design.right.wrapValid=false;
            this.addRebuildNeeded(rebuildTypes.full);
        }
    }


    getSideOfPorchParent(id){
        super.getSideOfSecondaryStructureParent(id);
    }

    detectWrappedPorchChangeImpact(impact)
    {
        
        // I need myself, a porch
        // I need the impacted porch (impact)
        // I need my adjacent porches or the impacted porch's adjacent porches

        //TODO remove four porch assumption
        let myConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
        let othersConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, impact.design.id);
        // if this porch and the other porch are not on the same structure, ignore the change
        if(myConstraint.parent.structureID !== othersConstraint.parent.structureID)
            return;
        
        let sideOfMyParent = myConstraint.parent.matingSide;
        let sideOfOtherParent = othersConstraint.parent.matingSide;





        const {left: adjLeftSide, right: adjRightSide} = BlueprintHelper.getAdjacentSides(sideOfMyParent);
        
        // if this impact is for a porch adjacent to this porch
        if(adjLeftSide === sideOfOtherParent){
            if(this.updateDesignFromAdjacentPorchDesign(impact.design, impact.design.right, this.design, this.design.left)){                
                this.addRebuildNeeded(rebuildTypes.full);
                return UpdateHelper.createImpact(this.design, impactTypes.porchWrap);
            }
        }

        if(adjRightSide === sideOfOtherParent){
            if(this.updateDesignFromAdjacentPorchDesign(impact.design, impact.design.left, this.design, this.design.right)){
                this.addRebuildNeeded(rebuildTypes.full);
                return UpdateHelper.createImpact(this.design, impactTypes.porchWrap);
            }
        }
    }

    updateDesignFromAdjacentPorchDesign(changedPorchDesign, changedPorchEnd, adjacentPorchDesign, adjacentPorchEnd){
        // this method udpated the near end of the adjacent porch
        //  the right-end of the porch adjacent to the left 
        //  the left-end of the porch adjacent to the right
        let updated = false;
         // if already both valid, and both enabled,  
         if(changedPorchEnd.wrapValid && changedPorchEnd.wrapEnabled && adjacentPorchEnd.wrapValid && adjacentPorchEnd.wrapEnabled )
         {
             // keep the porches aligned
             adjacentPorchDesign.getFrame().width = changedPorchDesign.getFrame().width
             adjacentPorchDesign.dim.heightOffset = changedPorchDesign.dim.heightOffset
             adjacentPorchDesign.roofPitch = changedPorchDesign.roofPitch;
             adjacentPorchDesign.roofFrameType = changedPorchDesign.roofFrameType;
             StructureSecondary.updateRoofPitch(adjacentPorchDesign, changedPorchDesign.getRoof().pitch);
             this.updateHeightFromHeightOffset(adjacentPorchDesign, adjacentPorchDesign.getFrame(), this.maxRidgeHeight )
             updated= true;
         }
         // if both valid, and changed porch is latched to this porch, this porch should latch to it also
         else if(changedPorchEnd.wrapValid  && adjacentPorchEnd.wrapValid && changedPorchEnd.wrapEnabled && !adjacentPorchEnd.wrapEnabled){
            adjacentPorchEnd.wrapEnabled = true;
            updated = true;
         }
         // if both valid, and changed porch is not latched to this porch, this porch should unlatch from it also
         else if(changedPorchEnd.wrapValid  && adjacentPorchEnd.wrapValid && !changedPorchEnd.wrapEnabled && adjacentPorchEnd.wrapEnabled){
            adjacentPorchEnd.wrapEnabled = false;
            updated = true;
         }
         else{ // otherwise, update validity
 
             // check left porch wrap validity
             // update changed porch wrapLeftValidity
             // update left porch wrapRightValidity
             let canWrap = false;
             if(adjacentPorchDesign){
                 canWrap = this.porchesCanWrap(changedPorchDesign, adjacentPorchDesign);
                 if(adjacentPorchEnd.wrapValid!=canWrap){
                     adjacentPorchEnd.wrapValid = canWrap;
                     updated = true;
                 }
                 
             }
             changedPorchEnd.wrapValid = canWrap;
         }
         return updated;
    }
    
    removeWalls(){

    }

    specificInit(){
        this.design.type = CORE.components.porch;
        this.calculateFramelines(); 
        this.detectMatingFramelines();       
        this.frameDesign.isPorch = true;
        
        this.roofDesign.isPorch = true; 

        this.roofDesign.left={
            wrap: this.design.left.wrap
        }
        this.roofDesign.right={
            wrap: this.design.right.wrap
        }
        
        this.constrainingNode = ConstraintHelper.findConstrainerOfNodeWithId(this.masterDesign.constraintRootNode,this.design.id);
        if(this.constrainingNode)
        {
            //this.constraineeConstraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode,this.constrainingNode.id);
            let parentStructureDesign = this.masterDesign.getComponentDesignById(this.constrainingNode.id);
            if(parentStructureDesign)
            {
                this.foundationDesign = parentStructureDesign.getFoundation();

                this.appearanceDesign = this.design.getAppearance();
                Appearance.mergeDesign(parentStructureDesign.getAppearance(), this.appearanceDesign)
                
                this.optionDesign = this.design.getOptions();
                Options.mergeDesign(parentStructureDesign.getOptions(), this.optionDesign);           
            }
            else
                this.useDefaultDesigns();
            
        }
        else{
            // porch is mocked up once, then added to the design, so flow comes through here without a constraining node
            this.useDefaultDesigns();            
        }

        this.applyColors(this.appearanceDesign);
        this.beamColor = this.getBeamColor();
        this.purlinColor = this.getPurlinColor();
    }

    getDescription(){        

        if(this.design.name)
            return this.design.name;

        let sideName = '';
        let constrainerConstraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode,this.constrainingNode.id);
        if(constrainerConstraints && constrainerConstraints.length==1){
            let side = constrainerConstraints[0].parent.matingSide;
            sideName = CORE.sides.getName(side);
        }

        let name = this.design.name;
        if(sideName)
            name = sideName+" Porch"
        else
            name= "Porch"
        this.design.name = name;
    }

    buildPickOnly(){
        super.buildPickOnly(); // initialization is very important!

        
        let pitchHeight = 0; // unsure how to migrate
        let dim = new THREE.Vector3(this.frameDesign.length, this.frameDesign.height-pitchHeight, this.frameDesign.width).multiplyScalar(12);
        
        if(!this.meshPickDetectionXY){
            // a grabject intersection mesh cannot be removed during grabject editing or the grabjects will be jumpy, as the intersection relies on a consistent surface of some kind (even that surface is moving or affected)

            // XY
            var geoXY = new THREE.BoxGeometry(dim.x, dim.y*1.5, 2);
            geoXY.name = 'structureporch picking xy'
            this.meshPickDetectionXY = new THREE.Mesh(geoXY, CORE.materials.transparent);
            this.meshPickDetectionXY.userData={
                type:  CORE.grabjects.pitch, // because this grabject has a basis of 'self', controller will look for an intersection with a mesh with userData type matching this
                id: this.design.id
            }
            this.meshPickDetectionXY.visible=false;
            this.meshPickDetectionXY.name = "grabject XY backboard mesh for pitch of " + this.getDescription();
            
            var geoX = new THREE.BoxGeometry(dim.x, 1, dim.z*2); 
            geoX.name = 'structureporch picking x'
            this.meshPickDetectionX = new THREE.Mesh(geoX, CORE.materials.transparent);
            this.meshPickDetectionX.userData={
                type: CORE.grabjects.bottom,  // because this grabject has a basis of 'self', controller will look for an intersection with a mesh with userData type matching this
                id: this.design.id
            }
            this.meshPickDetectionX.visible=false;
            this.meshPickDetectionX.name = "grabject XZ backboard mesh for width of " + this.getDescription();

            this.gPick.add(this.meshPickDetectionXY);
            this.gPick.add(this.meshPickDetectionX);
            
        }
        this.meshPickDetectionXY.position.set(0,(dim.y)/2 ,dim.z/2 + 5);
        this.meshPickDetectionX.position.set(0, 7 ,dim.z/2);
        
    }

    buildGrabjects(dim){
        /*
        let halfDim = dim.clone().multiplyScalar(.5);
        this.grabjectGroup = new THREE.Group();
        this.grabjectGroup.name = 'porch grabject group';
        let grabjectSize = 15;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        let backOffset = this.getBackOffset();
        backOffset.z = 0;
        let frameSideParentComponents = [
            CORE.components.frameSide            
        ];

        let positionIndicators = [CORE.indicators.upDown];

        if(!this.design.left.wrapValid || !this.design.left.wrapEnabled)
            // top left (affects length)
            new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,dim.y,-halfDim.z), [CORE.indicators.leftRight], grabjectSizeVector, CORE.grabjects.topLeft, frameSideParentComponents, 'porch', new Vector3(0,15,5));
        
        if(!this.design.right.wrapValid || !this.design.right.wrapEnabled)
            // top right (affects length)
            new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,dim.y,-halfDim.z), [CORE.indicators.leftRight], grabjectSizeVector, CORE.grabjects.topRight, frameSideParentComponents, 'porch', new Vector3(0,15,5));

        // porch height
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,dim.y,-halfDim.z-backOffset.z), [CORE.indicators.upDown], grabjectSizeVector, CORE.grabjects.top, frameSideParentComponents, 'porch', new Vector3(0,15,5));
        // porch pitch
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,dim.y,halfDim.z), [CORE.indicators.upDown], grabjectSizeVector, CORE.grabjects.pitch, [], 'self', new Vector3(0,0,5));
        // porch width (depth)
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,10,halfDim.z), [CORE.indicators.inOut], grabjectSizeVector, CORE.grabjects.bottom, [], 'self', new Vector3(0,10,0));
        this.gDynamic.add(this.grabjectGroup);
        this.showGrabjects(this.design.selected);        
        */
    }

    buildDistanceMarkers(){
        // removeDistanceMarks undoes what's build here.
        super.buildDistanceMarkers();

        let flb = this.getWallFrontLeftBottom();
        let frb =  this.getWallFrontRightBottom();
        let width = this.getStructureWidthInches();
        if(this.design.left.wrap)
            flb.x-=width;
        if(this.design.right.wrap)
            frb.x+=width;

        this.frontDistHori = new _3dDistHori(flb, frb, new THREE.Vector3(0,10,10), new THREE.Vector3(0,0,80), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.frontDistHori.group);

        // if the peak porch height is not equal to main eave
        this.peakHeightDistHori = new _3dDistHori(this.getWallBackLeftBottom(), this.getWallBackLeftTop(),new THREE.Vector3(0,.1,5), new THREE.Vector3(0,.1,40), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.peakHeightDistHori.group);
        this.lowHeightDistHori = new _3dDistHori(this.getWallFrontLeftBottom(), this.getWallFrontLeftTop(),new THREE.Vector3(0,.1,5), new THREE.Vector3(0,.1,20), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.lowHeightDistHori.group);

        this.backBayDistHoris = [];
        let columnWidth = 8;
        let structureHalfLengthInches = this.getStructureLengthInches()/2
        for(var fli = 1;fli<this.design.frameLines.length;fli++){
            
            let vLeft = this.getWallFrontLeftBottom();
            let vRight = this.getWallFrontRightBottom();
            vLeft.x = this.design.frameLines[fli-1] -structureHalfLengthInches;
            vRight.x = this.design.frameLines[fli] -structureHalfLengthInches;

            if(fli ===1) 
                vLeft.x-=columnWidth/2;
            if(fli === this.design.frameLines.length-1)
                vRight.x+=columnWidth/2;
            
                let backBayDistHori = new _3dDistHori(vLeft, vRight, new THREE.Vector3(0,10,5), new THREE.Vector3(0,0,50), 1/12, CORE.layers.dimensions)
            this.backBayDistHoris.push(backBayDistHori);
            this.groupMetal.add(backBayDistHori.group);
        }
    }

    buildFoundation(){
        let width = this.frameDesign.width*12;
        let length = this.frameDesign.length*12;
        
        if(this.design.left.wrap)
            length+=width;

        let pos = new Vector3(0,0,0);// new Vector3().copy(dim).multiply(new Vector3(.5,0,.5)); // .5 inch wider foundation on each end      

        if(this.design.left.wrap)
            pos.x -= width/2;

        
        this.foundation =  new Foundation(this.foundationDesign, new Vector3(length, 0, width), this.design);
        this.group.add(this.foundation.group);
        this.foundation.group.position.copy(pos);
        this.groupMetal.position.y = this.foundationDesign.height;
    }

    build(){
        
        this.design.left.wrap = this.design.left.wrapValid && this.design.left.wrapEnabled;
        this.design.getRoof().left.wrap = this.design.left.wrap;
        this.design.right.wrap= this.design.right.wrapValid && this.design.right.wrapEnabled;
        this.design.getRoof().right.wrap = this.design.right.wrap;
        
        super.build()
    }

    buildSideLabels(){
        // override default behavior to do nothing
    }

    buildFrame(porchFrameLineIndices, trimMaterials){
        super.buildFrame(porchFrameLineIndices);

        if(this.design.left.wrap){
            // add a rafter

            let fullWidth = this.getStructureWidthInches();
            let workingWidth = fullWidth-4
            
            if(fullWidth<=15*12) //15 feet
                workingWidth+=3;

            this.pitchRatio = this.roofDesign.pitch;
            let hipTriangleAdj = Math.SQRT2 * workingWidth; // because it's known to be a 45deg angle
            let hipTriangleOpp = this.GetPitchHeight()
            let hipPitch =Math.atan(hipTriangleOpp/hipTriangleAdj)
            let hipTriangleHyp = hipTriangleAdj / Math.cos(hipPitch);
            let pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
            let columnHeight = this.GetFrontEaveHeight() - this.frameLineStandard.columnTopDistYBelowEave
            let qRot = new Quaternion()            
            .multiplyQuaternions(
                // back rafter is the standard, and quaternions are in world coordinates, so 
                // order matters!
                new Quaternion().setFromAxisAngle(new Vector3(0,1,0),3*Math.PI/4), // order matters! Y then X
                new Quaternion().setFromAxisAngle(new Vector3(1,0,0),-hipPitch)
            );

            
            this.hipRafter = new _3dRafterStraight(
                CORE.preferences.des_levelOfDetail.value, 
                this.groupMetal, 
                new Vector3(
                    -this.getStructureLengthInches()/2-workingWidth,

                    this.getStructureHeightInches()
                    -BlueprintHelper.pitchedPurlinDimY(this.GetPitchRatio())
                    - this.frameLineStandard.columnTopDistYBelowEave,

                    workingWidth/2),

                    qRot,
                hipTriangleHyp, 
                hipPitch,
                false, 
                this.frameLineStandard,                 
                this.roofDesign.type === CORE.frame.types.bolt, // bolted
                this.roofFrameType === CORE.roof.frameTypes.bypass // includeBoltPlateTop
                , this.beamColor
                );
                layerHelper.enableLayer(this.hipRafter.group, CORE.layers.quote);
            

            let columnSquareFrameSideLength = 4
            // we have to add two square columns here because of the ability to toggle layers on/off
            if(fullWidth<=15*12) //15 feet
            {
                //https://trello.com/c/rBSRpviv/325

                let frameMaterial;
                
                let columnSquareTrimSideLength = columnSquareFrameSideLength + .2;

                if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                    frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(columnHeight, columnSquareFrameSideLength, this.getBeamColor());
                else
                    frameMaterial=materialHelper.getFrameMaterial_LD(this.getBeamColor());

                // subtract the half-width of the column to pull it under the porch
                let pos = new Vector3(-this.getStructureLengthInches()/2-workingWidth,0,workingWidth/2 - columnSquareFrameSideLength/2)
                this.frontColumn = new _3dColumnSquare(columnHeight, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame);
                this.frontColumn.group.position.copy(pos.clone());

                if(this.optionDesign.wrapTubePorchCols)
                {
                    let trimMaterial = trimMaterials.corner;
                    this.frontColumnWrap = new _3dColumnSquare(columnHeight, trimMaterial, columnSquareTrimSideLength, CORE.layers.walls);
                    
                    this.frontColumnWrap.group.position.copy(pos.clone());
                    this.groupMetal.add(this.frontColumnWrap.group);     
                    layerHelper.enableLayer(this.frontColumnWrap.group, CORE.layers.quote);
                }
                
            }
            else{
                this.frontColumn = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value, columnHeight, true, false, this.frameLineStandard.materialDim, this.frameLineStandard.rafterDimYAtColumn, hipPitch, this.frameDesign.frameType === CORE.frame.types.bolt, this.getBeamColor());
                let columnPosition = new Vector3(
                    -this.getStructureLengthInches()/2-workingWidth,
                    0,
                    workingWidth/2)
                this.frontColumn.group.position.copy(columnPosition.clone())
                this.frontColumn.group.rotation.y =-Math.PI/4;
            }

            this.groupMetal.add(this.frontColumn.group);            
            layerHelper.enableLayer(this.frontColumn.group, CORE.layers.quote);
        }
    }

    grabjectChangeLeft(newX){
        
        let increment = 3;
        let snapPoints = BlueprintHelper.subdivideLength(newX - increment*100, newX + increment*100, increment);
        let closest = PickHelper.getClosestValueToTarget(snapPoints, newX);
        let snapX = closest.closestValue;

        let frame = this.design.getFrame();
        let rightOld = frame.length*12;

        // if the left end has changed
        if(Math.abs(snapX) > increment/2){

            // adjust the length of the porch
            let newLength =rightOld+snapX; 
            frame.length = newLength/12;
            // the constraint for this porch must be handled so the porch doesn't jump over, now shorter.

            this.addRebuildNeeded(rebuildTypes.full);
            return UpdateHelper.createImpact(this.design, impactTypes.porchLength);
        }
    }

    grabjectChangeRight(newX){
        if(this.parent.updatePorchRight(this.design, newX)){
        this.addRebuildNeeded(rebuildTypes.full);
            return UpdateHelper.createImpact(this.design, impactTypes.porchLength);
        }
    }

    grabjectChangeTop(newY){
        let oldHeightOffset = this.design.dim.heightOffset;
        // we need to track the offset from the main structure eave height
        // we need to know that eave height
        // we need this newY 
        //let mainEaveHeight = this.maxRidgeHeight;//this.design.dim.parentHeight;
        let newOffset = this.maxRidgeHeight - newY;
        if(newOffset<6)
            newOffset=0;
        if(newOffset>6 && newOffset<12)
            newOffset=12;

        this.design.dim.heightOffset = newOffset;

        if(this.design.dim.heightOffset<0)
            this.design.dim.heightOffset=0;
        if(this.design.dim.heightOffset === oldHeightOffset)
            return;

        if(this.updateHeightFromHeightOffset(this.design, this.frameDesign, this.maxRidgeHeight ))
            this.addRebuildNeeded(rebuildTypes.full);
        return UpdateHelper.createImpact(this.design, impactTypes.porchHeight);
    }

    applyChangeWidth(newWidthInches){

        // bound the input
        if(newWidthInches < this.getWidthMinimum()*12)
            newWidthInches = this.getWidthMinimum()*12;
        let newWidthFeet = newWidthInches/12;
        if(newWidthFeet === this.frameDesign.width)
            return; // short circuit

        
        // calculate new values from some existing value
        // use the old ridge height and the new width to calculate the new lowEaveHeight
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.design.getRoof().pitch);
        let oldPitchHeight =  BlueprintHelper.pitchHeight(this.frameDesign.width*12, pitchRatio, this.design.getRoof().roofType);
        let ridgeHeight = this.frameDesign.height*12 + oldPitchHeight;
        
        let newPitchHeight =  BlueprintHelper.pitchHeight(newWidthInches, pitchRatio, this.design.getRoof().roofType);
        let newLowEaveHeight = ridgeHeight - newPitchHeight;


        // apply new values
        this.frameDesign.width = newWidthFeet;
        this.frameDesign.height = newLowEaveHeight/12;

        return UpdateHelper.createImpact(this.design, impactTypes.porchWidth);
    }
    
    grabjectChangePitch(newY){
        // record old
        let oldRoofPitch = this.roofDesign.pitch;
        
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = BlueprintHelper.pitchHeight(this.structureWidthInches(), pitchRatio, this.roofDesign.roofType )
        // calculate roofPitch height options
        let roofPitchHeights = [];

        for(var i=1;i<=6; i++)
            roofPitchHeights.push(12*this.frameDesign.height +pitchHeight- (12*this.frameDesign.width*i/12));
        
        let {closestIndex} = PickHelper.getClosestValueToTarget(roofPitchHeights, newY);
        //console.log(roofPitchHeights, closestIndex, newY)
        let newRoofPitch = closestIndex+1;
        if(newRoofPitch === oldRoofPitch)
            return;
        // calculate nearest roof pitch
        StructureSecondary.updateRoofPitch(this.design, newRoofPitch);
        return UpdateHelper.createImpact(this.design, impactTypes.porchPitch);
    }

    
    updateComponents(){
        this.components= []; // forget previous components
        // register these components so that sub-components and components they contain can be found
        this.addComponent(this.frame);
        this.addComponent(this.roof);              
        this.addComponent(this.wallBack);
        this.addComponent(this.wallRight);
        this.addComponent(this.wallFront);
        this.addComponent(this.wallLeft);

        if(this.leftEndSkirt)
            this.addComponent(this.leftEndSkirt)

        if(this.rightEndSkirt)
            this.addComponent(this.rightEndSkirt)
    }

}
