<template>
  <div class="item-management">
    <h1>Item Management</h1>
    <div class="actions">
      <button @click="downloadItemList">Download Company Item List</button>
      <!-- <button @click="downloadTemplate">Download Template</button> -->
      <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv,.xlsx" />
      <button @click="uploadItemList" :disabled="uploadInProgress">Upload Company Item List</button>
      <ProgressSpinner v-if="uploadInProgress"></ProgressSpinner>
    </div>

    <div class="filters">
      <input v-model="searchQuery" placeholder="Search items...">
      <select v-model="sortBy">
        <option value="systemCode">Ninja Code</option>
        <option value="entityCode">Company Code</option>
        <option value="description">Description</option>
        <option value="sunit">Unit</option>
        <option value="price">Price</option>
        <option value="weight">Weight</option>
      </select>
      <button @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'">
        {{ sortOrder === 'asc' ? '↑' : '↓' }}
      </button>
    </div>

    <table>
      <thead>
        <tr>
          <th @click="updateSort('systemCode')">Ninja Code</th>
          <th @click="updateSort('companyCode')">Company Code</th>
          <th @click="updateSort('description')">Description</th>
          <th @click="updateSort('sUnit')">Unit</th>
          <th @click="updateSort('pricePerUnit')">Price</th>
          <th @click="updateSort('weightPerUnit')">Weight</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredAndSortedItems" :key="item.id">
          <td>{{ item.systemCode }}</td>
          <td>{{ item.entityCode }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.sUnit }}</td>
          <td>{{ item.pricePerUnit }}</td>
          <td>{{ item.weightPerUnit }}</td>
          <td>
            <a @click="editItem(item.id)">Edit</a>
          </td>
        </tr>
      </tbody>
    </table>

    <message-modal
      :show="showMessage"
      :message="message"
      :issues="issues"
      @close="closeMessage"
    />
  </div>
</template>

<script>
import MessageModal from '@/components/MessageModal.vue'
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';
import Util from '@/utility';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
export default {
  components: {
    MessageModal,
    ProgressSpinner
  },
  data() {
    return {
      items: [
        { id: 1, systemCode: 'MS001', entityCode: 'CC001', description: 'Item 1', pricePerUnit: 100, weightPerUnit: 50 },
        { id: 2, systemCode: 'MS002', entityCode: 'CC002', description: 'Item 2', pricePerUnit: 200, weightPerUnit: 75 },
        { id: 3, systemCode: 'MS003', entityCode: 'CC003', description: 'Item 3', pricePerUnit: 150, weightPerUnit: 60 },
      ],
      searchQuery: '',
      sortBy: 'systemCode',
      sortOrder: 'asc',
      showMessage: false,
      message: '',
      issues: [],
      uploadInProgress: false
    }
  },
  computed: {
    ...mapState('contextModule', ['currentContext', 'mode']),
    filteredAndSortedItems() {
      console.log('filter', this.items)
      console.log('filter', typeof(this.items))
      let filtered = this.items.filter(item => {
        return Object.values(item).some(value => 
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    }
  },
  methods: {
    ...mapActions('itemModule', ['downloadItemListTemplate', 'uploadItemListTemplate']),
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    async downloadItemList() {
      let response = await this.downloadItemListTemplate(this.$route.params.id);
      if(response.isError){
        this.showErrorMessage(response.data.msg);
      }
      else{
        let templateData = response;
        let filename = "SteelNinja__ItemListTemplate.csv"; // this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(templateData.fileContents, templateData.contentType);
        Util.downloadBlob(blob, filename);
      }
    },
    handleFileUpload(event) {
      const file =  event.target.files[0];
      if(file && file.type === 'text/csv')
        this.selectedFile = file;
      else
        this.showErrorMessage('Please select a valid CSV file');
    },
    async uploadItemList() {
      if (this.selectedFile) {
        await this.parseFile()
        console.log(this.$refs.fileInput)
        this.$refs.fileInput.value = '';
      } else {
        this.showErrorMessage('Please select a file to upload')
      }
    },
    async parseFile() {
      this.uploadInProgress = true;
      let formData = new FormData(); //FormData() expects an html form element or not arguments
      formData.append('file', this.selectedFile); 

      let entityId = this.$route.params.id;
      let payload = {entityId: entityId, file: formData}
      let response = await this.uploadItemListTemplate(payload);
      if(response.isError){
        this.uploadInProgress = false;
        this.showErrorMessage(response.data.msg, response.data.issues);
      }
      else{
        this.uploadInProgress = false;
        this.showSuccessMessage("File uploaded and items updated successfully.")
      }
    },
    editItem(itemId) {
      // Implementation for editing an item
      console.log(`Editing item ${itemId}`)
    },
    showSuccessMessage(message) {
      this.message = message
      this.issues = []
      this.showMessage = true
    },
    showErrorMessage(message, issues = []) {
      this.message = message
      this.issues = issues
      this.showMessage = true
    },
    closeMessage() {
      this.showMessage = false
    },
  },
  async created(){
    await ContextLogic.initContext(this);
      let id = this.$route.params.id;
      if(id)
      this.items = await api.getItemListForEntity(id)
    else
      this.items = await api.getItemListForSystem()
    
    console.log(this.items)
  }
}
</script>

<style scoped>
.item-management {
  margin: 20px;
}

.actions {
  margin-bottom: 20px;
}

.filters {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  cursor: pointer;
}

tr:hover {
  background-color: #f5f5f5;
}

button, input[type="file"] {
  margin-right: 10px;
}

a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>