<template>
  <div class="entity-manager">
    <h1>{{entity}}</h1>
    <div class="filters">
      <input v-model="searchQuery" placeholder="Search entities...">
      <select v-model="sortBy">
        <option value="name">Name</option>
      </select>
      <button @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'">
        {{ sortOrder === 'asc' ? '↑' : '↓' }}
      </button>
    </div>
    <button v-if="canOnboardEntity" @click="goToOnboarding" class="onboard-btn">Onboard New Entity</button>
    <table>
      <thead>
        <tr>
          <th @click="updateSort('name')">Entity Name</th>
          <!-- <th>Actions</th> -->
        </tr>
      </thead>
      <tbody>
      <tr v-for="entity in filteredAndSortedEntities" :key="entity.id" @click="goToEntityDashboard(entity.id)" class="entity-row">
        <td>{{ entity.businessName }}</td>
        <!-- <td class="actions">
          <button @click.stop="editEntity(entity)">Edit</button>
          <button @click.stop="deleteEntity(entity)">Delete</button>
        </td> -->
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {CORE} from '../constants.js';
import ContextLogic from '@/lib/contextLogic';
export default {
  data() {
    return {
      searchQuery: '',
      sortBy: 'name',
      sortOrder: 'asc',
      entities: [],
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    filteredAndSortedEntities() {
      let filtered = this.entities.filter(entity => {
        return Object.values(entity).some(value => 
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    },
    entity(){
      if(this.current.mode == 1)
        return "Contractors"
      return "Entities"
    },
    canOnboardEntity() {
      return true
    }
  },
  methods: {
    ...mapActions('entityModule', ['fetchAllEntities', 'fetchSubEntities']),
    ...mapActions('contextModule', ['']),
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    goToOnboarding() {
      this.$router.push({ name: 'onboarding' })
    },
    goToEntityDashboard(entityId) {
      let entity = this.entities.find(e => e.id === entityId);
      // this is experimental for helping properly track current mode when not all route paths contain /company or /contractor      
      //this.$store.commit('contextModule/setMode', entity.type);
      let dashboardAlias;
      if(entity.type === CORE.entities.company){
        dashboardAlias = `/company/${entityId}`;
      }
      else if(entity.type === CORE.entities.contractor){
        dashboardAlias = `/contractor/${entityId}`;
      }
      if(dashboardAlias)
        this.$router.push(dashboardAlias)
    },    
    async deleteEntity(entity) {
      if (confirm(`Are you sure you want to delete ${entity.name}?`)) {
        try {
          await this.removeEntity(entity.id)
          // Optionally, show a success message
        } catch (error) {
          console.error('Error deleting entity:', error)
          // Optionally, show an error message
        }
      }
    }
  },
  async created() {
    await ContextLogic.initContext(this);

    // we will fetch the appropriate entities for the current user
    let pathParts = this.$route.path.split("/")
    this.entities = (this.current.mode === CORE.modes.system) ? await this.fetchAllEntities() : await this.fetchSubEntities(pathParts[2]);
  },
}
</script>

<style lang="scss" scoped>
// Variables
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333;
$hover-color: #f1f8ff;
$green-color: #2ecc71;
$danger-color: #e74c3c;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

// Mixins
@mixin transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease;
}

.entity-manager {
  margin: 20px;
  font-family: Arial, sans-serif;
  color: $text-color;
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 20px;

  h1 {
    color: $secondary-color;
    margin-bottom: 20px;
  }

  .filters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    input, select {
      padding: 8px 12px;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 14px;
      @include transition;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    button {
      padding: 8px 12px;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      @include transition;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .onboard-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: $green-color;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    @include transition;

    &:hover {
      background-color: darken($green-color, 10%);
    }
  }

  table {
    margin:auto;
    width: fit-content;
    border-collapse: separate;
    border-spacing: 0;

    th, td {
      border: 1px solid $border-color;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: $secondary-color;
      color: white;
      font-weight: bold;
      cursor: pointer;
      @include transition(background-color);

      &:hover {
        background-color: lighten($secondary-color, 10%);
      }
    }

    .entity-row {
      cursor: pointer;
      @include transition(background-color);

      &:hover {
        background-color: $hover-color;
      }

      td {
        @include transition(background-color);

        &:hover {
          background-color: darken($hover-color, 5%);
        }
      }

      .actions {
        button {
          padding: 6px 12px;
          margin-right: 5px;
          background-color: $primary-color;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          @include transition;

          &:hover {
            background-color: darken($primary-color, 10%);
          }

          &:last-child {
            background-color: $danger-color;

            &:hover {
              background-color: darken($danger-color, 10%);
            }
          }
        }
      }
    }
  }
}
</style>