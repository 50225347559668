import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
  async fetchProjects({ commit }, sub) {
    try {
      return await api.getProjects(sub)
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async fetchProject({ commit }, projectIdentifier) {
    try {
      return await api.getProject(projectIdentifier);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async createBuildingQuote({commit}, quote) {
    try {
    
      return await api.post('/project/saveProject', quote);
    } catch (error) {
      console.error('Error creating new building quote:', error);
      return error;
    }
  },
  async updateBuildingQuote({commit}, project) {
    try {
      return await api.post('/project/saveProject',project);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async requestCsvBomExport({commit}, payload){
    try{
      return await api.post('/project/requestCsvBomExport', payload)
    } catch(error){
      console.error('Error requesting Csv Bom');
    }
  },
  async fetchMBS({commit}, payload){
    try{
      return await api.post('/project/requestMBS', payload)
    } catch(error){
      console.error('Error requesting MBS');
    }
  },
  async generateBuildingQuotePDFV3({commit}, payload){
    try{
      return await api.post(`/project/generateBuildingQuotePDFV3`, payload)
    } catch(error){
      console.error('Error requesting Bidsheet');
    }
  },
  async fetchProjectDetails({commit}, ref){
    try {
      return await api.get(`/project/getProjectDetails/${ref}`);
    } catch (error) {
      console.error('Error requesting project details:', error);
      return error;
    }
  },
  async fetchStructureDetails({commit}, ref){
    try{
      return await api.get(`/project/getStructureDetails/${ref}`)
    } catch(error){
      console.error('Error requesting structure details');
    }
  },
  async updateQuoteAdjustments({commit}, payload){
    try {
      return await api.post(`/project/updateQuoteAdjustments`, payload);
    } catch (error) {
      console.error('Error updating project quote adjustments:', error);
      return error;
    }
  },
  async deleteProject({commit}, project){
    try {
      return await api.post(`/project/deleteProject`, project);
    } catch (error) {
      console.error('Error deleting project:', error);
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};