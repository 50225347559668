import Vue from 'vue'
import Vuex from 'vuex'
import projectModule from './modules/projectModule'
import entityModule from './modules/entityModule'
import contextModule from './modules/contextModule'
import userModule from './modules/userModule'
import {designModule,v,c,m} from './modules/designModule'
import itemModule from './modules/itemModule'
import pricingModule from './modules/pricingModule'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    project:{}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    projectModule,
    entityModule,
    contextModule,
    userModule,
    designModule,
    itemModule,
    pricingModule
  },
  plugins:[createPlugin(LogRocket)],
})

export {
v,c,m
}
