import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import { Vector2, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import _3dWindowBase from '../3d/WindowBase.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import layerHelper from '../helpers/layerHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import _3dTrimOpeningFace from '../3d/TrimOpeningFace.js';
import _3dTrimOpeningJamb from '../3d/TrimOpeningJamb.js';
import mathHelper from '../helpers/mathHelper.js';

export default class WideOpening extends Door {
    constructor(design) {        
        super(design);
        if(this.design)
        {
            if(this.design.dir) 
                delete this.design.dir; 
        }
        
                
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }
    detectImpact(impact){
        
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }
    
    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }

    processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.openingResize:
            //case impactTypes.doorRollupDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.openingPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }
    
    initParent(parent, context){
        this.parent = parent; // window needs to know the parent because a parent wall may be built normal or inverted (two modes for the sake of debugging math more easily)
        this.rot = parent.openingRot(); // the inverted walls are not rotated into place, but built in place, therefore, openings on the walls must be rotated
    }

    migrate(design){
        if(!design)
            return;

        if(typeof design.excludeOh === 'undefined')
            design.excludeOh=false;        
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),        
        this.design.type = design.type,
        //this.design.id = design.id,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };        
        this.design.pos.y = (this.design.dim.height)/2; 
        this.design.selected= false;
    }
    
    defaultDesign(limits){

        let des = {
            collided : false,
            pos : new Vector3(0,(9*12)/2,0), 
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.wideOpening,
            id: -1,
            dim:{
                width: 9*12,
                height: 9*12
            },
            selected:false
        }
        if(limits){
            if(des.dim.height > limits.dim.height.max)
            des.dim.height = limits.dim.height.max;
        }
        des.pos.y = (des.dim.height)/2; //foundation is 6" tall
        return des;
    }
    
    getOptionsSpec(){
        return [
            // TODO: handle conversion of these back and forth between feet and inches
            OptionHelper.numericUpDown("dim.width","Width", impactTypes.openingResize, "ft.", 6, undefined, 1,
            true,
            //OptionHelper.numericUpDown("dim.width","Width", impactTypes.openingResize, "ft.", 6, undefined, 1,
            ()=>{
                return this.design.dim.width/12;
            }, //fnIn
            undefined,
            (v)=> {//fnChange
                this.design.dim.width = v*12;
            }),
            OptionHelper.numericUpDown("dim.height","Height", impactTypes.openingResize, "ft.", 6, undefined, 1,
            true,
            //OptionHelper.numericUpDown("dim.height","Height", impactTypes.openingResize, "ft.", 6, undefined, 1,
            ()=>{
                return this.design.dim.height/12;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                // keep the bottom of the door where it is
                this.design.dim.height = v*12;                
                this.design.pos.y =  this.design.dim.height/2;
            })            
        ]
    }
    
    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        
        return [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideFront,
            CORE.components.baySideBack
        ]
    }
    
    static updatePosX(design, x){
        if(design.pos.x == x)
            return false;
        design.pos.x=x;
        return true;
    }

    static updatePosY(design) {
        // calculate the pos.y so that the door always touches the ground
        let y = design.dim.height/2;
        if(design.pos.y === y)
            return false;
        design.pos.y = y;
        return true;
    }

    
    left(){
        let left=-this.design.dim.width/2;
        if(this.parent.isInverted())
            left*=-1;
        return this.design.pos.x + left;
    }

    right(){
        let right=this.design.dim.width/2;
        if(this.parent.isInverted())
            right*=-1;
        return this.design.pos.x + right;
    }

    updatePosition(center)
    {
        if(!WideOpening.updatePosition(center, this.design))
            return

        // move requires no rebuild (except on re-parentage)
         return UpdateHelper.createImpact(this.design, impactTypes.openingPosition);
    }

    static updatePosition(center, design){
        let affected = false
        // moves the built object, so it doesn't have to be rebuilt.
        affected = WideOpening.updatePosX(design, center.x) || affected;
        affected = WideOpening.updatePosY(design) || affected;
        return affected;
    }

    updateSize(input){

        const {role, relPosition} = input;
        if(Util.isUndefined(relPosition)){
            throw 'OHD size grabject position is undefined'
        }

        let newCoordX = relPosition.x; 
        let newCoordY = relPosition.y;

        let affected = false;
        switch(role){
            case CORE.grabjects.topLeft:
                affected = this.grabjectChangeLeft(newCoordX) || affected;
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.top:
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.topRight:
                affected = this.grabjectChangeRight(newCoordX) || affected;
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.right:
                affected = this.grabjectChangeRight(newCoordX) || affected;
                break;            
            case CORE.grabjects.left:
                affected = this.grabjectChangeLeft(newCoordX) || affected;
                break;
        }
         // return details regarding the impact this input change had
         if(affected){
            return UpdateHelper.createImpact(this.design, impactTypes.openingResize);
        }
    }


    grabjectChangeLeft(newVal){
        // math is written based on positive-Z facing window, where:
        // left grabject moved to be left implies change < 0)
        // left grabject moved to the right implies change > 0
        // 
        // when flipped
        // left grabject moved to the left implies change > 0
        // left grabject moved to the right implies change < 0
        let change = newVal - this.left(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped

        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!WideOpening.updateWidth(this.design, this.design.dim.width-widthChange))
            return;
        
        // apply half of the change (+ or -) to the position
        WideOpening.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    setPickMeshGeometry(){
        //TODO: cleanup redundant variables frameDepth and halfFrameDepth that were copied from build() for the sake of rebuilds
        
        
    }


    grabjectChangeRight(newVal){
        let change = newVal - this.right(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped
        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!WideOpening.updateWidth(this.design, this.design.dim.width+widthChange))
            return;        
        // apply half of the change (+ or -) to the position
        WideOpening.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }      

    grabjectChangeTop(newTop){
        if(!WideOpening.updateHeight(this.design, newTop))
            return false;

        WideOpening.updatePosY(this.design)
        return true;
    }

    static updateHeight(design, height){
        if(height<36) // 36" = 3ft and is about as small as an OHD can get before grabjects run together
            return false; // signal that we can't go any smaller
        design.dim.height=height;
        return true;
    }

    static updateWidth(design, width){
        if(width<42) // 42" = 3.5ft and is about as small as an OHD can get before grabjects run together
            return false; // signal that we can't go any smaller
        design.dim.width=width;
        return true;
    }

    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }
        
    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }

    getTopRightCorner(){
         let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                        .8*this.design.dim.height/2,
                                        0);
         return offset;
    }

    // required by object interface
    getDescription(){
        if(!this.design)
            return `Wide Opening`
        let widthFt = (this.design.dim.width/12).toFixed(2);
        let heightFt = (this.design.dim.height/12).toFixed(2);
        //return `Wide Opening (${widthFt}' x ${heightFt}')`;
        return `Wide Opening (${mathHelper.inToFtIn(this.design.dim.width)} x ${mathHelper.inToFtIn(this.design.dim.height)})`;
    }
    
    

    // required by object interface
    styleCollision(colliding) {
        this.colliding = colliding;
        if(colliding)
            this.matDoor.color = new THREE.Color(0xff0000);
    }
    
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.wideOpening;
    }
    
    // required by object interface
    getCollisionZones() {
        return [this.zone];
    }

    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = WideOpening.parentComponentTypes();
        return grabData;
    }

    getPickDetectionMeshGeometry(){
        let frameDepth = 8; // 8 inches
        let halfFrameDepth = frameDepth/2;
        let geo = new THREE.BoxGeometry(this.design.dim.width, this.design.dim.height, halfFrameDepth);
        geo.name = 'wide opening'
        return geo;
    }
    createOrUpdatePickDetectionMesh(){
        // removing the pickMesh from the scene doesn't unset the variable this.pickDetectionMesh
        // since this.pickDetectionMesh is still set even after remove has been called, a new pickDetectionMesh isn't created on subsequent renders.
        // the geometry of the existing mesh is just updated
        // all of this allows the outline mesh to show while dragging an object along a wall
        if(typeof this.pickDetectionMesh === 'undefined') {
            var geoPick = this.getPickDetectionMeshGeometry();
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);            
            this.pickDetectionMesh.name=`pickMesh for wide opening ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
            
            this.pickDetectionMesh.userData = {
                type: this.design.type,
                id: this.design.id
            };
        }
        else
        {
            this.pickDetectionMesh.geometry.dispose();
            this.pickDetectionMesh.geometry = this.getPickDetectionMeshGeometry();
        }
    }

    build() {
        // use the design to build the component
        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;
        let halfFrameDepth = jambTrimThickness/2;
        let halfJambThickness = jambTrimThickness / 2;

        

        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
        this.sizeDoor = new THREE.Vector3(dim.x, dim.y, 1);
        
        this.group.position.copy(this.design.pos)

        // trim        
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(CORE.preferences.des_levelOfDetail.value, dim, dimTrimMaterial, this.structureConfig.trimMaterials.door, false);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        this.group.add(trim.group);
        this.group.position.z = .25;

        //if(this.settings && this.settings.ohJambLiner!==false)
        if(this.options && this.options['ohJambLiner']!==false)
        {
            let jamb = new _3dTrimOpeningJamb(CORE.preferences.des_levelOfDetail.value, dim, jambTrimThickness, jambTrimWidth, this.structureConfig.trimMaterials.door);
            this.group.add(jamb.group);        
        }        

        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 

        // we need a separate pickMesh on overhead doors because they're largest feature (the door mesh)
        // is inset into the wall, which "hides" the door from rayCasting because the wall pickMesh
        // must be without holes for the sake of component editing.
        
        this.createOrUpdatePickDetectionMesh();



        this.outlineMesh = this.pickDetectionMesh;
        
        
        
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        this.group.visible = this.visible;

        

        this.buildGrabjects(halfDim)
        this.showGrabjects(this.design.selected);
        
        this.group.rotation.y = this.rot;

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'wide opening frame'
        this.group.add(gFrame)
        let frameInset = 1;
        let header = new _3dGirtHoriC(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(0,0,0),new THREE.Vector3(this.design.dim.width,0,0), CORE.dir.rel.up, false);
        header.build();
        gFrame.add(header.group);
        header.group.position.set(0,this.design.dim.height/2,-header.dim.web/2-frameInset);

        //this.applyPosition();
        //this.setCollisionZone();
        
        this.built=true;
    }

    buildGrabjects(halfDim){
            
        let grabjectSize = 8;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = WideOpening.parentComponentTypes();
        this.positionGrabject = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,0,0), [CORE.indicators.leftRight],
         new THREE.Vector3(15, 15, 5), CORE.grabjects.position, parentComponentTypes);
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,halfDim.y,0), 
        //[CORE.indicators.topLeft, CORE.indicators.bottomRight],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.topLeft, parentComponentTypes);
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,halfDim.y,0), 
        //[CORE.indicators.upDown],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.top, parentComponentTypes);
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,halfDim.y,0), 
        //[CORE.indicators.topRight, CORE.indicators.bottomLeft],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.topRight, parentComponentTypes);
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,0,0), 
        //[CORE.indicators.leftRight],
        [CORE.indicators.sphere],
        grabjectSizeVector, CORE.grabjects.right, parentComponentTypes);
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,0,0), 
        //[CORE.indicators.leftRight],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.left, parentComponentTypes);
        this.group.add(this.grabjectGroup);
    }

    setCollisionZone(){
        if(!this.pickDetectionMesh)
        return;
        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        this.pickDetectionMesh.updateMatrix();
        this.pickDetectionMesh.updateWorldMatrix(true);
        this.pickDetectionMesh.renderOrder=1;
        let margin = CollisionHelper.getMargin(
            CORE.preferences.des_marginOhdSide.value,
            CORE.preferences.des_marginOhdSide.value,
            CORE.preferences.des_marginOhdTop.value,
            0,0,0);
        this.zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [
                CORE.collisions.classes.window,
                CORE.collisions.classes.emptyFramedOpening,
                CORE.collisions.classes.louverVent,
                CORE.collisions.classes.doorWalkin3o7o,
                CORE.collisions.classes.doorWalkin4o7o,
                CORE.collisions.classes.doorWalkin6o7o,
                CORE.collisions.classes.doorRollup,
                CORE.collisions.classes.doorSliding,
                CORE.collisions.classes.doorCommGlass,
                CORE.collisions.classes.doorSectional,
                CORE.collisions.classes.doorHangar,
                CORE.collisions.classes.wideOpening,
            ],
            this.sizeDoor,
            margin,
            this.getDescription()
        )
        this.zone.data = {
            id:this.design.id,
            type:this.design.type
        };

        this.pickDetectionMesh.userData.collisionZones= [this.zone];
        
    }
    
    remove() {
        // removing the pickMesh from the scene doesn't unset the variable this.pickDetectionMesh
        // since the variable is still set, a new pickDetectionMesh isn't created on subsequent renders.
        // 
        MemHelper.removeAllChildren(this.group);
        MemHelper.dispose(this.group);
        if(this.texture)
            this.texture.dispose();
    }

    getTypeDisplayName(){
        return "Wide Opening"
    }

}
