import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
  async fetchMyUser({ commit }, identifier){
    try {
      return await api.getMe(identifier);
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },
  async fetchUser({ commit }, identifier){
    try {
      return await api.getUser(identifier);
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },
  async fetchUsersFromCompany({commit}, entityId){
    try {
      return await api.getUsersFromCompany(entityId);
    } catch (error) {
      console.error('Error fetching users from company:', error);
      throw error;
    }
  },
  async fetchUsersFromContractor({commit}, entityId){
    try {
      return await api.getUsersFromContractor(entityId);
    } catch (error) {
      console.error('Error fetching users from contractor:', error);
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};