<template>
  <div class="projectView">
    
    <div class="project-details">
      <v-dialog transition="pop-out" />
      <h1>{{ isNewProject ? 'New Project' : quote.name }}</h1>
      <div class="details">
        
        <div class="details-group">
          
          
          <div v-if="input.error.show" style="color:red; margin: 10px">{{input.error.msg}}</div>
          <div class="input-group">
            <span for="projectName">Project Name</span>
            <input type="text" id="projectName" v-model="quote.name" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
            <div v-if="!nameValid" class="required">Required</div>
          </div>
          <div class="input-group">
            <span for="address">Address</span>
            <input type="text" id="address" v-model="quote.address" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
            <div v-if="!addressValid" class="required">Required</div>
          </div>
          <template v-if="isNewProject">
            <div class="input-group">
              <span for="code">Code</span>
              <select id="code" v-model="quote.buildingCode" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
                <option value="IBC 2012">IBC 2012</option>
                <option value="IBC 2015">IBC 2015</option>
                <option value="IBC 2018">IBC 2018</option>
                <option value="IBC 2021">IBC 2021</option>
              </select>
            </div>
            <div class="input-group">
              <span>Engineering Seals</span>
              <input type="checkbox" v-model="quote.seals" :disabled="inputIsDisabled">
            </div>
          </template>
          
          <div class="input-group">
            <span> Delivery</span>
            <input type="checkbox" v-model="quote.delivered" :disabled="inputIsDisabled">
          </div>
          <div class="input-group">
            <span for="taxes">Taxes</span>
            <select id="taxes" v-model="quote.tax" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
              <option value="No - Exempt">No - Exempt</option>
              <option value="No - Out Of State">No - Out Of State</option>
              <option value="Yes - City">Yes - City</option>
              <option value="Yes - Outside City">Yes - Outside City</option>
            </select>
          </div>
          <div class="input-group">
            <span for="projectName">Company Owner</span>
            <EntityUserPicker :entities="companies" :isCompanyPicker="true" ref="companyPicker" @user-selected="onCompanyUserSelected"></EntityUserPicker>
            <div v-if="!companyUserValid" class="required">Required</div>
          </div>
          <div class="input-group">
            <span for="projectName">Contractor Owner</span>
            <EntityUserPicker :entities="contractors" :isCompanyPicker="false" ref="contractorPicker" @user-selected="onContractorUserSelected"></EntityUserPicker>
          </div>
          <div class="input-group" id="notes-wrapper">
            <span>Notes</span>
            <textarea id="projectNotes" name="notes" rows="10" cols="40" v-model="quote.notes" :disabled="inputIsDisabled"></textarea>
          </div>
          <div class="form-actions">
            <div v-if="isNewProject">
              <button v-if="isNewProject" class="btn btn-primary" :disabled="input.disableSave || saveInProgress || !isAuthenticated" v-on:click="commitAndLaunchDesigner">Save and Open Designer</button>
            </div>
            <div v-else-if="isAuthenticated">
              <button v-if="editing" class="btn btn-primary" :disabled="input.disableSave || saveInProgress" v-on:click="commitDetails">Save</button>
              <button v-else class="btn btn-primary" v-on:click="setProjectEditMode">Edit Project Details</button>
            </div>
            <button type="button" class="btn btn-cancel" v-on:click="navigateBack">Cancel</button>
          </div>
          
          
        </div>
        <div class="project-image-wrapper details-group">

          <a @click.prevent="launchDesigner" :href="designUrl">
          <ProgressSpinner v-if="loadingProject"></ProgressSpinner>
          <img v-else-if="quote.state == 0" src="../assets/testBuilding.jpg" alt="Building" class="building-image">
          <img v-else :src="quote.image" alt="Building" class="building-image">
          
          </a>
          <div id="assets" v-if="isAuthenticated">
            <label><b>Assets:</b></label>
            
            <div v-if="loadingDetails"><ProgressSpinner /></div>
            <div v-else>
              <div id="bidsheets" v-if="quote.state > 0">
                <a :disabled="preventBidsheetDownload" class="link" v-on:click="bidsheetPdfView(quote.reference, false)">Full Bid Sheet</a> |
                <a :disabled="preventBidsheetDownload" class="link" v-on:click="bidsheetPdfView(quote.reference, true)">Summary Only</a>
              </div>
              <div v-else>
                No bid sheet is available yet. Edit this design and make something great!
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Structure</th>                
                    <th>Files</th>                  
                  </tr>
                </thead>
                <tbody v-if="structureDetails.length > 0">
                  <tr v-for="detail in structureDetails" :key="detail.id">
                    <td>{{ detail.name }}</td>
                    <td>
                      <div class="structureLinks" v-if="!detail.isPorch">
                        <a class="link" v-on:click="downloadCsvBomExport(quote.reference, detail.name, quote.version)">BOM</a> | 
                        <a class="link" v-on:click="requestMBS(quote.reference, detail.name, quote.version)">MBS</a>
                      </div>
                    
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td></td>
                    <td>No download files are available yet. Edit and save this design and make something great!</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="data">
          <label><b>Data:</b></label>
          <div id="sensistiveData" v-if="isAuthenticated">
              <div class="">
                <span>Price</span>
                <label  >{{ quote.price }}</label>  
              </div>
              <div class="" >
                <span>weight</span>
                <label >{{ quote.weight }}</label>  
              </div>
            </div>
            <div id="generalData" class="" v-if="true">
              <div class="">
                <span>Status</span>
                <label  >{{ quote.state }}</label>  
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, onErrorCaptured } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import Util from '../utility';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import EntityUserPicker from '@/components/EntityUserPicker.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue'
import { CORE } from '@/constants';
import { current } from 'tailwindcss/colors';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default {
  name: 'ProjectDetailsView',
  components:{
    EntityUserPicker,
    ProgressSpinner
  },
  data() {
    return {
      loadingProject: true,
      loadingDetails: true,
      isAuthenticated: false,
      quote: {},
      isNewProject: false,
      isShareMode: false,
      editing: false,
      creating: false,
      copying: false,
      saveInProgress: false,
      createBuildingFailed: false,
      entityType: -1,
      input: {
        disableSave: false,
        error:{
          show: false,
          msg: ""
        }
      },
      structureDetails: [],
      companies: [],
      contractors: [],
      companyPicker:{
        entity: {
          label: 'company',
          visible: true
        },
        user:{
          disabled: false,
          visible: true
        },
      },
      contractorPicker:{
        entity:{
          label: 'company',
          visible: true,
        },
        user:{
          disabled: false,
          visible: true,
        },
    
      }
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: 'description', content: "something" },
        { property: 'og:title', content: this.quote.Name },
        { property: 'og:description', content: "something" },
        { property: 'og:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share`},
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.quote.name },
        { name: 'twitter:description', content: "something"},
        { name: 'twitter:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share` }
      ]
    }
  },
  computed: {
    ...mapState('contextModule', ['current', 'mode', 'contractorParentData']),
    preventBidsheetDownload(){
      return this.editing || this.copying;
    },    
    designUrl(){      
      return `/project/${this.quote.reference}/design`;
    },
    nameValid(){
      return this.quote.name && this.quote.name.length !== 0;
    },
    addressValid(){
      return this.quote.address && this.quote.address.length !== 0;
    },
    companyUserValid(){
      return this.quote.lvl1UserId != -1;
    },
    inputIsDisabled(){
      return !this.editing || this.isShareMode || !this.isAuthenticated;
    }

  },
  methods: {
    ...mapActions('projectModule', ['fetchProject', 'createBuildingQuote', 'updateBuildingQuote', 
    'requestCsvBomExport', 'fetchMBS', 'generateBuildingQuotePDFV3',
    'fetchStructureDetails']),
     ...mapActions('entityModule', ['fetchAllEntities', 'fetchAllCompanies', 'fetchSubEntities', 'fetchContractorsByRep', 'fetchEntity']),
     ...mapActions('userModule', ['fetchUser', 'fetchUsersFromCompany', 'fetchUsersFromContractor']),
    navigateBack(){
      this.editing = false;
      this.$router.go(-1) ;
    },
    setProjectEditMode(){
      this.editing = true;
      this.$router.push(`${this.$route.path}/edit`) ;
    },
    inputfieldChanged(){
      // this.editing = true;
      this.input.error.show = false;

      let msg = this.buildErrorMsg();
      if (msg) {
          this.input.disableSave = true;
          this.input.error = {
              msg,
              show: true
          }
      } else {
          this.input.disableSave = false;
          this.input.error = {
              msg: null,
              show: false
          }
      }
    },
    buildErrorMsg() {
      let fieldList = [];
      if (this.quote.name.length === 0) fieldList.push("Name");

      if(this.quote.address.length === 0){
          fieldList.push('address');
      }


      if (fieldList.length > 0)
          return "Invalid Fields: " + fieldList.join(', ');

      if (this.quote.frameType === "Weld up" || this.quote.frameType === "Weld PLUS") {
          if (parseFloat(this.quote.width) > 60)
              return "Weld-up building width cannot exceed 60ft.";
      }

      if (/[\\/:*?"<>|]/.test(this.quote.name))
          return "A building name cannot contain any of the following characters: \\ / : * ? \" < > |";

      return null;
    },
    async commitAndLaunchDesigner(){
      console.log('new building quote', this.quote)
      await this.commitDetails();
      if (!this.createBuildingFailed)      
          this.launchDesigner();
    },
    async commitDetails(){
      if(!(this.nameValid && 
      this.addressValid && 
      this.companyUserValid))
      {
        this.input.disableSave = true;
        this.input.error = {
            msg: "Please fill in all required fields",
            show: true
        }
        return;
      }
      else
        this.input.disableSave = false;

      this.input.error = {
          msg:'',
          show: false
      }

      // this.geocodeLocation();
      this.saveInProgress = true;
      let response;
      if(this.creating)
        response = await this.createBuildingQuote(this.quote);
      else
        response = await this.updateBuildingQuote(this.quote);

      if(response.isError){ 
        this.commitDetailsError(response.data)
      }
      else{
        this.creating = false;
        this.editing = false;
        this.saveInProgress = false;
        this.createBuildingFailed = false;
        console.log(response);
        this.quote = response; // client learns id and reference
       }
    },
    
    launchDesigner(){
      if(this.creating || this.editing || this.copying)
          return;
      let designerUrl;
      this.$router.push(this.designUrl);
    },
    commitDetailsError(errData){
      this.createBuildingFailed = true;
      if(errData.locked){
        this.showAlert_QuoteTemplateLocked();
      }
      else{
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
              {
                title: 'OK',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
        })
      }
        
    },
    showAlert_QuoteTemplateLocked(){
      this.$modal.show('dialog', {
          title: 'Warning',
          text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
          buttons:[
            {
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
    },
    async downloadCsvBomExport(ref, structureName){
      let response = await this.requestCsvBomExport({id:ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
      }
      else{
        let bomData = response;
        let filename = `${structureName}_BOM.csv`;// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(bomData.fileContents, bomData.contentType);
        Util.downloadBlob(blob, filename);
      }

    },
    async requestMBS(ref, structureName) {
      let response = await this.fetchMBS({id: ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
      }
      else{
        let mbsData = response;
        let filename = "DesCtrlQuote.USR";// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(mbsData.fileContents, mbsData.contentType);
        Util.downloadBlob(blob, filename);
      }
    },
    async bidsheetPdfView(ref, summaryOnly) {
      let response = await this.generateBuildingQuotePDFV3({reference: ref, summaryOnly: summaryOnly});
      if(response.isError){
        this.commitDetailsError(response.data);
      }
      else{
        let openUrl = response
        window.open(openUrl);
      }
    },
    async onCompanyUserSelected(id){
      this.contractors = await this.fetchContractorsByRep(id);
      this.quote.lvl1UserId = id;
      this.inputfieldChanged();
    },
    async onContractorUserSelected(userId){
      this.quote.lvl2UserId = userId;
      this.inputfieldChanged();
    },
    async initPickerData(currentUser, currentUserEntity){
      if(this.isShareMode){
        this.$refs.companyPicker.disablePickers();
        this.$refs.contractorPicker.disablePickers();

        this.$refs.companyPicker.showEntity = false;
        this.$refs.companyPicker.showUser = true;
        this.$refs.contractorPicker.showEntity = false;
        this.$refs.contractorPicker.showUser = true;

        this.$refs.companyPicker.users = [await this.fetchUser(this.quote.lvl1UserId)];
        this.$refs.companyPicker.onUserSelected(this.quote.lvl1UserId);

        this.$refs.contractorPicker.users = [await this.fetchUser(this.quote.lvl2UserId)];
        this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
      }
      else{
      // Use context to figure out who the heck this project is being made for.
        if(this.entityType == CORE.entities.enterprise){
          this.$refs.companyPicker.showEntity = true;
          this.$refs.companyPicker.showUser = true;
          this.$refs.contractorPicker.showEntity = true;
          this.$refs.contractorPicker.showUser = true;


          // We have absolutely no clue.
          this.companies = await this.fetchAllCompanies();


          // If it's a pre-exisitng project, we have some clue who the company is
          // because every project should have a company attached to it
          if(!this.isNewProject){
            if(this.quote.lvl1UserId > -1){
              let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
              this.$refs.companyPicker.onEntitySelected(lvl1User.entityId);
              this.$refs.companyPicker.onUserSelected(this.quote.lvl1UserId);
            }
            if(this.quote.lvl2UserId > -1){
              let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
              this.$refs.contractorPicker.onEntitySelected(lvl2User.entityId);
              this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
            }

          }
            
        }
        else if(this.entityType == CORE.entities.company){
          // We know who the project company is, but not the comapny user nor the contractor user the project is assigned to.
          this.$refs.companyPicker.showEntity = false;
          this.$refs.companyPicker.showUser = true;
          this.$refs.contractorPicker.showEntity = true;
          this.$refs.contractorPicker.showUser = true;

          // get the users from the company
          this.$refs.companyPicker.users = await this.fetchUsersFromCompany(currentUserEntity.id);

          // If it's a pre-exisitng project, we have some clue who the company is
          // because every project should have a company attached to it
          if(!this.isNewProject){
            if(this.quote.lvl1UserId > -1){
              //let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
              this.$refs.companyPicker.onUserSelected(this.quote.lvl1UserId);
            }
            if(this.quote.lvl2UserId > -1){
              let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
              this.$refs.contractorPicker.onEntitySelected(lvl2User.entityId);
              this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
            }

          }
        }
        else{
          // We know who the project company, company user, and contractor is, but not the contractor user the project is assigned to.
          this.quote.lvl1UserId = this.contractorParentData.rep.sub

          this.$refs.companyPicker.showEntity = false;
          this.$refs.companyPicker.showUser = false;
          this.$refs.contractorPicker.showEntity = false;
          this.$refs.contractorPicker.showUser = true;
          this.$refs.contractorPicker.users = await this.fetchUsersFromContractor(currentUserEntity.id);     
          
          // If it's a pre-exisitng project, we have some clue who the company is
          // because every project should have a company attached to it
          if(!this.isNewProject){
            if(this.quote.lvl2UserId > -1){
              this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
            }

          }
        }
      }
    }
  },
  mounted(){
  },
  async created(){
    console.log('contractor parent data',this.contractorParentData)
     let pathParts = this.$route.path.split("/");
     let projectRef = pathParts[2];
      this.isAuthenticated = this.$auth.isAuthenticated;
      let currentUser;
      let currentUserEntity;
      if(this.isAuthenticated){
        currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        currentUserEntity = await this.$store.dispatch('entityModule/fetchEntity', currentUser.entityId);
        this.entityType = currentUserEntity.type;
      }

      let tempEditing;

      console.log('ProjectView State is New:', this.$route.path.includes('new'))
      this.isNewProject = this.$route.path.includes('new');
      this.isShareMode = this.$route.path.includes('share');

      if(this.isNewProject){
        if(!this.isAuthenticated)
          this.$router.go(-1) ;
        
        
        const newBuildingQuote = {
          id: 0,
          name: "",
          address: '',
          lvl1UserId: -1,
          lvl2UserId: -1,
          archive: false,
          tax: "No - Exempt",
          delivered: false,
          design: "",
          image: "",
          reference: '',
          buildingCode: "IBC 2015",
          seals: true,
          state: 0,    // 0 is NEW
          notes: "",
          version:3,
          priceScheme: 0
        };
        this.quote = newBuildingQuote;

        this.creating = true;
        tempEditing = true;
        this.copying = false;
        this.loadingProject=false;
        this.loadingDetails=false;
      } 
      else{
        this.quote = await this.fetchProject(projectRef);
        if(!this.quote.id)
          return; 

        this.loadingProject=false;        
        this.structureDetails = await this.fetchStructureDetails(this.quote.id)
        this.loadingDetails=false;
        tempEditing = false;
      }
      console.log('editing', this.editing)
      await this.initPickerData(currentUser, currentUserEntity);  
      this.editing = tempEditing;
      console.log('editing', this.editing)
  }
}
</script>

<style lang="scss" scoped>
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333;
$hover-color: #f1f8ff;
$green-color: #2ecc71;
$danger-color: #e74c3c;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$inputLabelColor: #eee;

// Breakpoint for tablet and below
$tablet-width: 768px;

@mixin transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease;
}

.projectView{
  background-color: $background-color;
}
.project-details, .project-downloads {
  margin: 20px;
  color: $text-color;
  
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 20px;
  // min-width: 300px;
  
  .details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: $tablet-width) {
      flex-direction: column;
    }
  }

  .project-image-wrapper {
    width: 90%;
    margin: 5%;
    @media (min-width: $tablet-width) {
      width: 50%;
    }

    .building-image {
      max-width: 75%;
      height: auto;
    }
  }

  .details-group {
    width: 100%;
    margin: 1%;
    @media (min-width: $tablet-width) {
      width: 50%;
    }

    .price-weight-wrapper{
      display: flex;
      @media (max-width: $tablet-width) {
        display: block;
      }
    }
    
    .input-group {
      display: flex;
      align-items: center;

      span {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 4px 0 0 4px;
        white-space: nowrap;
      }

      input[type="text"],
      input[type="number"],
      select {
        flex-grow: 1;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        border: 1px solid #ccc;
        border-radius: 0 4px 4px 0;
        border-left: none;

        &:focus {
          outline: none;
          border-color: $primary-color;
        }
      }

      input[type="checkbox"] {
        margin-left: 10px;
      }
    }

    #notes-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      span {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 4px 4px 0 0;
        width: 100%;
      }

      textarea {
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #555;
        border: 1px solid #ccc;
        border-radius: 0 0 4px 4px;
        resize: vertical;

        &:focus {
          outline: none;
          border-color: $primary-color;
        }
      }
    }
  }

  .form-actions {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-top: 20px;

    .btn {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      @include transition;

      &.btn-primary {
        background-color: $green-color;
        color: white;

        &:hover {
          background-color: darken($green-color, 10%);
        }
      }

      &.btn-secondary {
        background-color: $primary-color;
        color: white;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      &.btn-cancel {
        background-color: $danger-color;
        color: white;

        &:hover {
          background-color: darken($danger-color, 10%);
        }
      }
    }
  }
}

#assets{
  text-align: left;
  width:50%;
  margin:1%;
  
  table   
  {
    border-collapse: collapse;
    
    th, td {
      padding: 5px;
      border: 1px solid black;

  }
}
}

#data{
  text-align: left;
  width:50%;
  margin:1%;
}

.link{
  cursor: pointer;text-decoration: underline;
}

.required{
  color:red;
}
</style>