import {CORE} from '../constants.js'
import LogRocket from 'logrocket'
import api from '@/api';
export default class ContextLogic{

    static getBreadcrumbs(vue){

        let context = vue.$store.state.contextModule
        let current = context.current;
        let userEntity = context.userEntity;
        
        let crumbs = [];
        if (userEntity.type === CORE.modes.system){
            if(vue.$route.name== 'dashboard')
                crumbs.push({label:'Steel Ninja'});
            else
                crumbs.push({label:'Steel Ninja',path:'/'});
        }
        //if (current.mode === CORE.modes.company || current.mode === CORE.modes.contractor)
        {
            let comp = current.company;
            if(comp!=null)
                crumbs.push({label:comp?.businessName??"company dashboard",path:'/company/'+comp?.id});
        }
        //if (current.mode === CORE.modes.contractor)
        {
            let cont = current.contractor;
            if(cont!=null)
                crumbs.push({label:cont?.businessName??"contractor dashboard",path:'/contractor/'+cont?.id});
        }

        switch(vue.$route.name.toLowerCase()){
            case 'project':
                crumbs.push({label:'Projects', path:'/projects'});
                break;
            default: 
            //    if(vue.$route.meta.breadcrumb)
                    //crumbs.push({label:vue.$route.meta.breadcrumb,path:vue.$route.path});
                break;
        }

        console.log(vue.$route.meta.breadcrumb)
        
        return crumbs;
    }

    static getEntityNameByType(entityType){
        if(entityType == CORE.entities.enterprise)
            return 'entity'
        else if(entityType == CORE.entities.company)
            return 'company'
        else
            return 'contractor'
    }

    static getMode(){
        let currentContext = JSON.parse(localStorage.getItem('currentContext'))
        if(currentContext.contractor !== -1)
            return CORE.modes.contractor;
        else if(currentContext.company !== -1)
            return CORE.modes.company;
        else
            return CORE.modes.system;
    }

    static routeToUserDefaultPage(store, entityType, entityId, router){
        if(entityType === CORE.entities.enterprise){
            
        }
        else if(entityType === CORE.entities.company){
            //let dashboardAlias = `/company/${entityId}`;
            //router.push(dashboardAlias);
            router.push(`/company/${entityId}/projects`)
        }
        else if(entityType === CORE.entities.contractor){
            let dashboardAlias = `/contractor/${entityId}`;
            router.push(dashboardAlias);  
        }
        else{
            // get the entity the authenticated user is from
            // if it is a contractor, return the contractor view
            // if it is a company, return the company view
        }
    }

    static async getDefaultMode(store, auth, router){
        if(!auth)
            return;
        if(!auth.user)
            return;
        let loggedInUser = auth.user;
        if(loggedInUser.userRoles.includes('SysAdmin'))
            return 0;
        else{            
            let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            let entity = await store.dispatch('entityModule/fetchEntity', currentUser.entityId); //this.fetchEntity(currentUser.entityId);     
            
            if(entity.type == CORE.entities.company){
                store.commit('contextModule/setCurrentCompany', entity)
            }
            else
            if(entity.type == CORE.entities.contractor){
                store.commit('contextModule/setCurrentContractor', entity)
                let entityRep = await store.dispatch('userModule/fetchUser', entity.repUserId)
                store.commit('contextModule/setContractorParentData', {company: entity, rep: entityRep})
            }
            this.routeToUserDefaultPage(store, entity.type, entity.id, router);
            return entity.type
        }
    }

    static async initContext(vue) {
        let {$store, $auth, $router, $route} = vue;
        // Init Token first in case we need to call home
        let token = localStorage.getItem('token');
        if(!token)
            localStorage.setItem('token', await $auth.getTokenSilently());

        let mode = $store.state.contextModule.current.mode;;

        // attempt to learn the user from browser session storage
        let user = JSON.parse(sessionStorage.getItem('currentUser'));
        if(user){

            // if we know the user, we also have existing mode
            // when browsing entities and projects, the mode has to keep up.
            // we can't always set the mode based on the route.path because projects has neither /company nor /contractor
            // if the mode is kept accurate based on what entity link you click (what dashboard you hit, then that would work)
            
            // this is great except for when you visit /projects after navigating down to contractor projects.
            // then, since this always runs (after first lookup), and localhost:8080 doesn't contain company or contractor, the mode gets reverted back to system when it should stay contractor
        
            //Set Mode
            if($route.name == 'dashboard' || $route.name == 'entityDashboard')
            {
            if($route.path.includes('company'))
                mode= CORE.modes.company;
            else if($route.path.includes('contractor'))
                mode= CORE.modes.contractor;
            else
                mode= CORE.modes.system;
            }
        }
        else {
            // first load after login, we don't know the user
            let sub = $auth.user.sub.substring($auth.user.sub.indexOf('|') + 1);
            // look up the user
            user = await $store.dispatch('userModule/fetchMyUser', sub);            
            // cache the user in browser session storage
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            // set the mode based on who they are
            mode = await this.getDefaultMode($store, $auth, $router);
        }

        
        let routeEntityId = vue.$route.params["id"];

        if(mode == null){
            
        }
            
        if(mode == CORE.modes.system)
        {
            $store.commit('contextModule/clearCurrentCompany');
            $store.commit('contextModule/clearCurrentContractor');
        }

        if (mode == CORE.modes.company)
        {
            await this.setCurrentCompany($store, routeEntityId);
            $store.commit('contextModule/clearCurrentContractor');
        }
        
        if (mode == CORE.modes.contractor)
        {
            await this.setCurrentContractorAndParentCompany($store, routeEntityId);            
        }

        if(!$store.state.contextModule.userEntity){
            let currentUserEntity = await $store.dispatch('entityModule/fetchEntity', user.entityId);
            $store.commit('contextModule/setUserEntity', currentUserEntity);
        }
        
        LogRocket.identify(user.sub, {
            name: user.name,
            email: user.emailAddress
        })
        $store.commit('contextModule/setUser', user);
        $store.commit('contextModule/setMode', mode);
    }

    static async setCurrentCompany($store, id){
        if(typeof id === 'undefined')
            return;
        // if this information is already known, short-circuit
        if($store.state.contextModule.current.company && $store.state.contextModule.current.company.id == id)
            return;
        // fetch the info
        let entityInfo = await api.getEntity(id)
        // add to state
        $store.commit('contextModule/setCurrentCompany', entityInfo);
    }

    static async setCurrentContractorAndParentCompany($store, id){
        if(typeof id === 'undefined')
            return;
        // if this information is already known, short-circuit
        if($store.state.contextModule.current.contractor && $store.state.contextModule.current.contractor.id == id)            
            return;
        // fetch the info
        let contInfo = await api.getEntity(id)
        // add to state
        $store.commit('contextModule/setCurrentContractor', contInfo);
        // fetch the company rep info
        let compRep = await $store.dispatch('userModule/fetchUser', contInfo.repUserId)
        // update the company too, if necessary        
        this.setCurrentCompany($store, compRep.entityId)

    }

 
}