import api from '@/api';
import {CORE} from '../../constants.js'

const state = {
  user:null,
  userEntity:null,

  current: {
    mode: null,
    company: null,
    contractor:null,    
  },
  contractorParentData: {}
};

const mutations = {
  setUser(state, user){
    state.user = user;
  },
  setUserEntity(state, userEntity){
    state.userEntity = userEntity;
  },
  setMode(state, view){
    state.current.mode = view;
  },
  setContractorParentData(state, data){
    state.contractorParentData = data
  },
  setCurrentCompany(state, entity){
    state.current.company = entity;    
  },
  setCurrentContractor(state, entity){
    state.current.contractor = entity;    
  },
  clearCurrentCompany(state){
    state.current.company = null;
  },
  clearCurrentContractor(state){
    state.current.contractor = null;
  }

};

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};