import * as THREE from 'three';
import Tri from '../3d/Tri';

export default class EarcutHelper{

    static createSquareHole(x,y, width,height, dimensions=2){
        let hw = width/2;
        let hh = height/2;
      
        switch(dimensions)
        {
            case 2:
                return [
                    x-hw,y-hh,
                    x+hw,y-hh,
                    x+hw,y+hh,
                    x-hw,y+hh]
            case 3:        
                return [
                    x-hw,y-hh,z,
                    x+hw,y-hh,z,
                    x+hw,y+hh,z,
                    x-hw,y+hh,z]
            default:
                throw 'EarcutHelper: unrecognized quad dimensions'
        }
    }

    

    static createTriHole(p1,p2,p3,dimensions=2){
        switch(dimensions)
        {
            case 2:
                return [
                    p1.x,p1.y,
                    p2.x,p2.y,
                    p3.x,p3.y,]            
            case 3:
                return [
                    p1.x,p1.y,p1.z,
                    p2.x,p2.y,p2.z,
                    p3.x,p3.y,p3.z,
                ];
            default:
                throw 'EarcutHelper: unrecognized tri dimensions'
        }
    }    

    
    static createGroup(triangles, leftMost, width, bottomMost, height, side) {
        let group = new THREE.Group();
        group.name = 'earcut'
        for (let iTri = 0; iTri < triangles.length; iTri++) {
    
          let {v1, v2, v3} = triangles[iTri];
    
          let 
          v1x =(v1.x-leftMost)/width, 
          v1y =(v1.y-bottomMost)/height,
          v2x =(v2.x-leftMost)/width,
          v2y =(v2.y-bottomMost)/height,
          v3x =(v3.x-leftMost)/width,
          v3y =(v3.y-bottomMost)/height
        
         

          let tri = new Tri(
            'tritri',
            [
              v1,
              v2,
              v3, // bottom left 
            ],
            [
              v1x,v1y,v2x,v2y,v3x,v3y
            ]
          );
    
          

          let mesh = new THREE.Mesh(tri.geometry, side.material);
          group.add(mesh);
        }
        return group;
      }



    static processEarcutData(points, dimension, coords) {
        let leftMost = Infinity;
        let rightMost = -Infinity;
        let topMost = -Infinity;
        let bottomMost = Infinity;
        let triangles = [];
        for (let iTri = 0; iTri < points.length / 3; iTri++) {
          let pt1 = points[iTri * 3];
          let pt2 = points[iTri * 3 + 1];
          let pt3 = points[iTri * 3 + 2];
    
          if (dimension === 2) {
            let lv1 = new THREE.Vector2(coords[pt1 * dimension + 0], coords[pt1 * dimension + 1]);
            let lv2 = new THREE.Vector2(coords[pt2 * dimension + 0], coords[pt2 * dimension + 1]);
            let lv3 = new THREE.Vector2(coords[pt3 * dimension + 0], coords[pt3 * dimension + 1]);
            var v1 = new THREE.Vector3(lv1.x, lv1.y, 0);
            var v2 = new THREE.Vector3(lv2.x, lv2.y, 0);
            var v3 = new THREE.Vector3(lv3.x, lv3.y, 0);
          }
          else {
            let lv1 = new THREE.Vector3(coords[pt1 * dimension + 0], coords[pt1 * dimension + 1], coords[pt1 * dimension + 2]);
            let lv2 = new THREE.Vector3(coords[pt2 * dimension + 0], coords[pt2 * dimension + 1], coords[pt2 * dimension + 2]);
            let lv3 = new THREE.Vector3(coords[pt3 * dimension + 0], coords[pt3 * dimension + 1], coords[pt3 * dimension + 2]);
            var v1 = new THREE.Vector3(lv1.x, lv1.y, lv1.z);
            var v2 = new THREE.Vector3(lv2.x, lv2.y, lv2.z);
            var v3 = new THREE.Vector3(lv3.x, lv3.y, lv3.z);
          }
    
          if (v1.x < leftMost)
            leftMost = v1.x;
          if (v2.x < leftMost)
            leftMost = v2.x;
          if (v3.x < leftMost)
            leftMost = v3.x;
    
          if (v1.x > rightMost)
            rightMost = v1.x;
          if (v2.x > rightMost)
            rightMost = v2.x;
          if (v3.x > rightMost)
            rightMost = v3.x;
    
          if (v1.y < bottomMost)
            bottomMost = v1.y;
          if (v2.y < bottomMost)
            bottomMost = v2.y;
          if (v3.y < bottomMost)
            bottomMost = v3.y;
    
          if (v1.y > topMost)
            topMost = v1.y;
          if (v2.y > topMost)
            topMost = v2.y;
          if (v3.y > topMost)
            topMost = v3.y;
    
          triangles.push({ v1, v2, v3 });
        }
    
        let width = rightMost - leftMost;
        let height = topMost - bottomMost;
        return { triangles, leftMost, width, bottomMost, height };
      }
  
}
