// this is the place to define all 
// constants
// constraints
// rarely-changed variables
 
export let CORE = {
    entities:{
        enterprise: 0,
        company: 1,
        contractor: 2
    },
    modes:{
        system: 0,
        company: 1,
        contractor: 2,      
    }
}