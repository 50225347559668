<template>
    <div>        
        <template >
            <!-- <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" style='width:20px;display:inline;'/> -->
            <span v-if="quote.inProgress" >Quoting </span>

            <label v-if="!quote.inProgress && price==''" >Quote</label>            
            
            <div v-if="!quote.inProgress && price!='' && quote.success" style="display:flex;justify-content:space-around;">

            <label class='price'>{{formattedPrice}}</label>
            /
            <label class='price'>{{ formattedWeight}}</label>

            </div>
            <span v-if="!quote.inProgress && !quote.success">Quote Failed <template v-if="quote.showResult">❌</template></span>         
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    props:[
        'name',
        'specs',
        'mode',
        'branding'        
        ],
    computed:{
        ...mapState('designModule', [
      'showQuoting',
    ]),

        ...mapGetters('designModule', [
      'quote',
      'price',
      'weight'
    ]),
    formattedPrice(){
        let USDollar = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0
                    });
        return USDollar.format(this.price);
    },
    formattedWeight(){
        return new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        maximumFractionDigits: 0
                    }).format(this.weight) + ' Lbs';
    }
        
    },
    data(){
        return {
           
        }
    },
   
}
</script>

<style scoped>

.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    left:0px;
    top:0px;
    color:white;
    display: flex;
}

.meta{
    display:inline-block;
    margin-left:10px;
    flex-grow: 2;
}

.name {
    font-size: 1em;
}

.specs {
    font-size: .7em;
}

.logo{
    width:150px;
    position:relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.menuItem {
    margin:2px 10px;
    position:relative;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
}

.quote{
  display:block;
}

</style>