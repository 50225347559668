const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const USE_TEST_DATA = process.env.VUE_APP_USE_TEST_DATA === "true";
import { getInstance } from "./auth/index.js";
import { testProject, testProjects, testEntities } from "./testData";
import { jwtDecode } from "jwt-decode";

class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }


  async request(endpoint, method = 'GET', data = null, stringifyBody=true) {
    if (USE_TEST_DATA) {
      return this.getTestData(endpoint);
    }

    if(this.isTokenExpired()){
      const authService = getInstance();
      localStorage.removeItem('token');
      try{
      localStorage.setItem('token', await authService.getTokenSilently())
      }
      catch (error){
        console.log(error);        
      }
    }

    const url = `${this.baseUrl}${endpoint}`;
    const headers = {
      //'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    };

    const options = {
      method,
      headers,
      //credentials: 'include', // This is important for handling cookies, if your API uses them
    };

    // check if we are sending FormData
    const isFormData = data instanceof FormData;

    if (data && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      //if the data if FormData, do not set Content-Type
      if(isFormData){}
        //headers['Content-Type'] = 'multipart/form-data'
      else
        headers['Content-Type'] = 'application/json';
      options.body = stringifyBody ? JSON.stringify(data) : data;
    
    }

    try {
      const response = await fetch(url, options)

      if (!response.ok) {
        throw new Error(`HTTP error! Could not connect to the server. Status: ${response.status}`);

      }
   
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        let resp = await response.json();
        return resp;
      } else {
        return await response.text();
      }
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }

  getTestData(endpoint) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (endpoint === '/projects') {
          resolve({ data: testProjects });
        } else if (endpoint.startsWith('/projects/')) {
          const projectId = endpoint.split('/')[2];
          let testProject = testProjects.find(o => o.id === projectId)
          resolve({ data: testProject });
        } else if(endpoint.startsWith('/entities')){
          resolve({ data: testEntities});
        } else {
          resolve({ data: null });
        }
      }, 200); // Simulate network delay
    });
  }

  isTokenExpired(){
    const token = localStorage.getItem('token');
    if(!token) return true;

    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds

    return decoded.exp < currentTime;

  }

  //#region COMMON methods
  async get(endpoint) {
    return this.request(endpoint);
  }

  async post(endpoint, data, stringifyBody=true) {
    return this.request(endpoint, 'POST', data, stringifyBody) ;
  }

  async put(endpoint, data) {
    return this.request(endpoint, 'PUT', data);
  }

  async patch(endpoint, data) {
    return this.request(endpoint, 'PATCH', data);
  }

  async delete(endpoint) {
    return this.request(endpoint, 'DELETE');
  }
  async sayHi(){
    return this.get(`/entityManager/sayHi`);
  }
  //#endregion
 
  //#region ENTITY methods
  async getAllEntities() {
    return this.get(`/entityManager`)
  }
  async getEntity(id){
    return this.get(`/entity/${id}`);
  }

  async getAllCompanies(){
    return this.get(`/entityManager/getAllCompanies`)
  }
  async getSubEntities(id){
    return this.get(`/entityManager/getSubEntities/${id}`);
  }
  async getContractorsByRep(sub){
    return this.get(`/entityManager/getContractorsByRep/${sub}`)
  }
  //#endregion

  //#region PROJECT methods
  async getProjects(sub){
    let path = `/project/list`;
    if(sub)
      path = `${path}?sub=${sub}`
    
    return this.get(path)
  }
  async getProject(projectId){
    return this.get(`/project/${projectId}`)
  }

  async requestCsvBomExport(payload){
    return await this.post('/project/requestCsvBomExport', payload)
  }
  //#endregion

  //#region DESIGN methods
  async getDesign(reference){
    return await this.get(`/design/${reference}`)
  }

  async getIsDesignEditable(reference){
    return await this.get(`/design/${reference}/editable`)
  }

  async saveDesign(body){
    return await this.post(`/design/save`, body, false)
  }

  //#endregion

  async requestCsvBomExport(payload){
    return await this.post('/project/requestCsvBomExport', payload)
  }
  //#region USER methods
  async getMe(){
    return this.get(`/user`)
  }
  async getUser(identifier){
    return this.get(`/user/${identifier}`)
  }
  async getUsersFromCompany(entityId){
    return this.get(`/user/getUsersFromCompany/${entityId}`)
  }
  async getUsersFromContractor(entityId){
    return this.get(`/user/getUsersFromContractor/${entityId}`)
  }
  //#endregion



  //#region ITEM LIST methods
  async getItemListForEntity(entityId){
    return this.get(`/itemlist/entity/${entityId}`)
  }

  async getItemListForSystem(){
    return this.get(`/itemlist/entity/${1}`)
  }
  //#endregion

}

export default new Api(API_BASE_URL);