<template>
    <div class="profile-settings">
      <div class="header">
        <div class="header-info">
            <div class="logo">
                <img v-if="entity.logo" :src="entity.logo" alt="Company Logo" />
                <span v-else>Logo</span>
            </div>
            <h2>{{ entity.legalName }}</h2>
        </div>
      
        <div class="header=actions">
            <button v-if="!editing" class="btn btn-primary" id="edit" v-on:click="setEntityInfoEditMode">Edit</button>
        </div>
      </div>
  
      <div>
        <div class="section-group">
            <div class="form-group">
                <label for="legalName">Legal Name</label>
                <input type="text" :disabled="!editing" id="legalName" v-model="entity.legalName" required />
            </div>
    
            <div class="form-group">
                <label for="businessName">Business Name</label>
                <input type="text" :disabled="!editing" v-model="entity.businessName" id="businessName" />
            </div>
        </div>

        <div class="section-group">
            <span class="section-group-title"><b>Contact</b></span>
            <div class="form-group">
                <label for="address">Phone Number</label>
                <input :disabled="!editing" type="number" v-model="entity.phoneNumber" />
            </div>
            <div class="form-group">
                <label for="address">Website</label>
                <input :disabled="!editing" type="text" v-model="entity.website"/>
            </div>
            
        </div>
        
        <div class="section-group">
            <span class="section-group-title"><b>Mailling Address</b></span>
            <div class="form-group">
                <label for="address">Address 1</label>
                <input :disabled="!editing" type="text" v-model="entity.mailingAddress1" />
            </div>
            <div class="form-group">
                <label for="address">Address 2</label>
                <input :disabled="!editing" type="text" v-model="entity.mailingAddress2"/>
            </div>
            <div class="form-group">
                <label for="address">City</label>
                <input :disabled="!editing" type="text" v-model="entity.mailingAddressCity"/>
            </div>
            <div class="form-group">
                <label for="address">State</label>
                <input :disabled="!editing" type="text" v-model="entity.mailingAddressState"/>
            </div>
            <div class="form-group">
                <label for="address">Zip</label>
                <input :disabled="!editing" type="text" v-model="entity.mailingAddressZip"/>
            </div>
        </div>
    
        <div class="section-group">
            <div class="form-group">
                
                <label for="logoUpload">Upload Logo</label>

                <div class="logo-input">
                    <div class="logo-preview">
                        <img v-if="entity.logo" :src="entity.logo" alt="Company Logo" />
                        <span v-else>Logo</span>
                    </div>
                    <input :disabled="!editing" type="file" id="logoUpload" @change="handleLogoUpload" />
                </div>
                
            </div>
        </div>
      
  
        <button v-if="editing" class="btn btn-primary" v-on:click="saveChanges">Save Changes</button>
    </div>
    </div>
  </template>
  
  <script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
  export default {
    data() {
      return {
        entity: {},
        editing: false,
      };
    },
    methods: {
      ...mapActions('entityModule', ['updateEntityInfo', 'fetchEntity']),
      setEntityInfoEditMode(){
        this.editing = true;
        this.$router.push(`${this.$route.path}/edit`) ;
      },
      async saveChanges(){
        let response = await this.updateEntityInfo(this.entity);
        if(response.isError)
            this.commitDetailsError(response.data)
        else{
            this.editing = false;
            this.$router.push(this.$route.path.replace('/edit', ''));
        }
      },
      commitDetailsError(errData){
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
            {
                title: 'OK',
                handler: () => {
                this.$modal.hide('dialog')
                }
            },
            ]
        })
      },
      handleLogoUpload(event) {
        const file = event.target.files[0];
        if (file) {
          this.logo = URL.createObjectURL(file);
        }
      },
    },
    async created() {
        await ContextLogic.initContext(this);
        let pathParts = this.$route.path.split("/");
        this.entity = await this.fetchEntity(pathParts[2]);
    },
  };
  </script>
  
  <style lang="scss">
 .profile-settings {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .logo-preview {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #f0f1f5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Added shadow here */
      
      img {
        max-width: 100%;
        border-radius: 50%;
      }
    }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10%;
    justify-content: space-between;

    .header-info{
        display: flex;
        width: 75%;
        .logo{
            width: 100%;
        }

        h2 {
            font-size: 24px;
            margin: 0;
        }
    }

   .header-actions{

    .btn {
        background-color: #000000cb;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 13px;
        margin: 2%;
        text-align: center;
        &:hover {
        background-color: #5f6065;
        }
    }
   }
  }

    .section-group{
        padding: 3% 0% 0% 0%;
        border-top: solid rgb(230, 227, 227) 1.5px;
        border-bottom: solid rgb(230, 227, 227) 1.5px;
    }


  .form-group {
    margin-bottom: 15px;
    display:flex;
    text-align: left;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    

    label {
      display: block;
      margin-bottom: 5px;
      //font-weight: bold;
    }

    input {
      width: 65%;
      padding: 8px;
      border: 1px solid #bec3cf;
      border-radius: 4px;
      font-size: 16px;
      
    }
    .logo-input{
        display: flex;
    }
  }
}
  </style>