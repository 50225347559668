import ColorHelper from './colorHelper.js'

export default class OptionHelper {
/*
key is absolutely required for any options that must be uniquely identified
name is part of the three field system
text is not a part of that system.
*/
    static header(text, info){
        return {key: text, text, info, type: 'header'};
    }

    static text(key, name, impactType, fnIn, fnOut, fnChange){
        return {key, name, impactType, enabled:true, fnIn, fnOut, fnChange, type:'text'}
    }
    

    static checkHeader(key, text, impactType, enabled, fnIn, fnOut, fnChange){
        return {key, text, impactType, enabled, fnIn, fnOut, fnChange, type:'checkHeader'}
    }

    static checkbox(key, name, impactType, enabled, fnIn, fnOut, fnChange){
        return {key, name, impactType, enabled, fnIn, fnOut, fnChange, type:'checkbox'}
    }

    static switch(key, name, helpText, valOff, valOn, impactType, bgColors, swColors, enabled, fnIn, fnOut, fnChange){
        return {key, name, helpText, valOff, valOn, impactType, bgColors, swColors, enabled, fnIn, fnOut, fnChange, type:'switch'}
    }

    static numericUpDown(key, name, impactType, unit, min, max, increment, enabled, fnIn, fnOut, fnChange, info){
        return {key, name, impactType, unit, min, max, increment, enabled, fnIn, fnOut, fnChange, info, type:'nud'}
    }

    static range(key, name, impactType, leftLabel, rightLabel, min, max, increment, enabled){
        return {key, name, impactType, leftLabel, rightLabel, min, max, increment, enabled, type:'range'}
    }
    
    static chknumericUpDown(key, name, impactType, unit, min, max, increment, fnIn, fnOut, fnChange){
        return {key, name, impactType, unit, min, max, increment, enabled:true , fnIn, fnOut, fnChange, type:'chkNud'}
    }

    static radios(key, name, impactType, selOptions, fnIn, fnOut, fnChange){
        return {key, name, impactType, selOptions, enabled:true, fnIn, fnOut, fnChange, type:'radio'}
    };

    static selection(key,name, impactType,  unit, selOptions, enabled, fnIn, fnOut, fnChange){
        return {key, name, impactType,  unit, selOptions, enabled, fnIn, fnOut, fnChange, type:'selection'}
    }

    

    static color(key, name, impactType, palette, enabled, fnIn, fnOut, fnChange, fnHasChanged){
        let selOptions = [];
        if(!palette){
            palette = ColorHelper.getFullPalette();            
            //selOptions.sort((a,b)=> {return a.hex-b.hex});
        }
        palette.forEach((c)=>{
            selOptions.push({
                color: `#${c.hex}`,
                label: c.name,
                hex: c.hex,                    
            });
        });
        return {key, name, impactType, selOptions, enabled, fnIn, fnOut, fnChange, fnHasChanged, type:'color'}
    }

    static button(key, name){
        return {key, name, type: 'button'}
    }

    static delete(){
        return this.button("delete", "Delete")
    }

    static center(){
        return this.button("center", "Center")
    }

    static copy(){
        return this.button("copy", "Copy")
    }

    static getKeyArray(key){
        return key.split('.');
    }

    static getValueOfProperty(obj, key){
        if(typeof key === 'string')
            key = this.getKeyArray(key);        
        
        let firstKey = key[0];
        let val = obj[firstKey]
        if(key.length===1)
            return val;
        else{
            let remainingKey = key.slice(1); // jettison the first key, it's been used
            return this.getValueOfProperty(val, remainingKey)
        }
        
    }

    static setValueOfProperty( obj, key, value){
        if(typeof key === 'string')
            key = this.getKeyArray(key);        
        
        let firstKey = key[0];
        if(key.length===1)
            obj[firstKey]=value;
        else{
            if(typeof obj[firstKey] !== 'object')
                obj[firstKey] = {};

            let subObj = obj[firstKey]
            // assert that the subObject is indeed an object            
            let remainingKey = key.slice(1); // jettison the first key, it's been used
            this.setValueOfProperty(subObj, remainingKey, value)
        }          
    }

    static getOptionByKey(opts, key){
        for(var i=0;i<opts.length;i++)
            if(opts[i].key === key)
                return opts[i];        
        return null;
    }

}