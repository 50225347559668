import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
    async downloadItemListTemplate({commit}, entityId){
        try {
            const response = await api.post(`/itemlist/downloadTemplate/${entityId}`);
            return response;
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    },
    async uploadItemListTemplate({commit}, payload){
        try {
            const response = await api.post(`/itemlist/uploadTemplate?entityId=${payload.entityId}`, payload.file, false);
            return response;
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};