import * as THREE from 'three';
import Util from '../utility.js'
import {CORE, rebuildTypes} from '../_spec.js'
import RoofBase from './RoofBase.js'
import _3dTrimEaveGutter from '../3d/TrimEaveGutter.js'
import _3dTrimEaveAngle from '../3d/TrimEaveAngle.js'
import _3dTrimRake from '../3d/TrimRake.js'
import _3dPurlinC from '../3d/PurlinC.js'
import _3dPurlinZ from '../3d/PurlinZ.js'
import _3dEaveStrut from '../3d/EaveStrut.js'
import _3dInsulation from '../3d/Insulation.js'
import layerHelper from '../helpers/layerHelper.js';
import BuildLogic from './BuildLogic.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import { Earcut } from 'three/src/extras/Earcut.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import Sheeting from '../3d/Sheeting.js';
import RafterStraight from '../3d/RafterStraight.js';
import TrimGutterDownspout from '../3d/TrimGutterDownspout.js';
import DownspoutOffset from '../3d/DownspoutOffset.js';
import TrimSoffitRake from '../3d/TrimSoffitRake.js';
import TrimSoffitEave from '../3d/TrimSoffitEave.js';
import TreeNode from '../helpers/TreeNode.js';
import Sphere from '../3d/Sphere.js';


export default class RoofMainSlope extends RoofBase{
    constructor(
        design,         
        length,
        width,
        eaveHeight,
        purlinType,        
        trimMaterials, 
        frontEaveSegments, 
        frontDownspouts, // new
        backEaveSegments, 
        rotTexture,
        insulation,
        purlinColor,
        tieIns = [],
        roofExt,  // new
        framelines,  // new
        config  // new
        ){
        super(design)
        
        this.group.name = "CompGroup RoofSlope";        // component group
        this.front = new THREE.Group();
        this.group.add(this.front);
        this.insulation = insulation;
        this.tieIns = tieIns;
        this.width = width;
        this.length = length;
        this.halfLength = this.length/2;
        this.halfWidth = this.width/2;
        this.eaveHeight = eaveHeight;
        this.pitchRatio = BlueprintHelper.pitchToPitchRatio(design.pitch);
        this.pitchRadians = BlueprintHelper.pitchRatioToRadians(this.pitchRatio);        
        this.pitchDegrees = BlueprintHelper.pitchRatioToDegrees(this.pitchRatio);        
        this.pitchHeight = BlueprintHelper.pitchHeight(this.width, this.pitchRatio, design.roofType);
        this.roofExt = roofExt;
        this.framelines = framelines;

        
        this.frontSideExtensionLength = roofExt.front*12
        this.backSideExtensionLength = roofExt.back*12;

        this.leftEndExtensionLength = roofExt.left*12;
        this.rightEndExtensionLength = roofExt.right*12;

        
        this.front.position.y = this.pitchHeight+.1 // .1 prevents clipping of eave strut
        this.front.position.z = -this.width/2;
        this.front.rotation.x=.01  // debug
        this.front.rotation.x = Math.PI/2 + this.pitchRadians;

        this.eaveGutterOffsetPitchRadians = BlueprintHelper.pitchRatioToRadians(3/12) 
        let gutterOffsetBendSpacing = 4;// arbitrary 4 simulates space to bend gutter downspout twice
        this.frontSideExtensionPitchHeight = this.frontSideExtensionLength * Math.sin(this.pitchRadians);
        this.frontSideExtensionPitchDepth = this.frontSideExtensionLength * Math.cos(this.pitchRadians);
        this.frontGutterOffsetHeight = this.frontSideExtensionPitchDepth * Math.tan(this.eaveGutterOffsetPitchRadians);
        this.frontDownspoutDropDistance = this.frontSideExtensionPitchHeight + this.frontGutterOffsetHeight
            if(this.frontDownspoutDropDistance > 0)
            this.frontDownspoutDropDistance+= CORE.roof.purlin.dim.height+gutterOffsetBendSpacing; 
        


        this.rotTexture = rotTexture;
        
        this.frontEaveSegments = frontEaveSegments;
        this.frontDownspouts = frontDownspouts;

        this.backEaveSegments = backEaveSegments;
        ////////////////////////
        
        let backRoofHeightInches = +this.pitchHeight;
        this.backLeftRoofPos = new THREE.Vector3(-this.halfLength,backRoofHeightInches,-this.halfWidth);

        this.frontLeftRoofPos = new THREE.Vector3(-this.halfLength,0,this.halfWidth);

        this.backRightRoofPos = new THREE.Vector3(this.halfLength,backRoofHeightInches,-this.halfWidth);

        this.frontRightRoofPos = new THREE.Vector3(this.halfLength,0,this.halfWidth);

        this.pitchPurlinDimY =  BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        this.RidgePurlinPosY = 0 + this.pitchHeight - this.pitchPurlinDimY;

        this.purlinType = purlinType;

        this.frontLeftEavePos = new THREE.Vector3(-this.halfLength,0-this.pitchPurlinDimY,this.halfWidth);
        this.frontRightEavePos = new THREE.Vector3(this.halfLength,0-this.pitchPurlinDimY,this.halfWidth);
        this.backLeftEavePos = new THREE.Vector3(-this.halfLength,this.RidgePurlinPosY,-this.halfWidth);
        this.backRightEavePos = new THREE.Vector3(this.halfLength,this.RidgePurlinPosY,-this.halfWidth);

        ////////////////////////
        // purlins and eaves color are both defined by the option galvanized purlins
        this.purlinColor = purlinColor ? purlinColor : CORE.frame.redOxideColor;
        
        this.config = config;

        this.build(trimMaterials, insulation);
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
    }

    build(trimMaterials, insulation) {

        this.buildPurlins(); // processed
        this.buildEaves();
        this.buildPanels();
        this.buildEaveTrim(trimMaterials.eaveAndRake); // processed
        this.buildMainRakeTrim(trimMaterials.eaveAndRake); // processed 
        this.buildSoffitTrim(trimMaterials.eaveAndRake);

        if(this.config.gutters)
            this.buildDownspouts(trimMaterials);

        this.buildSidewallExtensions();

        if (insulation.roof !== CORE.insulation.standard.none.value)
            this.buildInsulation_Standard();
        if (insulation.energySaver.type !== CORE.insulation.insulband.none.value)
            this.buildInsulation_Insulband();
    }

    buildDownspouts(trimMaterials){
        let offsetFromStructure = .25;

        if(this.frontDownspouts)
        this.frontDownspouts.forEach((d)=>{
            let offset = new DownspoutOffset(10,this.frontSideExtensionPitchDepth, 10, this.eaveGutterOffsetPitchRadians, trimMaterials.downspout)
            this.front.add(offset.group);
            let g = new TrimGutterDownspout(d.length - this.frontDownspoutDropDistance, trimMaterials.downspout);
            this.front.add(g.group);

            // frontSideExtensionPitchHeight can be used as a hypotenuse to find the 
            // adjacent and opposite sides in order to position this downspout
            

            let yOffset = this.frontDownspoutDropDistance*Math.sin(this.pitchRadians);
            let zOffset = this.frontDownspoutDropDistance*Math.cos(this.pitchRadians);
            g.group.position.set(d.x, this.getFrameRakeLength()+offsetFromStructure+yOffset, +zOffset);
            g.group.rotation.x = - Math.PI/2 - this.pitchRadians; 
            g.group.rotation.y = 0;//-Math.PI;

             // y+3.5 represents the depth of the gutter plus the offsetFromStructure (which is affected by the roof pitch)
            offset.group.position.set(d.x, this.getFrameRakeLength() + this.frontSideExtensionLength +3.5,+CORE.roof.purlin.dim.height/2);
            offset.group.rotation.y = 0;//-Math.PI;
            offset.group.rotation.x =-  Math.PI/2 - this.pitchRadians;
            layerHelper.setGroup(offset.group, CORE.layers.walls);
            layerHelper.enableLayer(offset.group, CORE.layers.quote);
            layerHelper.enableLayer(g.group, CORE.layers.quote);
        });

        
    }

    buildSidewallExtensions(){
        // build an ext rafter at each rafter 

        // length is frame versus back/front
        this.framelines.forEach((fl)=>{
            this.buildExtensionRafters(fl);
        });

        this.buildExtensionPurlin();

    }
    
    
    buildExtensionPurlin(){
        if(this.frontSideExtensionLength && this.frontSideExtensionLength>0)
            this.buildFrontExtensionPurlin();
        if(this.backSideExtensionLength && this.backSideExtensionLength>0)
            this.buildBackExtensionPurlin();
    }

    buildFrontExtensionPurlin(){
        // get the "rakeLength" of the roof
        let frameLength = this.getFrameRakeLength();
        let frontLength = this.frontSideExtensionLength;

        let frontPurlinStart = 0;
        let frontPurlinEnd = frontLength
        if(frontPurlinEnd<frontPurlinStart)
            return;
        
        let frontPurlinRange = frontPurlinEnd - frontPurlinStart;
        let frontPurlinCount = Math.ceil(frontPurlinRange / CORE.roof.purlin.spacing.min);
        let frontPurlinSpacingActual = frontPurlinRange / frontPurlinCount;
        let purlinCurr = frontPurlinStart + frontPurlinSpacingActual;
        
        while(purlinCurr <= frontPurlinEnd){
            let len = this.length;
            let posX = 0;
            
            let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(frameLength+purlinCurr);
            len += leftEndAddition;
            // any additional left end length requires we shift the center toward that end
            posX -= leftEndAddition/2;

            let rightEndAddition = this.getRightEndAdditionAtFrontPosition(frameLength+purlinCurr);
            len += rightEndAddition;
            // any additional right end length requires we shift the center toward that end
            posX += rightEndAddition/2;

            let pos = new THREE.Vector3(posX,frameLength+purlinCurr-CORE.roof.purlin.dim.width/2,.05+CORE.roof.purlin.dim.height/2);
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == frontPurlinEnd)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            p.group.position.copy(pos);            
            p.group.rotation.x = -Math.PI/2;
            this.front.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            purlinCurr+=frontPurlinSpacingActual;
        }
    }

    
    buildBackExtensionPurlin(){
        // get the "rakeLength" of the roof
        let frameLength = 0
        let backLength = this.backSideExtensionLength;

        let backPurlinStart = 0;
        let frontPurlinEnd = backLength
        if(frontPurlinEnd<backPurlinStart)
            return;
        
        let backPurlinRange = frontPurlinEnd - backPurlinStart;
        let backPurlinCount = Math.ceil(backPurlinRange / CORE.roof.purlin.spacing.min);
        let backPurlinSpacingActual = backPurlinRange / backPurlinCount;
        let purlinCurr = backPurlinStart + backPurlinSpacingActual;
        
        while(purlinCurr <= frontPurlinEnd){
            let len = this.length;
            let posX = 0;
            
            let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(frameLength+purlinCurr);
            len += leftEndAddition;
            // any additional left end length requires we shift the center toward that end
            posX -= leftEndAddition/2;

            let rightEndAddition = this.getRightEndAdditionAtFrontPosition(frameLength+purlinCurr);
            len += rightEndAddition;
            // any additional right end length requires we shift the center toward that end
            posX += rightEndAddition/2;

            let pos = new THREE.Vector3(posX,-purlinCurr+CORE.roof.purlin.dim.width/2,+.05+CORE.roof.purlin.dim.height/2);
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == frontPurlinEnd)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            p.group.position.copy(pos);
            p.group.rotation.x = Math.PI/2;
            this.front.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            purlinCurr+=backPurlinSpacingActual;
        }
    }

    buildExtensionRafters(fl){
        if(this.frontSideExtensionLength && this.frontSideExtensionLength>0)
            this.buildFrontExtensionRafter(fl);
        if(this.backSideExtensionLength && this.backSideExtensionLength>0)
            this.buildBackExtensionRafter(fl);
    }

    buildFrontExtensionRafter(fl){
        let overlap = 12; //https://trello.com/c/9HVHHprQ/287-30h-eave-ew-extensions-and-sw-overhangs
        let length = this.frontSideExtensionLength + overlap;
        let materialDim = BlueprintHelper.get8x4StraightFraming();
        let rafterDepthAtColumn = 8; // to match 8x4 materialDim
        let rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(this.pitchRadians); // pure vertical dimension of a pitched rafter
        let ext = new RafterStraight(CORE.preferences.des_levelOfDetail.value, this.front, new THREE.Vector3(), 0, length, 0, false, {materialDim, rafterDimYAtColumn}, false, false, this.beamColor)
        ext.group.position.set(-this.length/2 + fl,this.getFrameRakeLength() -overlap,  + .05)
        ext.group.rotation.y = Math.PI;
        ext.group.rotation.x = Math.PI/2;
        layerHelper.setGroup(ext.group, CORE.layers.frame);
        layerHelper.enableLayer(ext.group, CORE.layers.quote);
        this.front.add(ext.group);
    }
    
    buildBackExtensionRafter(fl){
        let overlap = 12; //https://trello.com/c/9HVHHprQ/287-30h-eave-ew-extensions-and-sw-overhangs
        let length = this.backSideExtensionLength + overlap;
        let materialDim = BlueprintHelper.get8x4StraightFraming();
        let rafterDepthAtColumn = 8; // to match 8x4 materialDim
        let rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(this.pitchRadians); // pure vertical dimension of a pitched rafter
        let ext = new RafterStraight(CORE.preferences.des_levelOfDetail.value, this.front, new THREE.Vector3(), 0, length, 0, false, {materialDim, rafterDimYAtColumn}, false, false, this.beamColor)
        ext.group.position.set(-this.length/2 + fl,0 + overlap, + .05) // .75 is the thickness of the rafter
        ext.group.rotation.x = Math.PI/2;
        layerHelper.setGroup(ext.group, CORE.layers.frame);
        layerHelper.enableLayer(ext.group, CORE.layers.quote);
        this.front.add(ext.group);
    }


    buildInsulation_Standard(){
        let zBufferPadding = 2; // this is the amount of space added to prevent z-buffer fighting since the insulation is close to the roof panel exterior
        // add this standard insulation in the interior
        let startFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.leftWall));
        let stopFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.rightWall));
        let length = stopFrameX - startFrameX;
        
        let insWidth = this.frontLeftRoofPos.distanceTo(this.backLeftRoofPos)
        let insZFromCenter = (this.frontLeftRoofPos.z - this.backLeftRoofPos.z)/2

        // front side
        this.roofInsulationStandardFront = new _3dInsulation(length, insWidth, .05);
        this.roofInsulationStandardFront.group.position.copy(
            new THREE.Vector3(  
                startFrameX + length/2 - this.length/2,
            0 + (this.pitchHeight/2)-zBufferPadding ,
            this.halfWidth-insZFromCenter));
        this.roofInsulationStandardFront.group.rotation.x = Math.atan(this.pitchRatio) 
        this.group.add(this.roofInsulationStandardFront.group)
        
        layerHelper.setGroup(this.roofInsulationStandardFront.group,CORE.layers.roof, true);
        layerHelper.enableLayer(this.roofInsulationStandardFront.group,CORE.layers.quote, true);
    }

    
    buildInsulation_Insulband(){
        let zBufferPadding = 2; // this is the amount of space added to prevent z-buffer fighting since the insulation is close to the roof panel exterior
        if( Number(this.insulation.energySaver.framelineStop)>=this.insulation.framelines.length)
            this.insulation.energySaver.framelineStop = this.insulation.framelines.length-1;
        let startFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.energySaver.framelineStart));
        let stopFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.energySaver.framelineStop));
        
        let start = startFrameX + 1;
        let stop = stopFrameX -1;
        let length = stop - start;
        
        let insWidth = this.frontLeftRoofPos.distanceTo(this.backLeftRoofPos)
        let insZFromCenter = (this.frontLeftRoofPos.z - this.backLeftRoofPos.z)/2
        let insulationThickness = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio)-1 - zBufferPadding;
        insWidth -= (insulationThickness) * Math.tan(this.pitchRatio); // // https://trello.com/1/cards/6299f3c7d40c9a7e7b4b496a/attachments/62cd994260374646ec84fc34/previews/62cd994360374646ec84fcac/download/image.jpeg.jpg
        
        // front side
        this.roofInsulationInsulbandFront =new _3dInsulation(length, insWidth, insulationThickness- .1);
        this.roofInsulationInsulbandFront.group.position.copy(
            new THREE.Vector3(   start +length/2  - this.length/2,
        0 + (this.pitchHeight/2) - insulationThickness/2 - zBufferPadding,
        this.halfWidth-
        insZFromCenter));
        this.roofInsulationInsulbandFront.group.rotation.x = Math.atan(this.pitchRatio);
        this.group.add(this.roofInsulationInsulbandFront.group)

        layerHelper.setGroup(this.roofInsulationInsulbandFront.group,CORE.layers.roof, true);
        
    }

    buildMainRakeTrim(material){
        let eaveTrimMatingAngle = 0;
        let eaveTrimExtension = 3; 
        
        if(this.config.gutters){
            eaveTrimMatingAngle = Math.PI/4;
            eaveTrimExtension = 0;
        }


        // assummed here is pos
        //let length = this.frontLeftRoofPos.distanceTo(this.backLeftRoofPos) + eaveTrimExtension; // width, essentially
        //let mid = this.frontLeftRoofPos.clone(); 
        //mid = mid.lerp(this.backLeftRoofPos,.5);
        let length = this.getExtendedRakeLength()
        let leftRakeTrim = new _3dTrimRake(length, material, 0, 0, eaveTrimMatingAngle,eaveTrimMatingAngle);
        this.group.add(leftRakeTrim.group);

        
        let hypotZ =(this.frontSideExtensionLength-this.backSideExtensionLength)/2 // along the slope of the roof, adjust the mid-point
        
        let z = hypotZ*Math.cos(this.pitchRadians); // horizontal component
        let y = z*Math.sin(this.pitchRadians); // vertical component

        leftRakeTrim.group.position.set(-this.length/2 - this.leftEndExtensionLength, this.pitchHeight/2 - y, z); 
        leftRakeTrim.group.rotation.y = -Math.PI/2;
        leftRakeTrim.group.rotation.x = this.pitchRadians;
        this.trimRakes.push(leftRakeTrim);
        layerHelper.enableLayer(leftRakeTrim.group, CORE.layers.quote);

        let rightRakeTrim = new _3dTrimRake(length, material, 0, 0, eaveTrimMatingAngle,eaveTrimMatingAngle);
        this.group.add(rightRakeTrim.group);
        rightRakeTrim.group.position.set(this.length/2 + this.rightEndExtensionLength, this.pitchHeight/2 - y, z); 
        rightRakeTrim.group.rotation.y = Math.PI/2;
        rightRakeTrim.group.rotation.x = this.pitchRadians;
        this.trimRakes.push(rightRakeTrim);
        layerHelper.enableLayer(rightRakeTrim.group, CORE.layers.quote);
    }

    buildEaveTrim(trimMaterial){
        if(this.frontEaveSegments)
            this.frontEaveSegments.forEach((seg,i)=>{
                let trimY = this.getFrameRakeLength() + this.frontSideExtensionLength;
                seg.start.y = trimY;
                seg.end.y = trimY;
                seg.start.z = 0;
                seg.end.z = 0;
                if(i==0)
                    seg.start.x -=this.leftEndExtensionLength;
                if(i==this.frontEaveSegments.length-1)
                    seg.end.x +=this.rightEndExtensionLength;

                let trim;

                if(this.frontSideExtensionLength && this.frontSideExtensionLength>0){
                    trim= this.buildEaveTrimSegment(seg, trimMaterial,8);
                    if(this.config.gutters){
                        trim.group.rotation.x = -Math.PI/2;
                    }
                    else
                        trim.group.rotation.x = -Math.PI/2 + this.pitchRadians;
                }
                else{
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 6);   
                    trim.group.rotation.x = -Math.PI/2;
                }                
                //trim.group.rotation.x = this.pitchRadians;
                trim.group.position.y = this.getFrameRakeLength() + this.frontSideExtensionLength;
                this.front.add(trim.group);

            })        

        if(this.backEaveSegments)
            this.backEaveSegments.forEach((seg,i)=>{
                seg.start.y = this.backSideExtensionLength
                seg.end.y = this.backSideExtensionLength
                seg.start.z = 0;
                seg.end.z = 0;
                if(i==0)
                    seg.start.x -=this.leftEndExtensionLength;
                if(i==this.backEaveSegments.length-1)
                    seg.end.x +=this.rightEndExtensionLength;


                let trim
                if(this.backSideExtensionLength && this.backSideExtensionLength>0){
                    trim= this.buildEaveTrimSegment(seg, trimMaterial,8);
                    if(this.config.gutters){
                        trim.group.rotation.x = -Math.PI/2;
                    }
                    else
                        trim.group.rotation.x = -Math.PI/2 - this.pitchRadians;

                }
                else{
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 6);   
                    trim.group.rotation.x = -Math.PI/2;
                }                

                trim.group.rotation.y = Math.PI;
                trim.group.position.y = -this.backSideExtensionLength;
                this.front.add(trim.group);
            })  
    }

    buildEaveTrimSegment(segment, trimMaterial, angleHeight){
        let length = segment.start.distanceTo(segment.end);
        let mid = segment.start.clone();
        mid = mid.lerp(segment.end,.5);
        
        let trim;        
        if(this.config.gutters) {
            trim =new _3dTrimEaveGutter(length, trimMaterial, undefined, undefined, segment.leftHoriAngle, segment.rightHoriAngle);
            trim.group.position.set(mid.x, 0, mid.z);
        }
        else{
            trim =new _3dTrimEaveAngle(length, 90-this.pitchDegrees, trimMaterial, undefined, undefined, 0, 0, angleHeight);
            trim.group.position.set(mid.x, 0+.05, mid.z);
        }
        layerHelper.enableLayer(trim.group,CORE.layers.quote);
        return trim;
    }

    buildSoffitTrim(trimMaterials) {
        let debugMat = new THREE.MeshBasicMaterial({color: 0xff0000});

        let soffitTrims = new THREE.Group();
        
        let fExtension = this.frontSideExtensionLength;
        let bExtension = this.backSideExtensionLength;
        let lExtension = this.leftEndExtensionLength;
        let rExtension = this.rightEndExtensionLength;

        let soffitTrimWidth = 2;
        let soffitTrimThickness = 2;

        let frameRakeLength = this.getFrameRakeLength();
        
        let soffitLengthSubtraction = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians)
        let highSideSoffitGap = soffitLengthSubtraction
        let highSideTrimWidth = soffitTrimWidth + highSideSoffitGap;

        let specialLeftEave = (lExtension > 0 && bExtension == 0);
        let specialRightEave = (rExtension > 0 && bExtension == 0);

        // LEFT END 
        // trim between rake and soffit
        if (lExtension > 0) {
            let rakeLen = frameRakeLength + fExtension + bExtension + soffitTrimWidth*2;
            let bottomRakeVerticalAngle = specialLeftEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, 0, 0, 0, 0, false, false)

            rake.group.position.x = - this.length/2 - lExtension + .05;
            rake.group.position.y = (frameRakeLength + fExtension - bExtension)/2
            rake.group.position.z = /*-BlueprintHelper.pitchedPurlinDimY(this.pitchRatio)*/ CORE.roof.purlin.dim.height + /*(soffitTrimThickness/Math.cos(this.pitchRadians))*/ + .11;
            rake.group.rotateZ(-Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='left rake soffit trim mesh';
            soffitTrims.add(rake.group);
        } else {
            // no left extension, make a piece for each side extension
            if (fExtension > 0) {
                let rakeLen = fExtension + soffitTrimWidth - soffitLengthSubtraction;
                let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, 0, this.pitchRadians, 0, 0, false, false)
                rake.group.position.x = - this.length/2 - lExtension + .05; //- soffitTrimWidth/2
                rake.group.position.y = frameRakeLength + rakeLen - rakeLen/2 + soffitLengthSubtraction;
                rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
                rake.group.rotateZ(-Math.PI/2)
                rake.group.rotateX(-Math.PI/2)
                rake.group.name='front left soffit rake trim mesh';
                soffitTrims.add(rake.group);
            }
            if(bExtension > 0) {
                let rakeLen = bExtension + soffitTrimWidth + soffitLengthSubtraction;
                let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, -this.pitchRadians, 0, 0, 0, false, false)
                rake.group.position.x = - this.length/2 - lExtension + .05;
                rake.group.position.y = - bExtension - soffitTrimWidth + rakeLen/2;
                rake.group.position.z = CORE.roof.purlin.dim.height + .11;
                rake.group.rotateZ(-Math.PI/2)
                rake.group.rotateX(-Math.PI/2)
                rake.group.name='back left soffit rake trim mesh';
                soffitTrims.add(rake.group);
            }
        }
        
        // RIGHT END 
        // trim between rake and soffit
        if (rExtension > 0) {
            let rakeLen = frameRakeLength + fExtension + bExtension + soffitTrimWidth*2;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, 0, 0, 0, 0, false, false)

            rake.group.position.x = this.length/2 + rExtension - .05;
            rake.group.position.y = (frameRakeLength + fExtension - bExtension)/2
            rake.group.position.z = CORE.roof.purlin.dim.height + .11;
            rake.group.rotateZ(Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='left rake soffit trim mesh';
            soffitTrims.add(rake.group);
        } else {
            // no left extension, make a piece for each side extension
            if (fExtension > 0) {
                let rakeLen = fExtension + soffitTrimWidth - soffitLengthSubtraction;
                let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, this.pitchRadians, 0, 0, 0, false, false)
                rake.group.position.x = this.length/2 + rExtension - .05; //- soffitTrimWidth/2
                rake.group.position.y = frameRakeLength + rakeLen - rakeLen/2 + soffitLengthSubtraction;
                rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
                rake.group.rotateZ(Math.PI/2)
                rake.group.rotateX(-Math.PI/2)
                rake.group.name='front left soffit rake trim mesh';
                soffitTrims.add(rake.group);
            }
            if(bExtension > 0) {
                let rakeLen = bExtension + soffitTrimWidth + soffitLengthSubtraction;
                let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, 0, -this.pitchRadians, 0, 0, false, false)
                rake.group.position.x = + this.length/2 + rExtension - .05;
                rake.group.position.y = - bExtension - soffitTrimWidth + rakeLen/2;
                rake.group.position.z = CORE.roof.purlin.dim.height + .11;
                rake.group.rotateZ(Math.PI/2)
                rake.group.rotateX(-Math.PI/2)
                rake.group.name='back left soffit rake trim mesh';
                soffitTrims.add(rake.group);
            }
        }
                
        // Front side eave soffit trim/trims
        if (fExtension > 0) {   // one eave across
            // trim between front eave and soffit
            let eaveTrimHeight = soffitTrimThickness;//*1.2;
            let eaveLength = this.length+lExtension+rExtension+2*soffitTrimWidth;
            let eave = new TrimSoffitRake(eaveLength, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
            eave.group.name = "front eave soffit trim mesh";
            eave.group.position.x = -lExtension/2 + rExtension/2;
            eave.group.position.y = + frameRakeLength + fExtension - 0.05;
            eave.group.position.z = CORE.roof.purlin.dim.height + .11;
            eave.group.rotateZ(Math.PI);
            eave.group.rotateX(-Math.PI/2);
            soffitTrims.add(eave.group);
        } else {
            // trim for each end
            if (lExtension > 0) {
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                //let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitRake(lExtension + soffitTrimWidth, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
                //eave.group.rotateY(Math.PI);
                eave.group.position.x = - this.length/2 - lExtension/2 - soffitTrimWidth/2 - 0.05;;
                eave.group.position.y = + frameRakeLength// - soffitTrimWidth*(test);
                eave.group.position.z = + CORE.roof.purlin.dim.height + .11;
                eave.group.rotateZ(Math.PI);
                eave.group.rotateX(-Math.PI/2);
                eave.group.name='front left soffit eave trim mesh';
                soffitTrims.add(eave.group);
            }
            if (rExtension > 0) {
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                //let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitRake(rExtension + soffitTrimWidth, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
                eave.group.position.x = + this.length/2 + rExtension/2 + soffitTrimWidth/2 - 0.05;
                eave.group.position.y = + frameRakeLength// - soffitTrimWidth*(1/*test*/)+.11;
                eave.group.position.z = + CORE.roof.purlin.dim.height + .11;
                eave.group.rotateZ(Math.PI);
                eave.group.rotateX(-Math.PI/2);
                eave.group.name='front right soffit eave trim mesh';
                soffitTrims.add(eave.group);
            }
        }
        
        if (bExtension > 0) {
            // trim between back eave and soffit
            // trim between eave and soffit
            let eaveTrimHeight = soffitTrimThickness;
            let eaveLength = this.length+lExtension+rExtension+2*soffitTrimWidth;
            let eave = new TrimSoffitRake(eaveLength, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
            
            eave.group.position.x = rExtension/2 - lExtension/2; //+ this.length/2 + rExtension/2 + soffitTrimWidth/2;
            eave.group.position.y = - bExtension //+ soffitTrimWidth/2// - soffitTrimWidth*(test)/4+.11;
            eave.group.position.z = CORE.roof.purlin.dim.height + .11;
            eave.group.rotateX(-Math.PI/2);
            eave.group.name='back eave soffit trim';
            soffitTrims.add(eave.group);
        } else {
            if (lExtension > 0) {
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                //let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitRake(lExtension + soffitTrimWidth, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
                //eave.group.rotateY(Math.PI);
                eave.group.position.x = - this.length/2 - lExtension/2 - soffitTrimWidth/2 - 0.05;;
                eave.group.position.y = - bExtension// - soffitTrimWidth*(test);
                eave.group.position.z = + CORE.roof.purlin.dim.height + .11;
                eave.group.rotateX(-Math.PI/2);
                eave.group.name='back left soffit eave trim mesh';
                soffitTrims.add(eave.group);
            }
            if (rExtension > 0) {
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                //let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitRake(rExtension + soffitTrimWidth, soffitTrimWidth, eaveTrimHeight, trimMaterials, 0, 0, 0, 0, false, false);
                eave.group.position.x = + this.length/2 + rExtension/2 + soffitTrimWidth/2 - 0.05;
                eave.group.position.y = - bExtension// - soffitTrimWidth*(1/*test*/)+.11;
                eave.group.position.z = + CORE.roof.purlin.dim.height + .11;
                eave.group.rotateX(-Math.PI/2);
                eave.group.name='back right soffit eave trim mesh';
                soffitTrims.add(eave.group);
            }
        }
        
        //soffitTrims.position.y += highSideSoffitGap/2;
        this.front.add(soffitTrims);
        
    }

    getOutlineMeshes(){
        return this.outlineMeshes;
    }

    getRoofOutlinePoints(){
        return [
            -this.length/2- this.leftEndExtensionLength, -this.backSideExtensionLength, // left ridge
            this.length/2 + this.rightEndExtensionLength, -this.backSideExtensionLength, // right ridge
            this.length/2 + this.rightEndExtensionLength, this.getFrameRakeLength() + this.frontSideExtensionLength,
            -this.length/2 - this.leftEndExtensionLength, this.getFrameRakeLength() + this.frontSideExtensionLength,
        ];
    }

    buildRoofTop(){

        let rakeLength = this.frontLeftRoofPos.distanceTo(this.backLeftRoofPos);
        let frontRoofPoints = this.getRoofOutlinePoints();
        let front = new EarcutDataManager()
        front.setOutline(frontRoofPoints)

        let frontTieIns = this.getFrontTieIns();
        frontTieIns.forEach((t)=>{
            let halfWidth = t.width/2;
            
            let eaveHeightOffset = this.eaveHeight-(t.eave*12);
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch)
            let childPitchRadians = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let eaveOffsetWidth = eaveHeightOffset/Math.tan(childPitchRadians);
            let leftX = t.offset+eaveOffsetWidth;
            let centerX = t.offset + halfWidth;
            let rightX = t.offset+t.width-eaveOffsetWidth;
            
            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);            
            let childPitchHypotenuse = (childPitchHeight-eaveHeightOffset) / Math.sin(this.pitchRadians);

            let p1 = new THREE.Vector2(-this.length/2 + leftX, rakeLength)
            let p2 = new THREE.Vector2(-this.length/2+ centerX , rakeLength - childPitchHypotenuse)
            let p3 = new THREE.Vector2(-this.length/2 + rightX, rakeLength)
            //if(p2.y <0)
                //p2.y =0;
            front.addTriangularHole(p1, p2, p3);
        });
        //front.addSquareHole(0,240,300,300)
        
        let frontData = front.generate();

        let frontRoofPanelTop = SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.color));        
        let frontRoofPanelBottom = SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEED));

        let frontSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            frontData, 
            frontRoofPanelTop,
            frontRoofPanelBottom,            
            CORE.layers.roof );
        layerHelper.enableLayer(frontSheeting.group, CORE.layers.quote)

        let frontRoof = new THREE.Group();
        frontRoof.position.y = this.pitchHeight+.1 // .1 prevents clipping of eave strut
        frontRoof.rotation.x = Math.PI/2 + this.pitchRadians;
        frontRoof.add(frontSheeting.group)
        frontRoof.position.z = - this.width/2;
        this.group.add(frontRoof);

        /////
        // generate outline-specific mesh.
        // because the collision zones are being obscured by transparent outline and pickDetection meshes: https://stackoverflow.com/questions/11165345/three-js-webgl-transparent-planes-hiding-other-planes-behind-them

        this.addOutlineMeshesToGroup(frontRoofPoints, frontRoof, true);
    }

    buildRoofSoffit(){
        
        let front = new EarcutDataManager()
        let soffitLengthSubtraction = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians)
        let soffitPoints = this.getRoofOutlinePoints();
        front.setOutline(soffitPoints);
        
        // poke a hole for the front frame
        let adjustCenter = 0
        let adjustLength = 0;
        if (this.frontSideExtensionLength > 0) {
            adjustCenter = soffitLengthSubtraction;
            adjustLength = -soffitLengthSubtraction;
        }
        if (this.backSideExtensionLength > 0) {
            adjustCenter += soffitLengthSubtraction;
            adjustLength += soffitLengthSubtraction;
        }

        let rakeLength = this.getFrameRakeLength();
        front.addSquareHole(0,rakeLength/2+adjustCenter/2,this.length,rakeLength-adjustLength)
        let frontData = front.generate();

        let RoofPanelTop = SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(0xEEEEED));
        let RoofPanelBottom = SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(this.design.color));

        let frontSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            frontData,
            RoofPanelTop,
            RoofPanelBottom,
            CORE.layers.roof);
            layerHelper.enableLayer(frontSheeting.group, CORE.layers.quote)
        frontSheeting.group.position.z=CORE.roof.purlin.dim.height +.1; // .75 gets the panel covering the eave struct and extension rafters
        //frontSheeting.group.position.y=CORE.roof.purlin.dim.height/Math.tan(Math.PI/2-this.pitchRadians);
        this.front.add(frontSheeting.group);   
    }

    buildPanels(){
        this.outlineMeshes=[];

        this.buildRoofTop();
        this.buildRoofSoffit();

    }   

    buildPurlins(){
        // magic number 8 below is pretty arbitrary
         // get the "rakeLength" of the roof
         let frontLength = this.getFrameRakeLength()
         let purlinIndex = 0;
         // subtract peak space
         let frontPurlinStart = this.design.peakSpace;
         let frontPurlinEnd = frontLength; //-CORE.roof.purlin.spacing.min
         if(frontPurlinEnd<frontPurlinStart)
             return;
         let frontPurlinRange = frontPurlinEnd - frontPurlinStart;
         let frontPurlinSpaceCount = Math.ceil(frontPurlinRange / CORE.roof.purlin.spacing.min);
         let frontPurlinSpacingActual = frontPurlinRange / frontPurlinSpaceCount;
         //let purlinCurr = frontPurlinStart

        while(purlinIndex < frontPurlinSpaceCount){
            let len = this.length;
            let posX = 0;
            let purlinCurr = frontPurlinStart + frontPurlinSpacingActual * purlinIndex;
            let leftEndAddition = this.getLeftEndAdditionAtFrontPosition();
            len += leftEndAddition;
            // any additional left end length requires we shift the center toward that end
            posX -= leftEndAddition/2;

            let rightEndAddition = this.getRightEndAdditionAtFrontPosition();
            len += rightEndAddition;
            // any additional right end length requires we shift the center toward that end
            posX += rightEndAddition/2;

            let pos = new THREE.Vector3(posX,purlinCurr,.05+CORE.roof.purlin.dim.height/2);
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            p.group.position.copy(pos);
            p.group.rotation.x = Math.PI/2;
            this.front.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            //purlinCurr+=frontPurlinSpacingActual;
            purlinIndex++;
        }
    }

    buildEaves(){
        
        let collisionZones = true;

        let left= new THREE.Vector3().copy(this.backLeftEavePos);
        let right = new THREE.Vector3().copy(this.backRightEavePos);

        let len = left.distanceTo(right);
        let pos = left.lerp(right, .5);
        // TODO: these quaternions are hacky. They could be calculated inside EaveStrut with existing information as they are consistent, front to back.
        let backQRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),-Math.atan(-this.pitchRatio) - Math.PI), 
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),0)
        );
        pos.y+=this.pitchPurlinDimY/2-.5;
        let backEave = new _3dEaveStrut(CORE.preferences.des_levelOfDetail.value, len, pos, backQRot, collisionZones, this.pitchRatio, this.purlinColor);        
        this.group.add(backEave.group);
        this.eaves.push(backEave);
        layerHelper.enableLayer(backEave.group, CORE.layers.quote);

        left= new THREE.Vector3().copy(this.frontLeftEavePos);
        right= new THREE.Vector3().copy(this.frontRightEavePos)
        len = left.distanceTo(right);
        pos = left.lerp(right, .5);
        // TODO: these quaternions are hacky. They could be calculated inside EaveStrut with existing information as they are consistent, front to back.
        let frontQRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),-Math.atan(-this.pitchRatio)), 
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),0)
        );
        pos.y+=this.pitchPurlinDimY/2;
        let frontEave = new _3dEaveStrut(CORE.preferences.des_levelOfDetail.value, len, pos, frontQRot, collisionZones, this.pitchRatio, this.purlinColor);        
        this.group.add(frontEave.group);
        this.eaves.push(frontEave);
        layerHelper.enableLayer(frontEave.group, CORE.layers.quote);
    }   

    getFrameRakeLength(){
        return (this.width)/Math.cos(this.pitchRadians);
    }

    getExtendedRakeLength(){
        return this.getFrameRakeLength() + this.frontSideExtensionLength + this.backSideExtensionLength;
    }

    
    getRightEndAdditionAtFrontPosition() {
        let rightEndAddition = 0;
            // add the right extension
            rightEndAddition += this.rightEndExtensionLength;
        return rightEndAddition;
    }

    getLeftEndAdditionAtFrontPosition() {
        let leftEndAddition = 0;
        
            // add the left extension
            leftEndAddition += this.leftEndExtensionLength;
        return leftEndAddition;
    }
}