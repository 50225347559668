<template>
  <div class="dashboard">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <h1>{{linkLabels.dashboard}}</h1>
      <div class="dashboard-links">
        <template v-if="mode === 0 || mode === 1">
          <router-link :to="{ path: linkLabels.entityManagerRouteName }">
            <NavCard>
            {{ linkLabels.entityManager }}
          </NavCard>
          </router-link><br/><br/>
          <router-link :to="{ path: pricingRoutePath}">
            <NavCard>Pricing and Items</NavCard></router-link><br/><br/>
        </template>
        <template v-if="mode === 0 || mode === 1 || mode === 2">
          <!-- <router-link :to="{ name: 'onboarding' }">{{ linkLabels.onboarding }}</router-link><br/><br/> -->
          <!-- <router-link :to="{ name: 'employeeManager' }">Employee Manager</router-link><br/><br/> -->
        </template>
        
        <router-link :to="{path: entityInfoRoutePath}">
        <NavCard>
            {{ linkLabels.entityInfo }}
        </NavCard>
        </router-link><br /><br/>
        <router-link :to="{ path: projectManagerRoutePath }">
          <NavCard>Projects
          </NavCard>
        </router-link><br/><br/>
        

      </div>
    </div>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import NavCard from '../components/NavCard.vue'
export default {
  props: {
  },
  data() {
    return {
      entityId: null,
      loading:true,      
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    mode(){
      return this.current.mode
    },
    linkLabels() { 
      console.log('dashboard mode', this.mode)
      if (this.mode === 0) {
        return {
          dashboard: 'System Dashboard',
          entityManager: 'Companies and Contractors',
          onboarding: 'Company Onboarding',
          entityManagerRouteName: '/entities/list',
          entityInfo: 'Entity Info',
        };
      } else if (this.mode === 1) {
        return {
          dashboard: this.current.company.businessName,
          entityManager: 'Manage Contractors',
          onboarding: 'Contractor Onboarding',
          entityManagerRouteName: `${this.$route.path}/contractors`,
          entityInfo: 'Company Info',
        };
      } else if (this.mode === 2) {
        return {
          dashboard: this.current.contractor?.businessName??"def",
          onboarding: 'Employee Onboarding',
          entityInfo: 'Contractor Info'
        };
      }
      // Default fallback
      return {
        entityManager: 'Entity Manager',
        onboarding: 'Onboarding'
      };
    },
    pricingRoutePath() {
      if(this.$route.name == 'dashboard'){
        return '/pricing'
      }
      else
        return `${this.$route.path}/pricing`
    },
    
    projectManagerRoutePath() {
      if(this.$route.name == 'dashboard'){
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityInfoRoutePath(){
      if(this.$route.name == 'dashboard'){
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        return `/entity/${currentUser.entityId}/info`;
      }
      else{
        return `${this.$route.path}/info`;
      }
    }
  },
  methods: {
    ...mapActions('entityModule', ['fetchEntity',]),
    beforeRouteUpdate(to, from, next) {
      console.log('beforeRouteUpdate')    ;
      next();
    },
    async doInit(){
      
      console.log('dashboard: created');
      let redirectUrl = sessionStorage.getItem('redirectUrl')
      if(redirectUrl && redirectUrl != '/'){
        console.log('in dashboard, redirecting to: ', redirectUrl)
        sessionStorage.removeItem('redirectUrl');
        this.$router.push({path:redirectUrl}) // relative path
      }
      console.log('in dashboard: ', redirectUrl)

      await ContextLogic.initContext(this);
    
      this.loading=false;
    }
  },
  async created() {
    await this.doInit();
  },
  mounted() {
    if(!this.$auth.isAuthenticated)
    {
      console.log('dashboard mounted: not authenticated');
    }
  },
  watch:{
  },
  components:{
    NavCard
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
}
.dashboard-links {
  /* margin-top: 20px; */
  display:flex;
  flex-flow: row wrap;
  justify-content: center;
}
.dashboard-links a {
  margin-right: 10px;
  text-decoration: none;
  color: #3498db;
}
</style>