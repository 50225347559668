import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
  async fetchAllEntities({ commit }) {
    try {
      console.log('fetchAllEntities');
      return await api.getAllEntities();
    } catch (error) {
      console.error('Error fetching entities:', error);
      throw error;
    }
  },
  async fetchAllCompanies({ commit }){
    try {
      console.log('fetchAllCompanies');
      return await api.getAllCompanies();
    } catch (error) {
      console.error('Error fetching entities:', error);
      throw error;
    }
  },
  async fetchSubEntities({ commit }, entityId) {
    try {
      console.log('fetchSubEntities');
      return await api.getSubEntities(entityId);
    } catch (error) {
      console.error('Error fetching all companies:', error);
      throw error;
    }
  },
  async fetchContractorsByRep({commit}, sub){
    try {
      console.log('fetchEntitiesByRep');
      return await api.getContractorsByRep(sub);
    } catch (error) {
      console.error('Error fetching entities by rep:', error);
      throw error;
    }
  },
  async fetchEntity({ commit }, entityId){
    try {
      return await api.get(`/entity/${entityId}`);
    } catch (error) {
      console.error('Error fetching entity:', error);
      throw error;
    }
  },
  async addEntity({ commit }, entityData) {
    try {
      const response = await api.post('/entities', entityData);
      return response.data;
    } catch (error) {
      console.error('Error adding entity:', error);
      throw error;
    }
  },
  async updateEntityInfo({commit}, entity){
    try {
      const response = await api.put(`/entity/${entity.id}`, entity);
      return response.data;
    } catch (error) {
      console.error('Error updating entity:', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};