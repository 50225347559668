import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
    async fetchPriceSchemes({commit}, entityId){
        try {
            const response = await api.get(`/price/getPriceSchemes/${entityId}`);
            return response;
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};