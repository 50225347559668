<template>
    <div class="entityUserPicker">
      <EntityPicker v-if="showEntity" :entities="entities"  ref="entityPicker" @entity-selected="onEntitySelected" />
      <UserPicker v-if="showUser" :users="users" ref="userPicker" @user-selected="onUserSelected" />
    </div>
  </template>
  
  <script>
  import EntityPicker from './EntityPicker.vue'
  import UserPicker from './UserPicker.vue'
  import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    name: 'EntityUserPicker',
    props: {
      entities: {
        type: Array,
        required: true
      },
      isCompanyPicker:{
        type: Boolean,
        required: true
      },
    },
    components: {
      EntityPicker,
      UserPicker
    },
    data() {
      return {
        selectedEntityId: null,
        users: [],
        showEntity: true,
        showUser: true,
      }
    },
    computed: {
    
    },
    mounted(){
      
    },
    methods: {
      ...mapActions('userModule', ['fetchUsersFromCompany', 'fetchUsersFromContractor']),
      async onEntitySelected(entityId) {
        this.selectedEntityId = entityId;
        if (entityId) {
          this.$refs.entityPicker.selectedEntity = entityId;
          this.users = this.isCompanyPicker ? await this.fetchUsersFromCompany(entityId) : await this.fetchUsersFromContractor(entityId);
          console.log('Project lvl1 user is from: ', entityId)
        }
        else {
          this.users = [];  // Clear users when no entity is selected
        }
      },
      onUserSelected(userId) {
        if(userId){
          this.$refs.userPicker.selectedUser = userId;
          this.$emit('user-selected', userId)
          console.log('Project lvl1 user is: ', userId)
        }
      },
      disablePickers(){
        this.$refs.entityPicker.isDisabled = true;
        this.$refs.userPicker.isDisabled = true;
      }
    },
    watch:{
  
    }
  }
  </script>

<style lang="scss" scoped>
.entityUserPicker{
  display:flex;
  
}
</style>