<template>
    <div>
      <select :disabled="isDisabled" v-model="selectedEntity" @change="$emit('entity-selected', selectedEntity)">
        <option value="">Select an entity</option>
        <option v-for="entity in entities" :key="entity.id" :value="entity.id">
          {{ entity.businessName }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EntityPicker',
    props: {
      entities: {
        type: Array,
        required: true
      },
      // pickerSettings: {
      //   type: Object,
      //   required: true
      // }
    },
    data() {
      return {
        selectedEntity: '',
        isDisabled: false
      }
    },
    watch:{
        'entities'(newVal) {
            if (newVal) {
                console.log('entity picker', this.entities)
            }
        },

    }
  }
  </script>