
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import ColorHelper from '../helpers/colorHelper.js'
import {CORE,impactTypes, buildingCodes } from '../_spec.js';
import Util from '../utility.js'
import ConstraintHelper from '../helpers/ConstraintHelper.js';

export default class Options extends Base{
    constructor(design, model){
        super(design);
        this.model = model;
    }

    migrate(design)
    {
        if(Util.isUndefined(design.roofExt)){
            design.roofExt = {
                front:0,
                back:0,
                left:0,
                right:0,
            }
        }

        // data migration introduced during beta (2021.01.04)
        if(typeof design.insulband !== 'undefined')
        {
            design.insulation = {
                wall:'VR.3(R_10)',
                roof:'VR.3(R_10)',
                energySaver: 'none'
            };
            delete design.insulband;
        }

        if(typeof design.insulation.energySaver !== 'object'){
            design.insulation.energySaver = {
                type: design.insulation.energySaver,
                framelineStart: 1,
                framelineStop: 1
            }
        }

        let newWallIns = design.insulation.wall;
        switch(design.insulation.wall){
            case 'VR.3(R_10)':
                newWallIns = 'VR.3_(R_10)';
                break ;
            case 'VR.4(R_10)':
                newWallIns = 'VR.4_(R_13)';
                break ;
            case 'VR.6(R_19)':
                newWallIns = 'VR.6_(R_19)';
                break ;
            case 'none':
                newWallIns = 'None';
                break;
        }        
        design.insulation.wall = newWallIns;        

        let newRoofIns = design.insulation.roof;
        switch(design.insulation.roof){
            case 'VR.3(R_10)':
                newRoofIns = 'VR.3_(R_10)';
                break ;
            case 'VR.4(R_10)':
                newRoofIns = 'VR.4_(R_13)';
                break ;
            case 'VR.6(R_19)':
                newRoofIns = 'VR.6_(R_19)';
                break ;
            case 'none':
                newRoofIns = 'None';
                break;
        }
        design.insulation.roof = newRoofIns;

        if(design.insulation.energySaver.type === 'none')
            design.insulation.energySaver.type = 'Add Insul-Band';    
    }


    defaultDesign(){
        return {
            type: CORE.components.options,
            gutters: true,
            dripTrim: false,
            wrapTubePorchCols: true,
            touchUpPaint: true,
            jambCounterFlashing: false,
            ohJambLiner: true,
            tackyTapeRoof: false,
            plusGirtsCTL: false,
            galvBeams: false,
            galvPurlins: false,
            insulation: {
                wall:'VR.3_(R_10)',
                roof:'VR.3_(R_10)',
                chickWire: false,
                energySaver: {
                    type: 'none',
                    start: 1,
                    stop: 4
                }
            },            
            //premiumTrim: false
        }
    }
    
    static canEdit(){return true;}
    static canDelete(){return false;}
    static canCopy(){return false;}

    getOutlineMeshes(){        
        return null;
    }
    getDescription (){
        return `Options`
    }
    
    getOptionsSpec(){
        // logic to disable galvanized options when appropriate 
        let porchNamesWithNonBoltFT = [];
        let desParentOfOptions = this.masterDesign.getComponentDesignById(this.design.parent.id);
        if(desParentOfOptions.type === CORE.components.structure || desParentOfOptions.type === CORE.components.leanTo)
            porchNamesWithNonBoltFT = ConstraintHelper.getPorchesWithNonBoltFrameType(this.model.designManager, desParentOfOptions)
        let enableGalvOpts = desParentOfOptions.getFrame().frameType === CORE.frame.types.bolt
        let galvOptsInfo;

        // logic to disable back roof extention option for lean-tos
        let enableBackRoofExt = true;
        if(desParentOfOptions.type === CORE.components.leanTo){
            enableBackRoofExt = false;
        }

        if(!enableGalvOpts)
            galvOptsInfo ="Galvanized options only available for Bolt Up buildings.";
        
            let opts = [
                                  
            OptionHelper.header('Trim'),
            OptionHelper.checkbox("gutters", "Gutter", impactTypes.optionsGutter,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),

            OptionHelper.checkbox("dripTrim", "Drip Trim", impactTypes.optionsDripTrim,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),

            OptionHelper.checkbox("wrapTubePorchCols", "Wrap Tube Porch Columns", impactTypes.optionsWrapTubePorchCols,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),
            
                        
            OptionHelper.checkbox("touchUpPaint", "Touch up Paint", impactTypes.optionsTouchUpPaint,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),


            
//           OptionHelper.checkbox("premiumTrim", "Premium Trim Package", impactTypes.optionsTrimPackagePremium, 
//           true,
//           undefined,
//           undefined,
//           undefined,
//           undefined,
//           undefined),

            OptionHelper.header('Galvanized', galvOptsInfo),
            
            OptionHelper.checkbox("galvBeams", "Beams", impactTypes.optionsGalvanizedBeams,
            enableGalvOpts,
            undefined,//in
            undefined,//out
            (v) => { //fnChange
                //this.wallDesign.mainFrameColor
                if(porchNamesWithNonBoltFT.length > 0){
                    this.model.store.dispatch("showCannotApplyGalvOptionsBecausePorchChildren", {"porchNames": porchNamesWithNonBoltFT, 'parentName': desParentOfOptions.name,})
                }
                else{
                    this.design.galvBeams = v;
                    this.design.beamColor = v ? CORE.frame.galvanizedColor : CORE.frame.redOxideColor;
                }
            }),

            OptionHelper.checkbox("galvPurlins", "Purlins", impactTypes.optionsGalvanizedPurlins,
            enableGalvOpts,
            undefined,
            undefined,
            (v) => {
                // update purlin color (eaves use this same value)
                if(porchNamesWithNonBoltFT.length > 0)
                    this.model.store.dispatch("showCannotApplyGalvOptionsBecausePorchChildren", {"porchNames": porchNamesWithNonBoltFT, 'parentName': desParentOfOptions.name,})
                else{
                    this.design.galvPurlins = v;
                    this.design.purlinColor = v ? CORE.frame.galvanizedColor : CORE.frame.redOxideColor;
                }
               
            }
            ),



            OptionHelper.header('Insulation'),

            OptionHelper.selection("insulation.wall","Wall", impactTypes.optionsInsulationWall, undefined, [
                Util.copy(CORE.insulation.standard.none),
                Util.copy(CORE.insulation.standard.VR3),
                Util.copy(CORE.insulation.standard.VR4),
                Util.copy(CORE.insulation.standard.VR6)
                
            ],
            true,
            undefined,
            undefined,
            undefined,
            ),

            OptionHelper.selection("insulation.roof","Roof", impactTypes.optionsInsulationRoof, undefined, [
                Util.copy(CORE.insulation.standard.none),
                Util.copy(CORE.insulation.standard.VR3),
                Util.copy(CORE.insulation.standard.VR4),
                Util.copy(CORE.insulation.standard.VR6)
               
            ],
            true,
            undefined,
            undefined,
            undefined,
            ),

            OptionHelper.selection("insulation.energySaver.type","Energy Saver", impactTypes.optionsInsulationEnergySaverType, undefined, [
                Util.copy(CORE.insulation.insulband.none),
                Util.copy(CORE.insulation.insulband.R30),
                Util.copy(CORE.insulation.insulband.R35)
                
            ],
            true,
            undefined,
            undefined,
            undefined,
            ),

            OptionHelper.checkbox("insulation.chickenWire", "Chicken Wire", impactTypes.optionsInsulationChickenWire,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),

            OptionHelper.numericUpDown("insulation.energySaver.framelineStart", "Start frameline", impactTypes.optionsInsulationEnergySaverFramelineStart, "", 1, this.design.insulation.energySaver.framelineStop, undefined,true,
            ()=>{ //fnIn
                return this.design.insulation.energySaver.framelineStart+1;
            },
            (v) => { //fnOut
                return v-1;
            },
            (v)=>{ //fnChanged
                this.design.insulation.energySaver.framelineStart = v;
            }),
            OptionHelper.numericUpDown("insulation.energySaver.framelineStop", "Stop frameline", impactTypes.optionsInsulationEnergySaverFramelineStop, "", this.design.insulation.energySaver.framelineStart+1, undefined, undefined,true,
            ()=>{ //fnIn
                return this.design.insulation.energySaver.framelineStop+1;
            },
            (v) => { //fnOut                 
                return v-1;
            },
            (v)=>{ //fnChanged
                this.design.insulation.energySaver.framelineStop = v;
            }),

            OptionHelper.header('Other'),
            

            OptionHelper.checkbox("jambCounterFlashing", "Jamb Counter Flashing", impactTypes.optionsJambCounterFlashing,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),

            OptionHelper.checkbox("ohJambLiner", "OH Jamb Liner", impactTypes.optionsOhJambLiner,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),
            
            OptionHelper.checkbox("tackyTapeRoof", "Tacky Tape Roof", impactTypes.optionsTackyTapeRoof,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),

               
            OptionHelper.checkbox("plusGirtsCTL", "PLUS pkg Girts Cut to length", impactTypes.optionsPlusGirtsCTL,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined),
            
            OptionHelper.header('Roof Extensions'),
            OptionHelper.numericUpDown("roofExt.front", "Front", impactTypes.optionsRoofExtFront, "ft", 0, 6, 1, true,
            undefined,undefined, 
            (v)=>{ //fnChanged
                if(v>1.5)
                    v = Math.round(v);
                else
                    v = Util.RoundToNearest(v,.5)
                this.design.roofExt.front = v;                                
            }),
            OptionHelper.numericUpDown("roofExt.back", "Back", impactTypes.optionsRoofExtBack, "ft", 0, 6, 1, enableBackRoofExt,
            undefined,undefined,(v)=>{ //fnChanged
                if(v>1.5)
                    v = Math.round(v);
                else
                    v = Util.RoundToNearest(v,.5)
                this.design.roofExt.back = v;                                
            }),
            OptionHelper.numericUpDown("roofExt.left", "Left", impactTypes.optionsRoofExtLeft, "ft", 0, 6, 1, true,
            undefined,undefined,(v)=>{ //fnChanged
                if(v>1.5)
                    v = Math.round(v);
                else
                    v = Util.RoundToNearest(v,.5)
                this.design.roofExt.left = v;                                
            }),
            OptionHelper.numericUpDown("roofExt.right", "Right", impactTypes.optionsRoofExtBack, "ft", 0, 6, 1, true,
            undefined,undefined,(v)=>{ //fnChanged
                if(v>1.5)
                    v = Math.round(v);
                else
                    v = Util.RoundToNearest(v,.5)
                this.design.roofExt.right = v;                                
            }),
        ];
        return opts;
    }

    static mergeDesign(src, tgt){
        tgt.type= CORE.components.options,
        tgt.gutter = src.gutter;
        tgt.dripTrim = src.dripTrim;
        tgt.touchUpPaint = src.touchUpPaint;

        tgt.galvBeams = src.galvBeams;
        tgt.galvPurlins = src.galvPurlins;

        tgt.insulation.wall = src.insulation.wall;
        tgt.insulation.roof = src.insulation.roof;
        tgt.insulation.energySaver = src.insulation.energySaver;
        tgt.insulation.chickenWire = src.insulation.chickenWire;

        tgt.jambCounterFlashing = src.jambCounterFlashing;
        tgt.ohJambLiner = src.ohJambLiner;
        tgt.tackyTapeRoof = src.tackyTapeRoof;
        tgt.plusGirtsCTL = src.plusGirtsCTL;   
        
    }

    build(){        
    }
    
    remove(){
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getTypeDisplayName(){
        return "Options"
    }
}