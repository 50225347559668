import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './pf.js'
import VueMeta from 'vue-meta';
import LogRocket from 'logrocket';
// Import the plugin here
import { Auth0Plugin } from './auth';
// Import the Auth0 configuration
//import authInfo from '../auth_config.json';

LogRocket.init('nhd9sy/steel-ninja');

const authInfo = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams:{
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  }      
  // Add any other necessary options here
};

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: authInfo.domain,
  clientId: authInfo.clientId,
  authorizationParams: authInfo.authorizationParams,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true // ensure meta tags refresh on route changes
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
